// eslint-disable-next-line no-unused-vars
import store from '@/store';
import english from '@/views/Website/Translations/english';
import hindi from '@/views/Website/Translations/hindi';
import bengali from '@/views/Website/Translations/bengali';
import assamese from '@/views/Website/Translations/assamese';
import odia from '@/views/Website/Translations/odia';

// Function to filter options based on language
function getOptionsByLanguage (language) {
    if (language === 'english') {
        return english;
    } else if (language === 'hindi') {
        return hindi;
    } else if (language === 'bengali') {
        return bengali;
    } else if (language === 'assamese') {
        return assamese;
    } else if (language === 'odia') {
        return odia;
    }
    return english;
}

let language = 'english';

const languageSelected = localStorage.getItem('languageSelected');

if (languageSelected) {
    language = languageSelected;
}

const filteredOptions = getOptionsByLanguage(language);
export default filteredOptions;
