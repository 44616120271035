const english = {
    generalContents : {
        menuContents : {
            projectTitle : 'Healthmitra',
            home         : 'Home',
            about        : 'About',
            contactUs    : 'Contact Us',
            language     : 'Language',
            registerNow  : 'Register now'
        },
        footerContents : {
            copyright : 'Copyright',
            callUs    : 'Call us'
        }
    },
    // Page Contents
    homePageContents : {
        languageContent : {
            labelText          : 'Please choose a language to continue',
            selectLanguageText : 'Select a Language',
            submitButton       : 'Continue'
        },
        bannerContent : {
            welcomeText : 'Welcome to Healthmitra',
            text1       : 'Department of Science and Technology, GOI and Mahatma Gandhi University Project',
            text2       : 'An Initiative of Science and Engineering Research Board Project (CRG/2021/004314) on\n' +
                ' “Effect of Social Institutional and Technological Interventions on Access to Healthcare\n' +
                ' Among Interstate Migrant Labourers in Kerala”.',
            registerNow : 'Register now',
            callUsText1 : 'CALL US',
            callUsText2 : 'For any inquiry'
        },
        aboutContent : {
            heading : 'Let us introduce',
            text1   : 'We are dedicated to improving the lives of interstate migrant workers by providing\n' +
                ' essential interactions to access to healthcare and advocating for their rights to\n' +
                ' health.',
            text2 : 'Our mission is to ensure that no worker faces hardship while striving to build a better' +
                ' future for themselves the society and their families.'
        },
        contactUs : {
            title        : 'Contact us',
            formContents : {
                name         : 'Full Name',
                email        : 'Your Email',
                phone        : 'Your phone',
                message      : 'Your message',
                submitButton : 'Send Message'
            }
        }
    },
    registrationContents : {
        title        : 'Registration',
        formContents : {
            basicDetailsTitle            : 'Basic Details',
            nativeAddressTitleText       : 'Native Address',
            keralaAddressTitleText       : 'Address in Kerala',
            phoneValidation              : 'Account with Phone number already exists.',
            name                         : 'Full Name',
            phone                        : 'Phone',
            age                          : 'Age',
            gender                       : 'Gender',
            maritalStatus                : 'Marital Status',
            nativeLocality               : 'Native City/Locality',
            nativeState                  : 'Native State',
            nativeDistrict               : 'Native District',
            keralaLocality               : 'City/Locality in Kerala',
            keralaDistrict               : 'District in Kerala',
            durationInKerala             : 'Duration of Years in Kerala',
            submitButton1                : 'Continue',
            occupationTitleText          : 'Occupation',
            occupationCategory           : 'Occupation Category',
            occupation                   : 'Occupation',
            occupationSelectMessage      : 'Please choose a category',
            medicalHistoryTitleText      : 'Medical history',
            medicalHistoryWarningMessage : 'Please choose an occupation category and occupation.',
            submitButton2                : 'Submit',
            backButton                   : 'Back',
            okayButton                   : 'Okay'

        },
        successPageContents : {
            successMessage : 'Successful',
            thanks         : 'Thank you for registering!',
            noteTitle      : 'Note',
            noteContent    : 'Please don\'t hesitate to reach out to the administrator for any updates in the registered details below.'
        }
    },
    // Options and Questionnaire
    genderOptions : [
        { name : 'Male', id : 1 },
        { name : 'Female', id : 2 },
        { name : 'Transgender', id : 3 },
        { name : 'Rather not to say', id : 4 }
    ],
    maritalStatusOptions : [
        { name : 'Unmarried', id : 1 },
        { name : 'Married', id : 2 },
        { name : 'Divorce', id : 3 },
        { name : 'Partner Expired', id : 4 }
    ],
    durationOptions : [
        { name : 'Less than 1 Year', id : 1 },
        { name : '1-3 Years', id : 2 },
        { name : '3-5 Years', id : 3 },
        { name : '5-10 Years', id : 4 }
    ],
    stateDistrictOptions : [
        {
            id        : 1,
            name      : 'Andaman Nicobar',
            districts : [
                { id : 1, name : 'Nicobar' },
                { id : 2, name : 'North Middle Andaman' },
                { id : 3, name : 'South Andaman' }
            ]
        },
        {
            id        : 2,
            name      : 'Andhra Pradesh',
            districts : [
                { id : 1, name : 'Anantapur' },
                { id : 2, name : 'Chittoor' },
                { id : 3, name : 'East Godavari' },
                { id : 4, name : 'Alluri Sitarama Raju' },
                { id : 5, name : 'Anakapalli' },
                { id : 6, name : 'Annamaya' },
                { id : 7, name : 'Bapatla' },
                { id : 8, name : 'Eluru' },
                { id : 9, name : 'Guntur' },
                { id : 10, name : 'Kadapa' },
                { id : 11, name : 'Kakinada' },
                { id : 12, name : 'Konaseema' },
                { id : 13, name : 'Krishna' },
                { id : 14, name : 'Kurnool' },
                { id : 15, name : 'Manyam' },
                { id : 16, name : 'N T Rama Rao' },
                { id : 17, name : 'Nandyal' },
                { id : 18, name : 'Nellore' },
                { id : 19, name : 'Palnadu' },
                { id : 20, name : 'Prakasam' },
                { id : 21, name : 'Sri Balaji' },
                { id : 22, name : 'Sri Satya Sai' },
                { id : 23, name : 'Srikakulam' },
                { id : 24, name : 'Visakhapatnam' },
                { id : 25, name : 'Vizianagaram' },
                { id : 26, name : 'West Godavari' }
            ]
        },
        {
            id        : 3,
            name      : 'Arunachal Pradesh',
            districts : [
                { id : 1, name : 'Anjaw' },
                { id : 2, name : 'Changlang' },
                { id : 3, name : 'Dibang Valley' },
                { id : 4, name : 'East Kameng' },
                { id : 5, name : 'East Siang' },
                { id : 6, name : 'Kamle' },
                { id : 7, name : 'Kra Daadi' },
                { id : 8, name : 'Kurung Kumey' },
                { id : 9, name : 'Lepa Rada' },
                { id : 10, name : 'Lohit' },
                { id : 11, name : 'Longding' },
                { id : 12, name : 'Lower Dibang Valley' },
                { id : 13, name : 'Lower Siang' },
                { id : 14, name : 'Lower Subansiri' },
                { id : 15, name : 'Namsai' },
                { id : 16, name : 'Pakke Kessang' },
                { id : 17, name : 'Papum Pare' },
                { id : 18, name : 'Shi Yomi' },
                { id : 19, name : 'Siang' },
                { id : 20, name : 'Tawang' },
                { id : 21, name : 'Tirap' },
                { id : 22, name : 'Upper Siang' },
                { id : 23, name : 'Upper Subansiri' },
                { id : 24, name : 'West Kameng' },
                { id : 25, name : 'West Siang' }
            ]
        },
        {
            id        : 4,
            name      : 'Assam',
            districts : [
                { id : 1, name : 'Baksa' },
                { id : 2, name : 'Barpeta' },
                { id : 3, name : 'Biswanath' },
                { id : 4, name : 'Bongaigaon' },
                { id : 5, name : 'Cachar' },
                { id : 6, name : 'Charaideo' },
                { id : 7, name : 'Chirang' },
                { id : 8, name : 'Darrang' },
                { id : 9, name : 'Dhemaji' },
                { id : 10, name : 'Dhubri' },
                { id : 11, name : 'Dibrugarh' },
                { id : 12, name : 'Dima Hasao' },
                { id : 13, name : 'Goalpara' },
                { id : 14, name : 'Golaghat' },
                { id : 15, name : 'Hailakandi' },
                { id : 16, name : 'Hojai' },
                { id : 17, name : 'Jorhat' },
                { id : 18, name : 'Kamrup' },
                { id : 19, name : 'Kamrup Metropolitan' },
                { id : 20, name : 'Karbi Anglong' },
                { id : 21, name : 'Karimganj' },
                { id : 22, name : 'Kokrajhar' },
                { id : 23, name : 'Lakhimpur' },
                { id : 24, name : 'Majuli' },
                { id : 25, name : 'Morigaon' },
                { id : 26, name : 'Nagaon' },
                { id : 27, name : 'Nalbari' },
                { id : 28, name : 'Sivasagar' },
                { id : 29, name : 'Sonitpur' },
                { id : 30, name : 'South Salmara-Mankachar' },
                { id : 31, name : 'Tinsukia' },
                { id : 32, name : 'Udalguri' },
                { id : 33, name : 'West Karbi Anglong' }
            ]
        },
        {
            id        : 5,
            name      : 'Bihar',
            districts : [
                { id : 1, name : 'Araria' },
                { id : 2, name : 'Arwal' },
                { id : 3, name : 'Aurangabad' },
                { id : 4, name : 'Banka' },
                { id : 5, name : 'Begusarai' },
                { id : 6, name : 'Bhagalpur' },
                { id : 7, name : 'Bhojpur' },
                { id : 8, name : 'Buxar' },
                { id : 9, name : 'Darbhanga' },
                { id : 10, name : 'East Champaran' },
                { id : 11, name : 'Gaya' },
                { id : 12, name : 'Gopalganj' },
                { id : 13, name : 'Jamui' },
                { id : 14, name : 'Jehanabad' },
                { id : 15, name : 'Kaimur' },
                { id : 16, name : 'Katihar' },
                { id : 17, name : 'Khagaria' },
                { id : 18, name : 'Kishanganj' },
                { id : 19, name : 'Lakhisarai' },
                { id : 20, name : 'Madhepura' },
                { id : 21, name : 'Madhubani' },
                { id : 22, name : 'Munger' },
                { id : 23, name : 'Muzaffarpur' },
                { id : 24, name : 'Nalanda' },
                { id : 25, name : 'Nawada' },
                { id : 26, name : 'Patna' },
                { id : 27, name : 'Purnia' },
                { id : 28, name : 'Rohtas' },
                { id : 29, name : 'Saharsa' },
                { id : 30, name : 'Samastipur' },
                { id : 31, name : 'Saran' },
                { id : 32, name : 'Sheikhpura' },
                { id : 33, name : 'Sheohar' },
                { id : 34, name : 'Sitamarhi' },
                { id : 35, name : 'Siwan' },
                { id : 36, name : 'Supaul' },
                { id : 37, name : 'Vaishali' },
                { id : 38, name : 'West Champaran' }
            ]
        },
        {
            id        : 6,
            name      : 'Chandigarh',
            districts : [
                { id : 1, name : 'Chandigarh' }
            ]
        },
        {
            id        : 7,
            name      : 'Chhattisgarh',
            districts : [
                { id : 1, name : 'Balod' },
                { id : 2, name : 'Baloda Bazar' },
                { id : 3, name : 'Balrampur' },
                { id : 4, name : 'Bastar' },
                { id : 5, name : 'Bemetara' },
                { id : 6, name : 'Bijapur' },
                { id : 7, name : 'Bilaspur' },
                { id : 8, name : 'Dantewada' },
                { id : 9, name : 'Dhamtari' },
                { id : 10, name : 'Durg' },
                { id : 11, name : 'Gariaband' },
                { id : 12, name : 'Gaurela Pendra Marwahi' },
                { id : 13, name : 'Janjgir Champa' },
                { id : 14, name : 'Jashpur' },
                { id : 15, name : 'Kabirdham' },
                { id : 16, name : 'Kanker' },
                { id : 17, name : 'Khairagarh' },
                { id : 18, name : 'Kondagaon' },
                { id : 19, name : 'Korba' },
                { id : 20, name : 'Koriya' },
                { id : 21, name : 'Mahasamund' },
                { id : 22, name : 'Manendragarh' },
                { id : 23, name : 'Mohla Manpur' },
                { id : 24, name : 'Mungeli' },
                { id : 25, name : 'Narayanpur' },
                { id : 26, name : 'Raigarh' },
                { id : 27, name : 'Raipur' },
                { id : 28, name : 'Rajnandgaon' },
                { id : 29, name : 'Sakti' },
                { id : 30, name : 'Sarangarh Bilaigarh' },
                { id : 31, name : 'Sukma' },
                { id : 32, name : 'Surajpur' },
                { id : 33, name : 'Surguja' }
            ]
        },
        {
            id        : 8,
            name      : 'Dadra and Nagar Haveli and Daman and Diu',
            districts : [
                { id : 1, name : 'Dadra and Nagar Haveli' },
                { id : 2, name : 'Daman' },
                { id : 3, name : 'Diu' }
            ]
        },
        {
            id        : 9,
            name      : 'Delhi',
            districts : [
                { id : 1, name : 'Central Delhi' },
                { id : 2, name : 'East Delhi' },
                { id : 3, name : 'New Delhi' },
                { id : 4, name : 'North Delhi' },
                { id : 5, name : 'North East Delhi' },
                { id : 6, name : 'North West Delhi' },
                { id : 7, name : 'Shahdara' },
                { id : 8, name : 'South Delhi' },
                { id : 9, name : 'South East Delhi' },
                { id : 10, name : 'South West Delhi' },
                { id : 11, name : 'West Delhi' }
            ]
        },
        {
            id        : 10,
            name      : 'Goa',
            districts : [
                { id : 1, name : 'North Goa' },
                { id : 2, name : 'South Goa' }
            ]
        },
        {
            id        : 11,
            name      : 'Gujarat',
            districts : [
                { id : 1, name : 'Ahmedabad' },
                { id : 2, name : 'Amreli' },
                { id : 3, name : 'Anand' },
                { id : 4, name : 'Aravalli' },
                { id : 5, name : 'Banaskantha' },
                { id : 6, name : 'Bharuch' },
                { id : 7, name : 'Bhavnagar' },
                { id : 8, name : 'Botad' },
                { id : 9, name : 'Chhota Udaipur' },
                { id : 10, name : 'Dahod' },
                { id : 11, name : 'Dang' },
                { id : 12, name : 'Devbhoomi Dwarka' },
                { id : 13, name : 'Gandhinagar' },
                { id : 14, name : 'Gir Somnath' },
                { id : 15, name : 'Jamnagar' },
                { id : 16, name : 'Junagadh' },
                { id : 17, name : 'Kheda' },
                { id : 18, name : 'Kutch' },
                { id : 19, name : 'Mahisagar' },
                { id : 20, name : 'Mehsana' },
                { id : 21, name : 'Morbi' },
                { id : 22, name : 'Narmada' },
                { id : 23, name : 'Navsari' },
                { id : 24, name : 'Panchmahal' },
                { id : 25, name : 'Patan' },
                { id : 26, name : 'Porbandar' },
                { id : 27, name : 'Rajkot' },
                { id : 28, name : 'Sabarkantha' },
                { id : 29, name : 'Surat' },
                { id : 30, name : 'Surendranagar' },
                { id : 31, name : 'Tapi' },
                { id : 32, name : 'Vadodara' },
                { id : 33, name : 'Valsad' }
            ]
        },
        {
            id        : 12,
            name      : 'Haryana',
            districts : [
                { id : 1, name : 'Ambala' },
                { id : 2, name : 'Bhiwani' },
                { id : 3, name : 'Charkhi Dadri' },
                { id : 4, name : 'Faridabad' },
                { id : 5, name : 'Fatehabad' },
                { id : 6, name : 'Gurugram' },
                { id : 7, name : 'Hisar' },
                { id : 8, name : 'Jhajjar' },
                { id : 9, name : 'Jind' },
                { id : 10, name : 'Kaithal' },
                { id : 11, name : 'Karnal' },
                { id : 12, name : 'Kurukshetra' },
                { id : 13, name : 'Mahendragarh' },
                { id : 14, name : 'Nuh' },
                { id : 15, name : 'Palwal' },
                { id : 16, name : 'Panchkula' },
                { id : 17, name : 'Panipat' },
                { id : 18, name : 'Rewari' },
                { id : 19, name : 'Rohtak' },
                { id : 20, name : 'Sirsa' },
                { id : 21, name : 'Sonipat' },
                { id : 22, name : 'Yamunanagar' }
            ]
        },
        {
            id        : 13,
            name      : 'Himachal Pradesh',
            districts : [
                { id : 1, name : 'Bilaspur' },
                { id : 2, name : 'Chamba' },
                { id : 3, name : 'Hamirpur' },
                { id : 4, name : 'Kangra' },
                { id : 5, name : 'Kinnaur' },
                { id : 6, name : 'Kullu' },
                { id : 7, name : 'Lahaul Spiti' },
                { id : 8, name : 'Mandi' },
                { id : 9, name : 'Shimla' },
                { id : 10, name : 'Sirmaur' },
                { id : 11, name : 'Solan' },
                { id : 12, name : 'Una' }
            ]
        },
        {
            id        : 14,
            name      : 'Jammu and Kashmir',
            districts : [
                { id : 1, name : 'Anantnag' },
                { id : 2, name : 'Bandipora' },
                { id : 3, name : 'Baramulla' },
                { id : 4, name : 'Budgam' },
                { id : 5, name : 'Doda' },
                { id : 6, name : 'Ganderbal' },
                { id : 7, name : 'Jammu' },
                { id : 8, name : 'Kathua' },
                { id : 9, name : 'Kishtwar' },
                { id : 10, name : 'Kulgam' },
                { id : 11, name : 'Kupwara' },
                { id : 12, name : 'Poonch' },
                { id : 13, name : 'Pulwama' },
                { id : 14, name : 'Rajouri' },
                { id : 15, name : 'Ramban' },
                { id : 16, name : 'Reasi' },
                { id : 17, name : 'Samba' },
                { id : 18, name : 'Shopian' },
                { id : 19, name : 'Srinagar' },
                { id : 20, name : 'Udhampur' }
            ]
        },
        {
            id        : 15,
            name      : 'Jharkhand',
            districts : [
                { id : 1, name : 'Bokaro' },
                { id : 2, name : 'Chatra' },
                { id : 3, name : 'Deoghar' },
                { id : 4, name : 'Dhanbad' },
                { id : 5, name : 'Dumka' },
                { id : 6, name : 'East Singhbhum' },
                { id : 7, name : 'Garhwa' },
                { id : 8, name : 'Giridih' },
                { id : 9, name : 'Godda' },
                { id : 10, name : 'Gumla' },
                { id : 11, name : 'Hazaribagh' },
                { id : 12, name : 'Jamtara' },
                { id : 13, name : 'Khunti' },
                { id : 14, name : 'Koderma' },
                { id : 15, name : 'Latehar' },
                { id : 16, name : 'Lohardaga' },
                { id : 17, name : 'Pakur' },
                { id : 18, name : 'Palamu' },
                { id : 19, name : 'Ramgarh' },
                { id : 20, name : 'Ranchi' },
                { id : 21, name : 'Sahebganj' },
                { id : 22, name : 'Seraikela Kharsawan' },
                { id : 23, name : 'Simdega' },
                { id : 24, name : 'West Singhbhum' }
            ]
        },
        {
            id        : 16,
            name      : 'Karnataka',
            districts : [
                { id : 1, name : 'Bagalkot' },
                { id : 2, name : 'Bangalore Rural' },
                { id : 3, name : 'Bangalore Urban' },
                { id : 4, name : 'Belgaum' },
                { id : 5, name : 'Bellary' },
                { id : 6, name : 'Bidar' },
                { id : 7, name : 'Chamarajanagar' },
                { id : 8, name : 'Chikkaballapur' },
                { id : 9, name : 'Chikkamagaluru' },
                { id : 10, name : 'Chitradurga' },
                { id : 11, name : 'Dakshina Kannada' },
                { id : 12, name : 'Davanagere' },
                { id : 13, name : 'Dharwad' },
                { id : 14, name : 'Gadag' },
                { id : 15, name : 'Kalaburagi' },
                { id : 16, name : 'Hassan' },
                { id : 17, name : 'Haveri' },
                { id : 18, name : 'Kodagu' },
                { id : 19, name : 'Kolar' },
                { id : 20, name : 'Koppal' },
                { id : 21, name : 'Mandya' },
                { id : 22, name : 'Mysore' },
                { id : 23, name : 'Raichur' },
                { id : 24, name : 'Ramanagara' },
                { id : 25, name : 'Shimoga' },
                { id : 26, name : 'Tumkur' },
                { id : 27, name : 'Udupi' },
                { id : 28, name : 'Uttara Kannada' },
                { id : 29, name : 'Vijayanagara' },
                { id : 30, name : 'Vijayapura' },
                { id : 31, name : 'Yadgir' }
            ]
        },
        {
            id        : 17,
            name      : 'Kerala',
            districts : [
                { id : 1, name : 'Alappuzha' },
                { id : 2, name : 'Ernakulam' },
                { id : 3, name : 'Idukki' },
                { id : 4, name : 'Kannur' },
                { id : 5, name : 'Kasaragod' },
                { id : 6, name : 'Kollam' },
                { id : 7, name : 'Kottayam' },
                { id : 8, name : 'Kozhikode' },
                { id : 9, name : 'Malappuram' },
                { id : 10, name : 'Palakkad' },
                { id : 11, name : 'Pathanamthitta' },
                { id : 12, name : 'Thiruvananthapuram' },
                { id : 13, name : 'Thrissur' },
                { id : 14, name : 'Wayanad' }
            ]
        },
        {
            id        : 18,
            name      : 'Ladakh',
            districts : [
                { id : 1, name : 'Kargil' },
                { id : 2, name : 'Leh' }
            ]
        },
        {
            id        : 19,
            name      : 'Lakshadweep',
            districts : [
                { id : 1, name : 'Lakshadweep' }
            ]
        },
        {
            id        : 20,
            name      : 'Madhya Pradesh',
            districts : [
                { id : 1, name : 'Agar Malwa' },
                { id : 2, name : 'Alirajpur' },
                { id : 3, name : 'Anuppur' },
                { id : 4, name : 'Ashoknagar' },
                { id : 5, name : 'Balaghat' },
                { id : 6, name : 'Barwani' },
                { id : 7, name : 'Betul' },
                { id : 8, name : 'Bhind' },
                { id : 9, name : 'Bhopal' },
                { id : 10, name : 'Burhanpur' },
                { id : 11, name : 'Chachaura' },
                { id : 12, name : 'Chhatarpur' },
                { id : 13, name : 'Chhindwara' },
                { id : 14, name : 'Damoh' },
                { id : 15, name : 'Datia' },
                { id : 16, name : 'Dewas' },
                { id : 17, name : 'Dhar' },
                { id : 18, name : 'Dindori' },
                { id : 19, name : 'Guna' },
                { id : 20, name : 'Gwalior' },
                { id : 21, name : 'Harda' },
                { id : 22, name : 'Hoshangabad' },
                { id : 23, name : 'Indore' },
                { id : 24, name : 'Jabalpur' },
                { id : 25, name : 'Jhabua' },
                { id : 26, name : 'Katni' },
                { id : 27, name : 'Khandwa' },
                { id : 28, name : 'Khargone' },
                { id : 29, name : 'Maihar' },
                { id : 30, name : 'Mandla' },
                { id : 31, name : 'Mandsaur' },
                { id : 32, name : 'Morena' },
                { id : 33, name : 'Nagda' },
                { id : 34, name : 'Narsinghpur' },
                { id : 35, name : 'Neemuch' },
                { id : 36, name : 'Niwari' },
                { id : 37, name : 'Panna' },
                { id : 38, name : 'Raisen' },
                { id : 39, name : 'Rajgarh' },
                { id : 40, name : 'Ratlam' },
                { id : 41, name : 'Rewa' },
                { id : 42, name : 'Sagar' },
                { id : 43, name : 'Satna' },
                { id : 44, name : 'Sehore' },
                { id : 45, name : 'Seoni' },
                { id : 46, name : 'Shahdol' },
                { id : 47, name : 'Shajapur' },
                { id : 48, name : 'Sheopur' },
                { id : 49, name : 'Shivpuri' },
                { id : 50, name : 'Sidhi' },
                { id : 51, name : 'Singrauli' },
                { id : 52, name : 'Tikamgarh' },
                { id : 53, name : 'Ujjain' },
                { id : 54, name : 'Umaria' },
                { id : 55, name : 'Vidisha' }
            ]
        },
        {
            id        : 21,
            name      : 'Maharashtra',
            districts : [
                { id : 1, name : 'Ahmednagar' },
                { id : 2, name : 'Akola' },
                { id : 3, name : 'Amravati' },
                { id : 4, name : 'Aurangabad' },
                { id : 5, name : 'Beed' },
                { id : 6, name : 'Bhandara' },
                { id : 7, name : 'Buldhana' },
                { id : 8, name : 'Chandrapur' },
                { id : 9, name : 'Dhule' },
                { id : 10, name : 'Gadchiroli' },
                { id : 11, name : 'Gondia' },
                { id : 12, name : 'Hingoli' },
                { id : 13, name : 'Jalgaon' },
                { id : 14, name : 'Jalna' },
                { id : 15, name : 'Kolhapur' },
                { id : 16, name : 'Latur' },
                { id : 17, name : 'Mumbai City' },
                { id : 18, name : 'Mumbai Suburban' },
                { id : 19, name : 'Nagpur' },
                { id : 20, name : 'Nanded' },
                { id : 21, name : 'Nandurbar' },
                { id : 22, name : 'Nashik' },
                { id : 23, name : 'Osmanabad' },
                { id : 24, name : 'Palghar' },
                { id : 25, name : 'Parbhani' },
                { id : 26, name : 'Pune' },
                { id : 27, name : 'Raigad' },
                { id : 28, name : 'Ratnagiri' },
                { id : 29, name : 'Sangli' },
                { id : 30, name : 'Satara' },
                { id : 31, name : 'Sindhudurg' },
                { id : 32, name : 'Solapur' },
                { id : 33, name : 'Thane' },
                { id : 34, name : 'Wardha' },
                { id : 35, name : 'Washim' },
                { id : 36, name : 'Yavatmal' }
            ]
        },
        {
            id        : 22,
            name      : 'Manipur',
            districts : [
                { id : 1, name : 'Bishnupur' },
                { id : 2, name : 'Chandel' },
                { id : 3, name : 'Churachandpur' },
                { id : 4, name : 'Imphal East' },
                { id : 5, name : 'Imphal West' },
                { id : 6, name : 'Jiribam' },
                { id : 7, name : 'Kakching' },
                { id : 8, name : 'Kamjong' },
                { id : 9, name : 'Kangpokpi' },
                { id : 10, name : 'Noney' },
                { id : 11, name : 'Pherzawl' },
                { id : 12, name : 'Senapati' },
                { id : 13, name : 'Tamenglong' },
                { id : 14, name : 'Tengnoupal' },
                { id : 15, name : 'Thoubal' },
                { id : 16, name : 'Ukhrul' }
            ]
        },
        {
            id        : 23,
            name      : 'Meghalaya',
            districts : [
                { id : 1, name : 'East Garo Hills' },
                { id : 2, name : 'East Jaintia Hills' },
                { id : 3, name : 'East Khasi Hills' },
                { id : 4, name : 'Mairang' },
                { id : 5, name : 'North Garo Hills' },
                { id : 6, name : 'Ri Bhoi' },
                { id : 7, name : 'South Garo Hills' },
                { id : 8, name : 'South West Garo Hills' },
                { id : 9, name : 'South West Khasi Hills' },
                { id : 10, name : 'West Garo Hills' },
                { id : 11, name : 'West Jaintia Hills' },
                { id : 12, name : 'West Khasi Hills' }
            ]
        },
        {
            id        : 24,
            name      : 'Mizoram',
            districts : [
                { id : 1, name : 'Aizawl' },
                { id : 2, name : 'Champhai' },
                { id : 3, name : 'Hnahthial' },
                { id : 4, name : 'Khawzawl' },
                { id : 5, name : 'Kolasib' },
                { id : 6, name : 'Lawngtlai' },
                { id : 7, name : 'Lunglei' },
                { id : 8, name : 'Mamit' },
                { id : 9, name : 'Saiha' },
                { id : 10, name : 'Saitual' },
                { id : 11, name : 'Serchhip' }
            ]
        },
        {
            id        : 25,
            name      : 'Nagaland',
            districts : [
                { id : 1, name : 'Chumukedima' },
                { id : 2, name : 'Dimapur' },
                { id : 3, name : 'Kiphire' },
                { id : 4, name : 'Kohima' },
                { id : 5, name : 'Longleng' },
                { id : 6, name : 'Mokokchung' },
                { id : 7, name : 'Mon' },
                { id : 8, name : 'Niuland' },
                { id : 9, name : 'Noklak' },
                { id : 10, name : 'Peren' },
                { id : 11, name : 'Phek' },
                { id : 12, name : 'Shamator' },
                { id : 13, name : 'Tseminyu' },
                { id : 14, name : 'Tuensang' },
                { id : 15, name : 'Wokha' },
                { id : 16, name : 'Zunheboto' }
            ]
        },
        {
            id        : 26,
            name      : 'Odisha',
            districts : [
                { id : 1, name : 'Angul' },
                { id : 2, name : 'Balangir' },
                { id : 3, name : 'Balasore' },
                { id : 4, name : 'Bargarh' },
                { id : 5, name : 'Bhadrak' },
                { id : 6, name : 'Boudh' },
                { id : 7, name : 'Cuttack' },
                { id : 8, name : 'Debagarh' },
                { id : 9, name : 'Dhenkanal' },
                { id : 10, name : 'Gajapati' },
                { id : 11, name : 'Ganjam' },
                { id : 12, name : 'Jagatsinghpur' },
                { id : 13, name : 'Jajpur' },
                { id : 14, name : 'Jharsuguda' },
                { id : 15, name : 'Kalahandi' },
                { id : 16, name : 'Kandhamal' },
                { id : 17, name : 'Kendrapara' },
                { id : 18, name : 'Kendujhar' },
                { id : 19, name : 'Khordha' },
                { id : 20, name : 'Koraput' },
                { id : 21, name : 'Malkangiri' },
                { id : 22, name : 'Mayurbhanj' },
                { id : 23, name : 'Nabarangpur' },
                { id : 24, name : 'Nayagarh' },
                { id : 25, name : 'Nuapada' },
                { id : 26, name : 'Puri' },
                { id : 27, name : 'Rayagada' },
                { id : 28, name : 'Sambalpur' },
                { id : 29, name : 'Subarnapur' },
                { id : 30, name : 'Sundergarh' }
            ]
        },
        {
            id        : 27,
            name      : 'Puducherry',
            districts : [
                { id : 1, name : 'Karaikal' },
                { id : 2, name : 'Mahe' },
                { id : 3, name : 'Puducherry' },
                { id : 4, name : 'Yanam' }
            ]
        },
        {
            id        : 28,
            name      : 'Punjab',
            districts : [
                { id : 1, name : 'Amritsar' },
                { id : 2, name : 'Barnala' },
                { id : 3, name : 'Bathinda' },
                { id : 4, name : 'Faridkot' },
                { id : 5, name : 'Fatehgarh Sahib' },
                { id : 6, name : 'Fazilka' },
                { id : 7, name : 'Firozpur' },
                { id : 8, name : 'Gurdaspur' },
                { id : 9, name : 'Hoshiarpur' },
                { id : 10, name : 'Jalandhar' },
                { id : 11, name : 'Kapurthala' },
                { id : 12, name : 'Ludhiana' },
                { id : 13, name : 'Malerkotla' },
                { id : 14, name : 'Mansa' },
                { id : 15, name : 'Moga' },
                { id : 16, name : 'Mohali' },
                { id : 17, name : 'Muktsar' },
                { id : 18, name : 'Pathankot' },
                { id : 19, name : 'Patiala' },
                { id : 20, name : 'Rupnagar' },
                { id : 21, name : 'Sangrur' },
                { id : 22, name : 'Shaheed Bhagat Singh Nagar' },
                { id : 23, name : 'Tarn Taran' }
            ]
        },
        {
            id        : 29,
            name      : 'Rajasthan',
            districts : [
                { id : 1, name : 'Ajmer' },
                { id : 2, name : 'Alwar' },
                { id : 3, name : 'Banswara' },
                { id : 4, name : 'Baran' },
                { id : 5, name : 'Barmer' },
                { id : 6, name : 'Bharatpur' },
                { id : 7, name : 'Bhilwara' },
                { id : 8, name : 'Bikaner' },
                { id : 9, name : 'Bundi' },
                { id : 10, name : 'Chittorgarh' },
                { id : 11, name : 'Churu' },
                { id : 12, name : 'Dausa' },
                { id : 13, name : 'Dholpur' },
                { id : 14, name : 'Dungarpur' },
                { id : 15, name : 'Hanumangarh' },
                { id : 16, name : 'Jaipur' },
                { id : 17, name : 'Jaisalmer' },
                { id : 18, name : 'Jalore' },
                { id : 19, name : 'Jhalawar' },
                { id : 20, name : 'Jhunjhunu' },
                { id : 21, name : 'Jodhpur' },
                { id : 22, name : 'Karauli' },
                { id : 23, name : 'Kota' },
                { id : 24, name : 'Nagaur' },
                { id : 25, name : 'Pali' },
                { id : 26, name : 'Pratapgarh' },
                { id : 27, name : 'Rajsamand' },
                { id : 28, name : 'Sawai Madhopur' },
                { id : 29, name : 'Sikar' },
                { id : 30, name : 'Sirohi' },
                { id : 31, name : 'Sri Ganganagar' },
                { id : 32, name : 'Tonk' },
                { id : 33, name : 'Udaipur' }
            ]
        },
        {
            id        : 30,
            name      : 'Sikkim',
            districts : [
                { id : 1, name : 'East Sikkim' },
                { id : 2, name : 'North Sikkim' },
                { id : 3, name : 'Pakyong' },
                { id : 4, name : 'Soreng' },
                { id : 5, name : 'South Sikkim' },
                { id : 6, name : 'West Sikkim' }
            ]
        },
        {
            id        : 31,
            name      : 'Tamil Nadu',
            districts : [
                { id : 1, name : 'Ariyalur' },
                { id : 2, name : 'Chengalpattu' },
                { id : 3, name : 'Chennai' },
                { id : 4, name : 'Coimbatore' },
                { id : 5, name : 'Cuddalore' },
                { id : 6, name : 'Dharmapuri' },
                { id : 7, name : 'Dindigul' },
                { id : 8, name : 'Erode' },
                { id : 9, name : 'Kallakurichi' },
                { id : 10, name : 'Kanchipuram' },
                { id : 11, name : 'Kanyakumari' },
                { id : 12, name : 'Karur' },
                { id : 13, name : 'Krishnagiri' },
                { id : 14, name : 'Madurai' },
                { id : 15, name : 'Mayiladuthurai' },
                { id : 16, name : 'Nagapattinam' },
                { id : 17, name : 'Namakkal' },
                { id : 18, name : 'Nilgiris' },
                { id : 19, name : 'Perambalur' },
                { id : 20, name : 'Pudukkottai' },
                { id : 21, name : 'Ramanathapuram' },
                { id : 22, name : 'Ranipet' },
                { id : 23, name : 'Salem' },
                { id : 24, name : 'Sivaganga' },
                { id : 25, name : 'Tenkasi' },
                { id : 26, name : 'Thanjavur' },
                { id : 27, name : 'Theni' },
                { id : 28, name : 'Thoothukudi' },
                { id : 29, name : 'Tiruchirappalli' },
                { id : 30, name : 'Tirunelveli' },
                { id : 31, name : 'Tirupattur' },
                { id : 32, name : 'Tiruppur' },
                { id : 33, name : 'Tiruvallur' },
                { id : 34, name : 'Tiruvannamalai' },
                { id : 35, name : 'Tiruvarur' },
                { id : 36, name : 'Vellore' },
                { id : 37, name : 'Viluppuram' },
                { id : 38, name : 'Virudhunagar' }
            ]
        },
        {
            id        : 32,
            name      : 'Telangana',
            districts : [
                { id : 1, name : 'Adilabad' },
                { id : 2, name : 'Bhadradri Kothagudem' },
                { id : 3, name : 'Hanamkonda' },
                { id : 4, name : 'Hyderabad' },
                { id : 5, name : 'Jagtial' },
                { id : 6, name : 'Jangaon' },
                { id : 7, name : 'Jayashankar' },
                { id : 8, name : 'Jogulamba' },
                { id : 9, name : 'Kamareddy' },
                { id : 10, name : 'Karimnagar' },
                { id : 11, name : 'Khammam' },
                { id : 12, name : 'Komaram Bheem' },
                { id : 13, name : 'Mahabubabad' },
                { id : 14, name : 'Mahbubnagar' },
                { id : 15, name : 'Mancherial' },
                { id : 16, name : 'Medak' },
                { id : 17, name : 'Medchal Malkajgiri' },
                { id : 18, name : 'Mulugu' },
                { id : 19, name : 'Nagarkurnool' },
                { id : 20, name : 'Nalgonda' },
                { id : 21, name : 'Narayanpet' },
                { id : 22, name : 'Nirmal' },
                { id : 23, name : 'Nizamabad' },
                { id : 24, name : 'Peddapalli' },
                { id : 25, name : 'Rajanna Sircilla' },
                { id : 26, name : 'Ranga Reddy' },
                { id : 27, name : 'Sangareddy' },
                { id : 28, name : 'Siddipet' },
                { id : 29, name : 'Suryapet' },
                { id : 30, name : 'Vikarabad' },
                { id : 31, name : 'Wanaparthy' },
                { id : 32, name : 'Warangal' },
                { id : 33, name : 'Yadadri Bhuvanagiri' }
            ]
        },
        {
            id        : 33,
            name      : 'Tripura',
            districts : [
                { id : 1, name : 'Dhalai' },
                { id : 2, name : 'Gomati' },
                { id : 3, name : 'Khowai' },
                { id : 4, name : 'North Tripura' },
                { id : 5, name : 'Sepahijala' },
                { id : 6, name : 'South Tripura' },
                { id : 7, name : 'Unakoti' },
                { id : 8, name : 'West Tripura' }
            ]
        },
        {
            id        : 34,
            name      : 'Uttar Pradesh',
            districts : [
                { id : 1, name : 'Agra' },
                { id : 2, name : 'Aligarh' },
                { id : 3, name : 'Ambedkar Nagar' },
                { id : 4, name : 'Amethi' },
                { id : 5, name : 'Amroha' },
                { id : 6, name : 'Auraiya' },
                { id : 7, name : 'Ayodhya' },
                { id : 8, name : 'Azamgarh' },
                { id : 9, name : 'Baghpat' },
                { id : 10, name : 'Bahraich' },
                { id : 11, name : 'Ballia' },
                { id : 12, name : 'Balrampur' },
                { id : 13, name : 'Banda' },
                { id : 14, name : 'Barabanki' },
                { id : 15, name : 'Bareilly' },
                { id : 16, name : 'Basti' },
                { id : 17, name : 'Bhadohi' },
                { id : 18, name : 'Bijnor' },
                { id : 19, name : 'Budaun' },
                { id : 20, name : 'Bulandshahr' },
                { id : 21, name : 'Chandauli' },
                { id : 22, name : 'Chitrakoot' },
                { id : 23, name : 'Deoria' },
                { id : 24, name : 'Etah' },
                { id : 25, name : 'Etawah' },
                { id : 26, name : 'Farrukhabad' },
                { id : 27, name : 'Fatehpur' },
                { id : 28, name : 'Firozabad' },
                { id : 29, name : 'Gautam Buddha Nagar' },
                { id : 30, name : 'Ghaziabad' },
                { id : 31, name : 'Ghazipur' },
                { id : 32, name : 'Gonda' },
                { id : 33, name : 'Gorakhpur' },
                { id : 34, name : 'Hamirpur' },
                { id : 35, name : 'Hapur' },
                { id : 36, name : 'Hardoi' },
                { id : 37, name : 'Hathras' },
                { id : 38, name : 'Jalaun' },
                { id : 39, name : 'Jaunpur' },
                { id : 40, name : 'Jhansi' },
                { id : 41, name : 'Kannauj' },
                { id : 42, name : 'Kanpur Dehat' },
                { id : 43, name : 'Kanpur Nagar' },
                { id : 44, name : 'Kasganj' },
                { id : 45, name : 'Kaushambi' },
                { id : 46, name : 'Lakhimpur Kheri' },
                { id : 47, name : 'Kushinagar' },
                { id : 48, name : 'Lalitpur' },
                { id : 49, name : 'Lucknow' },
                { id : 50, name : 'Maharajganj' },
                { id : 51, name : 'Mahoba' },
                { id : 52, name : 'Mainpuri' },
                { id : 53, name : 'Mathura' },
                { id : 54, name : 'Mau' },
                { id : 55, name : 'Meerut' },
                { id : 56, name : 'Mirzapur' },
                { id : 57, name : 'Moradabad' },
                { id : 58, name : 'Muzaffarnagar' },
                { id : 59, name : 'Pilibhit' },
                { id : 60, name : 'Pratapgarh' },
                { id : 61, name : 'Prayagraj' },
                { id : 62, name : 'Raebareli' },
                { id : 63, name : 'Rampur' },
                { id : 64, name : 'Saharanpur' },
                { id : 65, name : 'Sambhal' },
                { id : 66, name : 'Sant Kabir Nagar' },
                { id : 67, name : 'Shahjahanpur' },
                { id : 68, name : 'Shamli' },
                { id : 69, name : 'Shravasti' },
                { id : 70, name : 'Siddharthnagar' },
                { id : 71, name : 'Sitapur' },
                { id : 72, name : 'Sonbhadra' },
                { id : 73, name : 'Sultanpur' },
                { id : 74, name : 'Unnao' },
                { id : 75, name : 'Varanasi' }
            ]
        },
        {
            id        : 35,
            name      : 'Uttarakhand',
            districts : [
                { id : 1, name : 'Almora' },
                { id : 2, name : 'Bageshwar' },
                { id : 3, name : 'Chamoli' },
                { id : 4, name : 'Champawat' },
                { id : 5, name : 'Dehradun' },
                { id : 6, name : 'Haridwar' },
                { id : 7, name : 'Nainital' },
                { id : 8, name : 'Pauri' },
                { id : 9, name : 'Pithoragarh' },
                { id : 10, name : 'Rudraprayag' },
                { id : 11, name : 'Tehri' },
                { id : 12, name : 'Udham Singh Nagar' },
                { id : 13, name : 'Uttarkashi' }
            ]
        },
        {
            id        : 36,
            name      : 'West Bengal',
            districts : [
                { id : 1, name : 'Alipurduar' },
                { id : 2, name : 'Bankura' },
                { id : 3, name : 'Birbhum' },
                { id : 4, name : 'Cooch Behar' },
                { id : 5, name : 'Dakshin Dinajpur' },
                { id : 6, name : 'Darjeeling' },
                { id : 7, name : 'Hooghly' },
                { id : 8, name : 'Howrah' },
                { id : 9, name : 'Jalpaiguri' },
                { id : 10, name : 'Jhargram' },
                { id : 11, name : 'Kalimpong' },
                { id : 12, name : 'Kolkata' },
                { id : 13, name : 'Malda' },
                { id : 14, name : 'Murshidabad' },
                { id : 15, name : 'Nadia' },
                { id : 16, name : 'North 24 Parganas' },
                { id : 17, name : 'Paschim Bardhaman' },
                { id : 18, name : 'Paschim Medinipur' },
                { id : 19, name : 'Purba Bardhaman' },
                { id : 20, name : 'Purba Medinipur' },
                { id : 21, name : 'Purulia' },
                { id : 22, name : 'South 24 Parganas' },
                { id : 23, name : 'Uttar Dinajpur' }
            ]
        }
    ],
    occupationCategoryOptions : [
        {
            id          : 1,
            category    : 'Construction',
            occupations : [
                { name : 'Buildings', id : 1 },
                { name : 'Roads', id : 2 },
                { name : 'Bridges', id : 3 },
                { name : 'Other infrastructure', id : 4 }
            ]
        },
        {
            id          : 2,
            category    : 'Agricultural',
            occupations : [
                { name : 'Farming', id : 1 },
                { name : 'Plantation work', id : 2 },
                { name : 'Harvesting crops', id : 3 },
                { name : 'Other infrastructure', id : 4 }
            ]
        },
        {
            id          : 3,
            category    : 'Domestic Helpers',
            occupations : [
                { name : 'House cleaning', id : 1 },
                { name : 'Cooking', id : 2 },
                { name : 'Childcare', id : 3 },
                { name : 'Elderly care', id : 4 }
            ]
        },
        {
            id          : 4,
            category    : 'Manufacturing and Industrial Workers',
            occupations : [
                { name : 'Textile', id : 1 },
                { name : 'Plywood', id : 2 },
                { name : 'Food processing', id : 3 },
                { name : 'Garment manufacturing', id : 4 }
            ]
        },
        {
            id          : 5,
            category    : 'Hospitality and Service Industry',
            occupations : [
                { name : 'Housekeeping', id : 1 },
                { name : 'Serving', id : 2 },
                { name : 'Front desk', id : 3 },
                { name : 'Maintenance', id : 4 }
            ]
        },
        {
            id          : 6,
            category    : 'Transportation and Logistics',
            occupations : [
                { name : 'Drivers', id : 1 },
                { name : 'Loaders', id : 2 },
                { name : 'Helpers', id : 3 },
                { name : 'Warehousing', id : 4 }
            ]
        },
        {
            id          : 7,
            category    : 'Street Vendors and Small Businesses',
            occupations : [
                { name : 'Paan Shop', id : 1 },
                { name : 'Buji Shop', id : 2 },
                { name : 'Fast Food', id : 3 },
                { name : 'Other', id : 4 }
            ]
        },
        {
            id          : 8,
            category    : 'Healthcare Support Staff',
            occupations : [
                { name : 'Nurses aides', id : 1 },
                { name : 'Janitors', id : 2 },
                { name : 'Patient Attendants', id : 3 }
            ]
        },
        {
            id          : 9,
            category    : 'Fisheries and Marine Industry',
            occupations : [
                { name : 'Fishing', id : 1 },
                { name : 'Fish Processing', id : 2 },
                { name : 'Boat building', id : 3 },
                { name : 'Marine Industry', id : 4 }
            ]
        },
        {
            id          : 10,
            category    : 'Marine Industry',
            occupations : [
                { name : 'Sanitation workers', id : 1 },
                { name : 'Security guards', id : 2 },
                { name : 'Manual laborers', id : 3 }
            ]
        }
    ],
    questionnaire : [
        {
            id          : 1,
            category_id : 1,
            questions   : [
                'Have you ever experienced any work-related injuries or accidents in the past?',
                'Are you currently experiencing any physical discomfort or pain related to your work?',
                'Have you received any occupational health and safety training or awareness programs specific to the construction industry?',
                'Are you aware of the safety protocols and measures to prevent common construction-related health risks, such as falls, noise exposure, or chemical hazards?',
                'Do you have access to personal protective equipment (PPE) like helmets, gloves, safety goggles, and harnesses while working on construction sites?',
                'Are you aware of the importance of proper lifting techniques and ergonomics to prevent musculoskeletal injuries?',
                'Have you been vaccinated against common occupational health risks, such as tetanus, hepatitis B, or other relevant vaccines?',
                'Are you regularly screened or monitored for health conditions that may be associated with construction work, such as respiratory issues, hearing loss, or skin disorders?',
                'Are you provided with regular breaks and rest periods during your work shifts to prevent fatigue and maintain overall well-being?',
                'Are you aware of the available healthcare facilities and resources in case of any health emergencies or concerns while on the construction site?'
            ]
        },
        {
            id          : 2,
            category_id : 2,
            questions   : [
                'Have you experienced any work-related injuries or accidents in the past while working in agriculture ? ',
                'Are you currently experiencing any physical discomfort or health issues related to your work in agriculture ? ',
                'Are you aware of the potential health risks associated with the use of pesticides, fertilizers, or other chemicals in agricultural practices ? ',
                'Do you have access to and consistently use personal protective equipment(PPE) such as gloves, masks, or goggles when handling agricultural chemicals or performing tasks with potential hazards? ',
                'Are you aware of the importance of proper lifting techniques and ergonomics to prevent musculoskeletal injuries while performing agricultural tasks ? ',
                'Are you knowledgeable about the safety guidelines and practices to prevent common agricultural health risks, such as heat - related illnesses, respiratory issues, or exposure to zoonotic diseases ? ',
                'Have you received any training or information regarding the safe handling and storage of agricultural chemicals and equipment ? ',
                'Are you regularly screened or monitored for health conditions that may be associated with agricultural work, such as respiratory issues, skin disorders, or allergies ? ',
                'Are you provided with regular breaks, access to clean drinking water, and shaded areas to prevent heat - related illnesses and dehydration while working in the fields? ',
                'Are you aware of the available healthcare facilities and resources in case of any health emergencies or concerns related to your work in agriculture ? '
            ]
        },
        {
            id          : 3,
            category_id : 3,
            questions   : [
                'Have you experienced any work-related injuries or accidents in the past while performing domestic tasks?',
                'Are you currently experiencing any physical discomfort or health issues related to your work as a domestic helper?',
                'Are you aware of proper lifting techniques and ergonomics to prevent musculoskeletal injuries while performing household tasks?',
                'Do you have access to and consistently use personal protective equipment (PPE) such as gloves, masks, or aprons when handling cleaning chemicals or performing tasks with potential hazards?',
                'Are you knowledgeable about the safe usage and storage of cleaning chemicals, detergents, and other household products?',
                'Are you provided with adequate rest periods and breaks during your work shifts to prevent fatigue and maintain overall well-being?',
                'Are you aware of the importance of maintaining good indoor air quality and ventilation while performing cleaning tasks?',
                'Are you regularly screened or monitored for health conditions that may be associated with your work as a domestic helper, such as respiratory issues, allergies, or skin disorders?',
                'Are you provided with training or information on basic first aid, including knowledge of emergency contact numbers and actions to take in case of accidents or health emergencies?',
                'Are you aware of the available healthcare facilities and resources in case of any health emergencies or concerns related to your work as a domestic helper?'
            ]
        },
        {
            id          : 4,
            category_id : 4,
            questions   : [
                'Have you experienced any work-related injuries or accidents in the past while working in manufacturing or industrial settings?',
                'Are you currently experiencing any physical discomfort or health issues related to your work in manufacturing or industrial settings?',
                'Are you aware of the potential health risks associated with your specific job role in the manufacturing or industrial sector?',
                'Do you have access to and consistently use personal protective equipment (PPE) such as safety goggles, gloves, ear protection, or masks when working in areas with potential hazards?',
                'Are you knowledgeable about the safety protocols and measures in place to prevent common industrial health risks, such as exposure to harmful chemicals, noise-induced hearing loss, or ergonomic-related injuries?',
                'Are you provided with regular breaks and rest periods during your work shifts to prevent fatigue and maintain overall well-being?',
                'Are you aware of the importance of maintaining proper posture and using ergonomic equipment to minimize the risk of musculoskeletal injuries while performing your job tasks?',
                'Are you regularly screened or monitored for health conditions that may be associated with your work in the manufacturing or industrial sector, such as respiratory issues, hearing loss, or occupational related illnesses?',
                'Are you provided with training or information on emergency procedures, including knowledge of emergency contact numbers and actions to take in case of accidents or health emergencies in the workplace?',
                'Are you aware of the available healthcare facilities and resources in case of any health emergencies or concerns related to your work in the manufacturing or industrial sector?'
            ]
        },
        {
            id          : 5,
            category_id : 5,
            questions   : [
                'Have you experienced any work - related injuries or accidents in the past while working in the hospitality and service industry ? ',
                'Are you currently experiencing any physical discomfort or health issues related to your work in the hospitality and service industry ? ',
                'Are you aware of the potential health risks associated with your specific job role in the hospitality and service industry? ',
                'Do you have access to and consistently use personal protective equipment(PPE) such as gloves, aprons, or masks when handling food, chemicals, or performing tasks with potential hazards? ',
                'Are you knowledgeable about the proper food handling and hygiene practices to prevent foodborne illnesses and ensure food safety? ',
                'Are you provided with regular breaks and rest periods during your work shifts to prevent fatigue and maintain overall well - being ? ',
                'Are you aware of the importance of maintaining good posture and using ergonomic equipment to minimize the risk of musculoskeletal injuries while performing your job tasks? ',
                'Are you regularly screened or monitored for health conditions that may be associated with your work in the hospitality and service industry, such as repetitive strain injuries, respiratory issues, or stress - related conditions? ',
                'Are you provided with training or information on emergency procedures, including knowledge of emergency contact numbers and actions to take in case of accidents or health emergencies in the workplace? ',
                'Are you aware of the available healthcare facilities and resources in case of any health emergencies or concerns related to your work in the hospitality and service industry? '
            ]
        },
        {
            id          : 6,
            category_id : 6,
            questions   : [
                'Have you experienced any work - related injuries or accidents in the past while working in transportation and logistics ?',
                'Are you currently experiencing any physical discomfort or health issues related to your work in transportation and logistics ?',
                'Are you aware of the potential health risks associated with your specific job role in the transportation and logistics sector ?',
                'Do you have access to and consistently use personal protective equipment(PPE) such as helmets, safety vests, gloves, or masks when performing tasks with potential hazards ?',
                'Are you knowledgeable about safe driving practices, including proper seatbelt use, adherence to traffic rules, and maintaining road safety ?',
                'Are you provided with regular breaks and rest periods during your work shifts to prevent fatigue and maintain overall well - being ?',
                'Are you aware of the importance of maintaining good posture and using ergonomic equipment, such as lumbar supports or adjustable seating, to minimize the risk of musculoskeletal injuries while driving or handling goods ?',
                'Are you regularly screened or monitored for health conditions that may be associated with your work in the transportation and logistics sector, such as back pain, vision problems, or sleep disorders ?',
                'Are you provided with training or information on emergency procedures, including knowledge of emergency contact numbers and actions to take in case of accidents or health emergencies on the road or at the workplace ?',
                'Are you aware of the available healthcare facilities and resources in case of any health emergencies or concerns related to your work in the transportation and logistics sector ?'
            ]
        },
        {
            id          : 7,
            category_id : 7,
            questions   : [
                'Have you experienced any work - related injuries or accidents in the past while working as a street vendor or in a small business  ?',
                'Are you currently experiencing any physical discomfort or health issues related to your work as a street vendor or in a small business  ?',
                'Are you aware of the potential health risks associated with your specific job role as a street vendor or in a small business ?',
                'Do you have access to and consistently use personal protective equipment(PPE) such as gloves, aprons, or masks when handling food or performing tasks with potential hazards  ?',
                'Are you knowledgeable about the proper food handling and hygiene practices to prevent foodborne illnesses and ensure food safety ?',
                'Are you provided with regular breaks and rest periods during your work shifts to prevent fatigue and maintain overall well - being  ?',
                'Are you aware of the importance of maintaining good posture and using ergonomic equipment to minimize the risk of musculoskeletal injuries while working as a street vendor or in a small business  ?',
                'Are you regularly screened or monitored for health conditions that may be associated with your work, such as respiratory issues, skin disorders, or stress - related conditions ?',
                'Are you provided with training or information on emergency procedures, including knowledge of emergency contact numbers and actions to take in case of accidents or health emergencies at your workplace  ?',
                'Are you aware of the available healthcare facilities and resources in case of any health emergencies or concerns related to your work as a street vendor or in a small business ?'
            ]
        },
        {
            id          : 8,
            category_id : 8,
            questions   : [
                'Have you ever faced challenges in accessing healthcare services for yourself or your dependents ?',
                'Are you aware of the healthcare benefits and resources available to you as a healthcare support staff member  ?',
                'Have you encountered any difficulties in accessing timely medical treatment or consultations when needed ?',
                'Are you satisfied with the healthcare facilities provided by your employer or institution  ?',
                'Have you received training or information regarding your rights and entitlements to healthcare services as a healthcare support staff member ?',
                'Are you aware of the process for reporting health concerns or occupational hazards in your workplace ?',
                'Have you faced any barriers to obtaining necessary vaccinations or immunizations required for your role in the healthcare industry  ?',
                'Are you provided with regular health check - ups or screenings to monitor your well-being and detect any potential health issues ?',
                'Do you have access to mental health support or counselling services to address work-related stress or emotional well - being  ?',
                'Are you familiar with the procedures and resources available for seeking emergency medical assistance while on duty ?'
            ]
        },
        {
            id          : 9,
            category_id : 9,
            questions   : [
                'Have you ever faced challenges in accessing healthcare services for yourself or your dependents while working in the fisheries and marine industry  ?',
                'Are you aware of the healthcare benefits and resources available to you as a fisheries and marine industry laborer ?',
                'Have you faced any difficulties in accessing timely medical treatment or consultations when needed ? If yes, please provide details.',
                'Are you satisfied with the healthcare facilities provided by your employer or institution in relation to your work in the fisheries and marine industry , If not, what improvements would you suggest  ?',
                'Are you aware of the specific healthcare services or support available for occupational hazards or injuries associated with the fisheries and marine industry, such as waterborne diseases or injuries from handling fishing equipment ?',
                'Have you received training or information regarding your rights and entitlements to healthcare services as a fisheries and marine industry labourer ?',
                'Are you familiar with the procedures and resources available for seeking emergency medical assistance while on board a vessel or at sea ?',
                'Have you faced any barriers to obtaining necessary vaccinations or preventive measures required for your role in the fisheries and marine industry  ?',
                'Are you provided with regular health check - ups or screenings to monitor your well-being and detect any potential health issues ?',
                'Do you have access to mental health support or counselling services to address work-related stress or emotional well - being while working in the fisheries and marine industry  ?'
            ]
        },
        {
            id          : 10,
            category_id : 10,
            questions   : [
                'Have you ever faced challenges in accessing healthcare services for yourself or your dependents?',
                'Are you aware of the healthcare benefits and resources available to you as a laborer in your specific industry or sector ?',
                'Have you any difficulties in accessing timely medical treatment or consultations when needed?',
                'Are you satisfied with the healthcare facilities provided by health institution ?',
                'Are you aware of the specific occupational health risks associated with your line of work?',
                'Are you familiar with your rights and entitlements to healthcare services as a laborer in your sector?',
                'Have you faced any barriers to obtaining necessary vaccinations or preventive measures required for your role ?',
                'Are you provided with regular health check - ups or screenings to monitor your well-being and detect any potential health issues ?',
                'Are you aware of the available healthcare facilities and resources in your area? If not, would you like more information on how to access them?',
                'Do you have access to mental health support or counseling services to address work-related stress or emotional well-being ?'
            ]
        }
    ]
};

export default english;
