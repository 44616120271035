const hindi = {
    generalContents : {
        menuContents : {
            projectTitle : 'हेल्थमित्र',
            home         : 'मुख्य प्रष्ठ',
            about        : 'के बारे म',
            contactUs    : 'संपर्क करे',
            language     : 'भाषा',
            registerNow  : 'अभी पंजीकरण करे'
        },
        footerContents : {
            copyright : 'कॉपीराइट',
            callUs    : 'हमें कॉल करें'
        }
    },
    // Page Contents
    homePageContents : {
        languageContent : {
            labelText          : 'जारी रखने के लिए कृपया एक भाषा चुनें',
            selectLanguageText : 'भाषा चुनें',
            submitButton       : 'जारी रखें'
        },
        bannerContent : {
            welcomeText : 'हेल्थमित्र में आपका स्वागत है',
            text1       : 'विज्ञान और प्रौद्योगिकी विभाग, भारत सरकार और महात्मा गांधी विश्वविद्यालय परियोजना',
            text2       : 'केरल में अंतरराज्यीय प्रवासी मजदूरों के बीच स्वास्थ्य देखभाल तक पहुंच पर सामाजिक' +
                'संस्थागत और तकनीकी हस्तक्षेप का प्रभाव विषय पर विज्ञान और इंजीनियरिंग अनुसंधान' +
                'बोर्ड परियोजना (CRG/2021/004314) की एक पहल।',
            registerNow : 'अभी पंजीकरण करें',
            callUsText1 : 'हमें कॉल करें',
            callUsText2 : 'किसी भी पूछताछ के लिए'
        },
        aboutContent : {
            heading : 'कुछ जानकारी हमारे बारें में',
            text1   : 'हम स्वास्थ्य देखभाल तक पहुंच के लिए आवश्यक बातचीत प्रदान करने और स्वास्थ्य के प्रति' +
                'अंतरराज्यीय प्रवासी श्रमिकों के अधिकारों की जानकारी प्रदान कर, उनके जीवन को बेहतर बनाने के लिए समर्पित हैं।',
            text2 : 'हमारा मिशन यह सुनिश्चित करना है कि किसी भी श्रमिक को समाज और अपने परिवार के' +
                'लिए बेहतर भविष्य बनाने का प्रयास करते समय किसी भी कठिनाई का सामना न करना पड़े।'
        },
        contactUs : {
            title        : 'संपर्क करें',
            formContents : {
                name         : 'पूरा नाम',
                email        : 'आपका ईमेल',
                phone        : 'आपका फोन नंबर',
                message      : 'आपका संदेश',
                submitButton : 'संदेश भेजें'
            }
        }
    },
    registrationContents : {
        title        : 'पंजीकरण',
        formContents : {
            basicDetailsTitle            : 'सामान्य जानकारी',
            nativeAddressTitleText       : 'मूल पता',
            keralaAddressTitleText       : 'केरल में पता',
            phoneValidation              : 'इस फ़ोन नंबर वाला खाता पहले से मौजूद है',
            name                         : 'पूरा नाम',
            phone                        : 'फोन नंबर',
            age                          : 'आयु',
            gender                       : 'लिंग',
            maritalStatus                : 'वैवाहिक स्थिति',
            nativeLocality               : 'मूल शहर/क्षेत्र',
            nativeState                  : 'मूल राज्य',
            nativeDistrict               : 'मूल जिला',
            keralaLocality               : 'केरल में शहर/क्षेत्र',
            keralaDistrict               : 'केरल में जिला',
            durationInKerala             : 'केरल में रहने की अवधि',
            submitButton1                : 'जारी रखें',
            occupationTitleText          : 'व्यवसाय',
            occupationCategory           : 'व्यवसाय श्रेणी',
            occupation                   : 'व्यवसाय श्रेणी',
            occupationSelectMessage      : 'कृपया एक श्रेणी चुनें',
            medicalHistoryTitleText      : 'चिकित्सा का इतिहास',
            medicalHistoryWarningMessage : 'कृपया व्यवसाय श्रेणी और व्यवसाय चुनें',
            submitButton2                : 'जमा करें',
            backButton                   : 'पीछे',
            okayButton                   : 'ठीक है'

        },
        successPageContents : {
            successMessage : 'सफल',
            thanks         : 'पंजीकरण करने के लिए धन्यवाद!',
            noteTitle      : 'टिप्पणी',
            noteContent    : 'कृपया नीचे दिए गए पंजीकृत विवरणों में किसी भी अपडेट के लिए व्यवस्थापक से संपर्क करने में संकोच न करें।'
        }
    },
    // Options and Questionnaire
    genderOptions : [
        { name : 'पुरुष', id : 1 },
        { name : 'महिला', id : 2 },
        { name : 'ट्रांसजेंडर', id : 3 },
        { name : 'मैं बताना नहीं चाहूँगा/ चाहूंगी', id : 4 }
    ],
    maritalStatusOptions : [
        { name : 'अविवाहित', id : 1 },
        { name : 'विवाहित', id : 2 },
        { name : 'तलाकशुदा', id : 3 },
        { name : 'साथी की मृत्यु हो चुकी है', id : 4 }
    ],
    durationOptions : [
        { name : 'एक वर्ष से कम', id : 1 },
        { name : '1-3 वर्ष', id : 2 },
        { name : '3-5 वर्ष', id : 3 },
        { name : '5-10 वर्ष', id : 4 }
    ],
    stateDistrictOptions : [
        {
            id        : 1,
            name      : 'अंडमान और निकोबार',
            districts : [
                { id : 1, name : 'निकोबार' },
                { id : 2, name : 'उत्तर मध्य अंडमान' },
                { id : 3, name : 'दक्षिण अंडमान' }
            ]
        },
        {
            id        : 2,
            name      : 'आंध्र प्रदेश',
            districts : [
                { id : 1, name : 'अनंतपुर' },
                { id : 2, name : 'चित्तूर' },
                { id : 3, name : 'पूर्वी गोदावरी' },
                { id : 4, name : 'अल्लूरी सीताराम राजू' },
                { id : 5, name : 'अनकापल्ली' },
                { id : 6, name : 'अन्नामया' },
                { id : 7, name : 'बापतला' },
                { id : 8, name : 'एलुरु' },
                { id : 9, name : 'गुंटूर' },
                { id : 10, name : 'कडप्पा' },
                { id : 11, name : 'काकीनाडा' },
                { id : 12, name : 'कोनासीमा' },
                { id : 13, name : 'कृष्ण' },
                { id : 14, name : 'कुरनूल' },
                { id : 15, name : 'मन्यम।' },
                { id : 16, name : 'एन टी रामा राव' },
                { id : 17, name : 'नंद्याल' },
                { id : 18, name : 'नेल्लोर' },
                { id : 19, name : 'पलनाडु' },
                { id : 20, name : 'प्रकाशम' },
                { id : 21, name : 'श्री बालाजी' },
                { id : 22, name : 'श्री सत्य साई' },
                { id : 23, name : 'श्रीकाकुलम' },
                { id : 24, name : 'विशाखापत्तनम' },
                { id : 25, name : 'विजयनगरम' },
                { id : 26, name : 'पश्चिम गोदावरी' }
            ]
        },
        {
            id        : 3,
            name      : 'अरुणाचल प्रदेश',
            districts : [
                { id : 1, name : 'अंजाव' },
                { id : 2, name : 'चांगलांग' },
                { id : 3, name : 'डिबांग घाटी' },
                { id : 4, name : 'पूर्वी कामेंग' },
                { id : 5, name : 'पूर्वी सियांग' },
                { id : 6, name : 'कामले' },
                { id : 7, name : 'क्रा दादी' },
                { id : 8, name : 'कुरुंग कुमेय' },
                { id : 9, name : 'लेपा राडा' },
                { id : 10, name : 'लोहित' },
                { id : 11, name : 'लोंगडिंग' },
                { id : 12, name : 'लोअर डिबांग घाटी' },
                { id : 13, name : 'लोअर सियांग' },
                { id : 14, name : 'लोअर सुबांसिरी' },
                { id : 15, name : 'नामसै' },
                { id : 16, name : 'पक्के केसांग' },
                { id : 17, name : 'पापुम पारे' },
                { id : 18, name : 'शी योमी' },
                { id : 19, name : 'सियांग' },
                { id : 20, name : 'तवांग' },
                { id : 21, name : 'तिराप' },
                { id : 22, name : 'अपर सियांग' },
                { id : 23, name : 'अपर सुबांसिरी' },
                { id : 24, name : 'पश्चिम कामेंग' },
                { id : 25, name : 'पश्चिम सियांग' }
            ]
        },
        {
            id        : 4,
            name      : 'असम',
            districts : [
                { id : 1, name : 'बक्सा' },
                { id : 2, name : 'बारपेटा' },
                { id : 3, name : 'बिस्वनाथ' },
                { id : 4, name : 'बोंगाईगांव' },
                { id : 5, name : 'काछार' },
                { id : 6, name : 'चारैडियो' },
                { id : 7, name : 'चिरंग' },
                { id : 8, name : 'डारंग' },
                { id : 9, name : 'धेमाजी' },
                { id : 10, name : 'धुबरी' },
                { id : 11, name : 'डिब्रुगढ़' },
                { id : 12, name : 'डिमा हसाओ' },
                { id : 13, name : 'गोआलपाड़ा' },
                { id : 14, name : 'गोलाघाट' },
                { id : 15, name : 'हैलाकांडी' },
                { id : 16, name : 'होजई' },
                { id : 17, name : 'जोरहाट' },
                { id : 18, name : 'कामरूप' },
                { id : 19, name : 'कामरूप मेट्रोपोलिटन' },
                { id : 20, name : 'कारबी आंगलोंग' },
                { id : 21, name : 'करीमगंज' },
                { id : 22, name : 'कोकराझार' },
                { id : 23, name : 'लखीमपुर' },
                { id : 24, name : 'माजुली' },
                { id : 25, name : 'मोरीगाँव' },
                { id : 26, name : 'नगांव' },
                { id : 27, name : 'नालबाड़ी' },
                { id : 28, name : 'शिवसागर' },
                { id : 29, name : 'सोनितपुर' },
                { id : 30, name : 'दक्षिण सालमारा-मानकाचार' },
                { id : 31, name : 'तिनसुकिया' },
                { id : 32, name : 'उदालगुरी' },
                { id : 33, name : 'पश्चिम कारबी आंगलोंग' }
            ]
        },
        {
            id        : 5,
            name      : 'बिहार',
            districts : [
                { id : 1, name : 'अररिया' },
                { id : 2, name : 'अरवल' },
                { id : 3, name : 'औरंगाबाद' },
                { id : 4, name : 'बांका' },
                { id : 5, name : 'बेगूसराय' },
                { id : 6, name : 'भागलपुर' },
                { id : 7, name : 'भोजपुर' },
                { id : 8, name : 'बक्सर' },
                { id : 9, name : 'दरभंगा' },
                { id : 10, name : 'पूर्वी चंपारण' },
                { id : 11, name : 'गया' },
                { id : 12, name : 'गोपालगंज' },
                { id : 13, name : 'जमुई' },
                { id : 14, name : 'जहानाबाद' },
                { id : 15, name : 'कैमूर' },
                { id : 16, name : 'कटिहार' },
                { id : 17, name : 'खगड़िया' },
                { id : 18, name : 'किशनगंज' },
                { id : 19, name : 'लखीसराय' },
                { id : 20, name : 'मधेपुरा' },
                { id : 21, name : 'मधुबनी' },
                { id : 22, name : 'मुंगेर' },
                { id : 23, name : 'मुजफ्फरपुर' },
                { id : 24, name : 'नालंदा' },
                { id : 25, name : 'नवादा' },
                { id : 26, name : 'पटना' },
                { id : 27, name : 'पूर्णिया' },
                { id : 28, name : 'रोहतास' },
                { id : 29, name : 'सहरसा' },
                { id : 30, name : 'समस्तीपुर' },
                { id : 31, name : 'सारण' },
                { id : 32, name : 'शेखपुरा' },
                { id : 33, name : 'शिवहर' },
                { id : 34, name : 'सीतामढ़ी' },
                { id : 35, name : 'सिवान' },
                { id : 36, name : 'सुपौल' },
                { id : 37, name : 'वैशाली' },
                { id : 38, name : 'पश्चिम चंपारण' }
            ]
        },
        {
            id        : 6,
            name      : 'चंडीगढ़',
            districts : [
                { id : 1, name : 'चंडीगढ़' }
            ]
        },
        {
            id        : 7,
            name      : 'छत्तीसगढ़',
            districts : [
                { id : 1, name : 'बलोद' },
                { id : 2, name : 'बलोदा बाजार' },
                { id : 3, name : 'बलरामपुर' },
                { id : 4, name : 'बस्तर' },
                { id : 5, name : 'बेमेतरा' },
                { id : 6, name : 'बीजापुर' },
                { id : 7, name : 'बिलासपुर' },
                { id : 8, name : 'दंतेवाड़ा' },
                { id : 9, name : 'धमतरी' },
                { id : 10, name : 'दुर्ग' },
                { id : 11, name : 'गरियाबंद' },
                { id : 12, name : 'गौरेला पेंद्रा मारवाहि' },
                { id : 13, name : 'जांजगीर चंपा' },
                { id : 14, name : 'जशपुर' },
                { id : 15, name : 'कबीरधाम' },
                { id : 16, name : 'कांकेर' },
                { id : 17, name : 'खैरागढ़' },
                { id : 18, name : 'कोण्डागाँव' },
                { id : 19, name : 'कोरबा' },
                { id : 20, name : 'कोरिया' },
                { id : 21, name : 'महासमुंद' },
                { id : 22, name : 'मनेन्द्रगढ़' },
                { id : 23, name : 'मोहला मानपुर' },
                { id : 24, name : 'मुंगेली' },
                { id : 25, name : 'नारायणपुर' },
                { id : 26, name : 'रायगढ़' },
                { id : 27, name : 'रायपुर' },
                { id : 28, name : 'राजनांदगाँव' },
                { id : 29, name : 'शक्ति' },
                { id : 30, name : 'सारंगर्ह बिलाइगढ़' },
                { id : 31, name : 'सुकमा' },
                { id : 32, name : 'सूरजपुर' },
                { id : 33, name : 'सरगुजा' }
            ]
        },
        {
            id        : 8,
            name      : 'दादरा और नगर हवेली और दमन और दीव',
            districts : [
                { id : 1, name : 'दादरा और नगर हवेली' },
                { id : 2, name : 'दमन' },
                { id : 3, name : 'दीव' }
            ]
        },
        {
            id        : 9,
            name      : 'दिल्ली',
            districts : [
                { id : 1, name : 'केंद्रीय दिल्ली' },
                { id : 2, name : 'पूर्वी दिल्ली' },
                { id : 3, name : 'नई दिल्ली' },
                { id : 4, name : 'उत्तरी दिल्ली' },
                { id : 5, name : 'उत्तर पूर्वी दिल्ली' },
                { id : 6, name : 'उत्तर पश्चिम दिल्ली' },
                { id : 7, name : 'शाहदरा' },
                { id : 8, name : 'दक्षिण दिल्ली' },
                { id : 9, name : 'दक्षिण पूर्वी दिल्ली' },
                { id : 10, name : 'दक्षिण पश्चिम दिल्ली' },
                { id : 11, name : 'पश्चिमी दिल्ली' }
            ]
        },
        {
            id        : 10,
            name      : 'गोवा',
            districts : [
                { id : 1, name : 'उत्तर गोवा' },
                { id : 2, name : 'दक्षिण गोवा' }
            ]
        },
        {
            id        : 11,
            name      : 'गुजरात',
            districts : [
                { id : 1, name : 'अहमदाबाद' },
                { id : 2, name : 'अमरेली' },
                { id : 3, name : 'आनंद' },
                { id : 4, name : 'अरवल्ली' },
                { id : 5, name : 'बनासकांठा' },
                { id : 6, name : 'भरूच' },
                { id : 7, name : 'भावनगर' },
                { id : 8, name : 'बोटाद' },
                { id : 9, name : 'छोटा उदयपुर' },
                { id : 10, name : 'दाहोद' },
                { id : 11, name : 'दंग' },
                { id : 12, name : 'देवभूमि द्वारका' },
                { id : 13, name : 'गांधीनगर' },
                { id : 14, name : 'गिर सोमनाथ' },
                { id : 15, name : 'जामनगर' },
                { id : 16, name : 'जूनागढ़' },
                { id : 17, name : 'खेड़ा' },
                { id : 18, name : 'कच्छ' },
                { id : 19, name : 'महीसागर' },
                { id : 20, name : 'मेहसाणा' },
                { id : 21, name : 'मोरबी' },
                { id : 22, name : 'नर्मदा' },
                { id : 23, name : 'नवसारी' },
                { id : 24, name : 'पंचमहल' },
                { id : 25, name : 'पाटन' },
                { id : 26, name : 'पोरबंदर' },
                { id : 27, name : 'राजकोट' },
                { id : 28, name : 'साबरकांठा' },
                { id : 29, name : 'सूरत' },
                { id : 30, name : 'सुरेंद्रनगर' },
                { id : 31, name : 'तापी' },
                { id : 32, name : 'वड़ोदरा' },
                { id : 33, name : 'वलसाड' }
            ]
        },
        {
            id        : 12,
            name      : 'हरियाणा',
            districts : [
                { id : 1, name : 'अंबाला' },
                { id : 2, name : 'भिवानी' },
                { id : 3, name : 'चरखी दादरी' },
                { id : 4, name : 'फरीदाबाद' },
                { id : 5, name : 'फतेहाबाद' },
                { id : 6, name : 'गुरुग्राम' },
                { id : 7, name : 'हिसार' },
                { id : 8, name : 'झज्जर' },
                { id : 9, name : 'जींद' },
                { id : 10, name : 'कैथल' },
                { id : 11, name : 'करनाल' },
                { id : 12, name : 'कुरुक्षेत्र' },
                { id : 13, name : 'महेन्द्रगढ़' },
                { id : 14, name : 'नूह' },
                { id : 15, name : 'पलवल' },
                { id : 16, name : 'पंचकुला' },
                { id : 17, name : 'पानीपत' },
                { id : 18, name : 'रेवाड़ी' },
                { id : 19, name : 'रोहतक' },
                { id : 20, name : 'सिरसा' },
                { id : 21, name : 'सोनीपत' },
                { id : 22, name : 'यमुनानगर' }
            ]
        },
        {
            id        : 13,
            name      : 'हिमाचल प्रदेश',
            districts : [
                { id : 1, name : 'बिलासपुर' },
                { id : 2, name : 'चंबा' },
                { id : 3, name : 'हमीरपुर' },
                { id : 4, name : 'कांगड़ा' },
                { id : 5, name : 'किन्नौर' },
                { id : 6, name : 'कुल्लू' },
                { id : 7, name : 'लाहौल स्पीति' },
                { id : 8, name : 'मंडी' },
                { id : 9, name : 'शिमला' },
                { id : 10, name : 'सिरमौर' },
                { id : 11, name : 'सोलन' },
                { id : 12, name : 'ऊना' }
            ]
        },
        {
            id        : 14,
            name      : 'जम्मू और कश्मीर',
            districts : [
                { id : 1, name : 'अनंतनाग' },
                { id : 2, name : 'बांदीपोरा' },
                { id : 3, name : 'बारामुला' },
                { id : 4, name : 'बुद्धगम' },
                { id : 5, name : 'डोडा' },
                { id : 6, name : 'गंदेरबल' },
                { id : 7, name : 'जम्मू' },
                { id : 8, name : 'कठुआ' },
                { id : 9, name : 'किश्तवार' },
                { id : 10, name : 'कुलगाम' },
                { id : 11, name : 'कुपवाड़ा' },
                { id : 12, name : 'पूंछ' },
                { id : 13, name : 'पुलवामा' },
                { id : 14, name : 'राजौरी' },
                { id : 15, name : 'रामबाण' },
                { id : 16, name : 'रियासी' },
                { id : 17, name : 'साम्बा' },
                { id : 18, name : 'शोपियाँ' },
                { id : 19, name : 'श्रीनगर' },
                { id : 20, name : 'उधमपुर' }
            ]
        },
        {
            id        : 15,
            name      : 'झारखंड',
            districts : [
                { id : 1, name : 'बोकारो' },
                { id : 2, name : 'चतरा' },
                { id : 3, name : 'देवघर' },
                { id : 4, name : 'धनबाद' },
                { id : 5, name : 'दुमका' },
                { id : 6, name : 'पूर्व सिंहभूम' },
                { id : 7, name : 'गढ़वा' },
                { id : 8, name : 'गिरिडीह' },
                { id : 9, name : 'गोड्डा' },
                { id : 10, name : 'गुमला' },
                { id : 11, name : 'हजारीबाग' },
                { id : 12, name : 'जामताड़ा' },
                { id : 13, name : 'खुंटी' },
                { id : 14, name : 'कोड़ेरमा' },
                { id : 15, name : 'लाटेहार' },
                { id : 16, name : 'लोहारदगा' },
                { id : 17, name : 'पाकुड़' },
                { id : 18, name : 'पलामू' },
                { id : 19, name : 'रामगढ़' },
                { id : 20, name : 'रांची' },
                { id : 21, name : 'साहेबगंज' },
                { id : 22, name : 'सराइकेला खरसावां' },
                { id : 23, name : 'सिमडेगा' },
                { id : 24, name : 'पश्चिम सिंहभूम' }
            ]
        },
        {
            id        : 16,
            name      : 'कर्नाटक',
            districts : [
                { id : 1, name : 'बागलकोट' },
                { id : 2, name : 'बैंगलोर ग्रामीण' },
                { id : 3, name : 'बैंगलोर शहरी' },
                { id : 4, name : 'बेलगाम' },
                { id : 5, name : 'बेल्लारी' },
                { id : 6, name : 'बीदर' },
                { id : 7, name : 'चामराजनगर' },
                { id : 8, name : 'चिक्कबल्लापुर' },
                { id : 9, name : 'चिक्कमगलुरु' },
                { id : 10, name : 'चित्रदुर्गा' },
                { id : 11, name : 'दक्षिण कन्नड़' },
                { id : 12, name : 'दावणगेरे' },
                { id : 13, name : 'धारवाड़' },
                { id : 14, name : 'गड़ग' },
                { id : 15, name : 'कलाबुरगी' },
                { id : 16, name : 'हसन' },
                { id : 17, name : 'हावेरी' },
                { id : 18, name : 'कोडागू' },
                { id : 19, name : 'कोलार' },
                { id : 20, name : 'कोप्पल' },
                { id : 21, name : 'मंड्य' },
                { id : 22, name : 'मैसूर' },
                { id : 23, name : 'रायचुर' },
                { id : 24, name : 'रामनगर' },
                { id : 25, name : 'शिमोगा' },
                { id : 26, name : 'तुमकुर' },
                { id : 27, name : 'उडुपि' },
                { id : 28, name : 'उत्तर कन्नड़' },
                { id : 29, name : 'विजयनगर' },
                { id : 30, name : 'विजयपुरा' },
                { id : 31, name : 'यादगीर' }
            ]
        },
        {
            id        : 17,
            name      : 'केरल',
            districts : [
                { id : 1, name : 'आलप्पुझा' },
                { id : 2, name : 'एर्नाकुलम' },
                { id : 3, name : 'इडुक्की' },
                { id : 4, name : 'कन्नूर' },
                { id : 5, name : 'कासरगोड' },
                { id : 6, name : 'कोल्लम' },
                { id : 7, name : 'कोट्टायम' },
                { id : 8, name : 'कोझिकोड' },
                { id : 9, name : 'मलप्पुरम' },
                { id : 10, name : 'पलक्कड' },
                { id : 11, name : 'पथानामथित्ता' },
                { id : 12, name : 'तिरुवनंतपुरम' },
                { id : 13, name : 'त्रिशूर' },
                { id : 14, name : 'वायनाड' }
            ]
        },
        {
            id        : 18,
            name      : 'लद्दाख',
            districts : [
                { id : 1, name : 'कारगिल' },
                { id : 2, name : 'लेह' }
            ]
        },
        {
            id        : 19,
            name      : 'लक्षद्वीप',
            districts : [
                { id : 1, name : 'लक्षद्वीप' }
            ]
        },
        {
            id        : 20,
            name      : 'मध्य प्रदेश',
            districts : [
                { id : 1, name : 'आगर मालवा' },
                { id : 2, name : 'अलीराजपुर' },
                { id : 3, name : 'अनूपपुर' },
                { id : 4, name : 'अशोकनगर' },
                { id : 5, name : 'बालाघाट' },
                { id : 6, name : 'बड़वानी' },
                { id : 7, name : 'बेतूल' },
                { id : 8, name : 'भिण्ड' },
                { id : 9, name : 'भोपाल' },
                { id : 10, name : 'बुरहानपुर' },
                { id : 11, name : 'चाचौरा' },
                { id : 12, name : 'छतरपुर' },
                { id : 13, name : 'छिंदवाड़ा' },
                { id : 14, name : 'दमोह' },
                { id : 15, name : 'दतिया' },
                { id : 16, name : 'देवास' },
                { id : 17, name : 'धार' },
                { id : 18, name : 'डिण्डोरी' },
                { id : 19, name : 'गुना' },
                { id : 20, name : 'ग्वालियर' },
                { id : 21, name : 'हरदा' },
                { id : 22, name : 'होशंगाबाद' },
                { id : 23, name : 'इंदौर' },
                { id : 24, name : 'जबलपुर' },
                { id : 25, name : 'झाबुआ' },
                { id : 26, name : 'कटनी' },
                { id : 27, name : 'खंडवा' },
                { id : 28, name : 'खरगोन' },
                { id : 29, name : 'मैहर' },
                { id : 30, name : 'मंडला' },
                { id : 31, name : 'मंदसौर' },
                { id : 32, name : 'मोरेना' },
                { id : 33, name : 'नागदा' },
                { id : 34, name : 'नरसिंहपुर' },
                { id : 35, name : 'नीमच' },
                { id : 36, name : 'निवाड़ी' },
                { id : 37, name : 'पन्ना' },
                { id : 38, name : 'रायसेन' },
                { id : 39, name : 'राजगढ़' },
                { id : 40, name : 'रतलाम' },
                { id : 41, name : 'रीवा' },
                { id : 42, name : 'सागर' },
                { id : 43, name : 'सतना' },
                { id : 44, name : 'सेहोर' },
                { id : 45, name : 'सिवनी' },
                { id : 46, name : 'शहडोल' },
                { id : 47, name : 'शाजापुर' },
                { id : 48, name : 'शेओपुर' },
                { id : 49, name : 'शिवपुरी' },
                { id : 50, name : 'सिधी' },
                { id : 51, name : 'सिंगरौली' },
                { id : 52, name : 'तिकमगढ़' },
                { id : 53, name : 'उज्जैन' },
                { id : 54, name : 'उमरिया' },
                { id : 55, name : 'विदिशा' }
            ]
        },
        {
            id        : 21,
            name      : 'महाराष्ट्र',
            districts : [
                { id : 1, name : 'अहमदनगर' },
                { id : 2, name : 'अकोला' },
                { id : 3, name : 'अमरावती' },
                { id : 4, name : 'औरंगाबाद' },
                { id : 5, name : 'बीड' },
                { id : 6, name : 'भंडारा' },
                { id : 7, name : 'बुलढाणा' },
                { id : 8, name : 'चंद्रपूर' },
                { id : 9, name : 'धुले' },
                { id : 10, name : 'गडचिरोली' },
                { id : 11, name : 'गोंदिया' },
                { id : 12, name : 'हिंगोली' },
                { id : 13, name : 'जलगांव' },
                { id : 14, name : 'जालना' },
                { id : 15, name : 'कोल्हापूर' },
                { id : 16, name : 'लातूर' },
                { id : 17, name : 'मुंबई शहर' },
                { id : 18, name : 'मुंबई उपनगर' },
                { id : 19, name : 'नागपुर' },
                { id : 20, name : 'नांदेड' },
                { id : 21, name : 'नंदुरबार' },
                { id : 22, name : 'नाशिक' },
                { id : 23, name : 'उस्मानाबाद' },
                { id : 24, name : 'पालघर' },
                { id : 25, name : 'परभणी' },
                { id : 26, name : 'पुणे' },
                { id : 27, name : 'रायगड' },
                { id : 28, name : 'रत्नागिरी' },
                { id : 29, name : 'सांगली' },
                { id : 30, name : 'सातारा' },
                { id : 31, name : 'सिंधुदुर्ग' },
                { id : 32, name : 'सोलापुर' },
                { id : 33, name : 'ठाणे' },
                { id : 34, name : 'वर्धा' },
                { id : 35, name : 'वाशिम' },
                { id : 36, name : 'यवतमाल' }
            ]
        },
        {
            id        : 22,
            name      : 'मणिपुर',
            districts : [
                { id : 1, name : 'बिष्णुपुर' },
                { id : 2, name : 'चंदेल' },
                { id : 3, name : 'चुराचांदपुर' },
                { id : 4, name : 'इम्फाल पूर्व' },
                { id : 5, name : 'इम्फाल पश्चिम' },
                { id : 6, name : 'जिरीबाम' },
                { id : 7, name : 'ककिंग' },
                { id : 8, name : 'कामजोंग' },
                { id : 9, name : 'कांगपोकपी' },
                { id : 10, name : 'नोनी' },
                { id : 11, name : 'फेरज़वाल' },
                { id : 12, name : 'सेनापति' },
                { id : 13, name : 'तामेंगलोंग' },
                { id : 14, name : 'तेंगनौपाल' },
                { id : 15, name : 'थौबल' },
                { id : 16, name : 'उखरुल' }
            ]
        },
        {
            id        : 23,
            name      : 'मेघालय',
            districts : [
                { id : 1, name : 'पूर्वी गारो पहाड़ियाँ' },
                { id : 2, name : 'पूर्वी जेन्टिया पहाड़ियाँ' },
                { id : 3, name : 'पूर्वी खासी पहाड़ियाँ' },
                { id : 4, name : 'मैरांग' },
                { id : 5, name : 'उत्तरी गारो पहाड़ियाँ' },
                { id : 6, name : 'री भोई' },
                { id : 7, name : 'दक्षिणी गारो पहाड़ियाँ' },
                { id : 8, name : 'दक्षिण पश्चिम गारो पहाड़ियाँ' },
                { id : 9, name : 'दक्षिण पश्चिम खासी पहाड़ियाँ' },
                { id : 10, name : 'पश्चिमी गारो पहाड़ियाँ' },
                { id : 11, name : 'पश्चिमी जेन्टिया पहाड़ियाँ' },
                { id : 12, name : 'पश्चिमी खासी पहाड़ियाँ' }
            ]
        },
        {
            id        : 24,
            name      : 'मिजोरम',
            districts : [
                { id : 1, name : 'आइजॉल' },
                { id : 2, name : 'चाम्फाई' },
                { id : 3, name : 'हनथियाल' },
                { id : 4, name : 'खावजॉल' },
                { id : 5, name : 'कोलासीब' },
                { id : 6, name : 'लॉंगटलाई' },
                { id : 7, name : 'लुंगलेई' },
                { id : 8, name : 'मामित' },
                { id : 9, name : 'सैहा' },
                { id : 10, name : 'साइचुअल' },
                { id : 11, name : 'सर्चीप' }
            ]
        },
        {
            id        : 25,
            name      : 'नागालैंड',
            districts : [
                { id : 1, name : 'चुमुकेड़िमा' },
                { id : 2, name : 'डीमापुर' },
                { id : 3, name : 'किफायर' },
                { id : 4, name : 'कोहिमा' },
                { id : 5, name : 'लॉंगलेंग' },
                { id : 6, name : 'मोकोकचुंग' },
                { id : 7, name : 'मोन' },
                { id : 8, name : 'निउलैंड' },
                { id : 9, name : 'नोकलक' },
                { id : 10, name : 'पेरेन' },
                { id : 11, name : 'फेक' },
                { id : 12, name : 'शमाटोर' },
                { id : 13, name : 'त्सेमिन्यू' },
                { id : 14, name : 'टुएंसांग' },
                { id : 15, name : 'वोखा' },
                { id : 16, name : 'जूनहेबोटो' }
            ]
        },
        {
            id        : 26,
            name      : 'उड़ीसा',
            districts : [
                { id : 1, name : 'अंगुल' },
                { id : 2, name : 'बलांगीर' },
                { id : 3, name : 'बालासोर' },
                { id : 4, name : 'बरगढ़' },
                { id : 5, name : 'भद्रक' },
                { id : 6, name : 'बौध' },
                { id : 7, name : 'कटक' },
                { id : 8, name : 'देवगढ़' },
                { id : 9, name : 'ढेंकनाल' },
                { id : 10, name : 'गजापति' },
                { id : 11, name : 'गञ्जाम' },
                { id : 12, name : 'जगतसिंहपुर' },
                { id : 13, name : 'जाजपुर' },
                { id : 14, name : 'झारसुगुडा' },
                { id : 15, name : 'कलाहांडी' },
                { id : 16, name : 'कंधमाल' },
                { id : 17, name : 'केंद्रपाड़ा' },
                { id : 18, name : 'केंदुझार' },
                { id : 19, name : 'खोरधा' },
                { id : 20, name : 'कोरापुट' },
                { id : 21, name : 'मालकानगिरि' },
                { id : 22, name : 'मयूरभंज' },
                { id : 23, name : 'नबरंगपुर' },
                { id : 24, name : 'नयागढ़' },
                { id : 25, name : 'नुआपड़ा' },
                { id : 26, name : 'पुरी' },
                { id : 27, name : 'रायगढ़' },
                { id : 28, name : 'संबलपुर' },
                { id : 29, name : 'सुबर्णपुर' },
                { id : 30, name : 'सुंदरगढ़' }
            ]
        },
        {
            id        : 27,
            name      : 'पुदुचेरी',
            districts : [
                { id : 1, name : 'कराईकल' },
                { id : 2, name : 'माहे' },
                { id : 3, name : 'पुदुचेरी' },
                { id : 4, name : 'यानम' }
            ]
        },
        {
            id        : 28,
            name      : 'पंजाब',
            districts : [
                { id : 1, name : 'अमृतसर' },
                { id : 2, name : 'बरनाला' },
                { id : 3, name : 'बठिंडा' },
                { id : 4, name : 'फरीदकोट' },
                { id : 5, name : 'फतेहगढ़ साहिब' },
                { id : 6, name : 'फ़ाज़िल्का' },
                { id : 7, name : 'फ़िरोज़पुर' },
                { id : 8, name : 'गुरदासपुर' },
                { id : 9, name : 'होशियारपुर' },
                { id : 10, name : 'जालंधर' },
                { id : 11, name : 'कपूरथला' },
                { id : 12, name : 'लुधियाना' },
                { id : 13, name : 'मलेरकोटला' },
                { id : 14, name : 'मंसा' },
                { id : 15, name : 'मोगा' },
                { id : 16, name : 'मोहाली' },
                { id : 17, name : 'मुक्तसर' },
                { id : 18, name : 'पठानकोट' },
                { id : 19, name : 'पटियाला' },
                { id : 20, name : 'रूपनगर' },
                { id : 21, name : 'संगरूर' },
                { id : 22, name : 'शहीद भगत सिंह नगर' },
                { id : 23, name : 'तारन तारन' }
            ]
        },
        {
            id        : 29,
            name      : 'राजस्थान',
            districts : [
                { id : 1, name : 'अजमेर' },
                { id : 2, name : 'अलवर' },
                { id : 3, name : 'बांसवाड़ा' },
                { id : 4, name : 'बारां' },
                { id : 5, name : 'बाड़मेर' },
                { id : 6, name : 'भरतपुर' },
                { id : 7, name : 'भीलवाड़ा' },
                { id : 8, name : 'बीकानेर' },
                { id : 9, name : 'बूंदी' },
                { id : 10, name : 'चित्तौड़गढ़' },
                { id : 11, name : 'चुरु' },
                { id : 12, name : 'दौसा' },
                { id : 13, name : 'धौलपुर' },
                { id : 14, name : 'डूंगरपुर' },
                { id : 15, name : 'हनुमानगढ़' },
                { id : 16, name : 'जयपुर' },
                { id : 17, name : 'जैसलमेर' },
                { id : 18, name : 'जालोर' },
                { id : 19, name : 'झालावाड़' },
                { id : 20, name : 'झुंझुनू' },
                { id : 21, name : 'जोधपुर' },
                { id : 22, name : 'करौली' },
                { id : 23, name : 'कोटा' },
                { id : 24, name : 'नागौर' },
                { id : 25, name : 'पाली' },
                { id : 26, name : 'प्रतापगढ़' },
                { id : 27, name : 'राजसमंद' },
                { id : 28, name : 'सवाई माधोपुर' },
                { id : 29, name : 'सीकर' },
                { id : 30, name : 'सिरोही' },
                { id : 31, name : 'श्री गंगानगर' },
                { id : 32, name : 'टोंक' },
                { id : 33, name : 'उदयपुर' }
            ]
        },
        {
            id        : 30,
            name      : 'सिक्किम',
            districts : [
                { id : 1, name : 'पूर्वी सिक्किम' },
                { id : 2, name : 'उत्तर सिक्किम' },
                { id : 3, name : 'पाक्योंग' },
                { id : 4, name : 'सोरेंग' },
                { id : 5, name : 'दक्षिण सिक्किम' },
                { id : 6, name : 'पश्चिम सिक्किम' }
            ]
        },
        {
            id        : 31,
            name      : 'तमिलनाडु',
            districts : [
                { id : 1, name : 'अरियालूर' },
                { id : 2, name : 'चेंगलपट्टू' },
                { id : 3, name : 'चेन्नई' },
                { id : 4, name : 'कोयंबटूर' },
                { id : 5, name : 'कुड्डालोर' },
                { id : 6, name : 'धर्मपुरी' },
                { id : 7, name : 'दिन्दीगुल' },
                { id : 8, name : 'इरोड' },
                { id : 9, name : 'कल्लाकुरिचि' },
                { id : 10, name : 'कांचीपुरम' },
                { id : 11, name : 'कन्याकुमारी' },
                { id : 12, name : 'कारूर' },
                { id : 13, name : 'कृष्णागिरी' },
                { id : 14, name : 'मदुरै' },
                { id : 15, name : 'मयिलादुतुरै' },
                { id : 16, name : 'नागपट्टिनम' },
                { id : 17, name : 'नामक्कल' },
                { id : 18, name : 'नीलगिरिस' },
                { id : 19, name : 'पेरम्बलूर' },
                { id : 20, name : 'पुदुक्कोट्टै' },
                { id : 21, name : 'रामनाथपुरम' },
                { id : 22, name : 'राणीपेट' },
                { id : 23, name : 'सलेम' },
                { id : 24, name : 'शिवगंगा' },
                { id : 25, name : 'टेंकसी' },
                { id : 26, name : 'तंजावुर' },
                { id : 27, name : 'थेनी' },
                { id : 28, name : 'तूतुकुड़ी' },
                { id : 29, name : 'तिरुचिराप्पल्लि' },
                { id : 30, name : 'तिरुनेलवेलि' },
                { id : 31, name : 'तिरुपत्तूर' },
                { id : 32, name : 'तिरुप्पूर' },
                { id : 33, name : 'तिरुवल्लुर' },
                { id : 34, name : 'तिरुवन्नामलै' },
                { id : 35, name : 'तिरुवारुर' },
                { id : 36, name : 'वेल्लोर' },
                { id : 37, name : 'विलुप्पुरम' },
                { id : 38, name : 'विरुधुनगर' }
            ]
        },
        {
            id        : 32,
            name      : 'तेलंगाना',
            districts : [
                { id : 1, name : 'आदिलाबाद' },
                { id : 2, name : 'भद्राद्रि कोठागुदेम' },
                { id : 3, name : 'हनमकोंडा' },
                { id : 4, name : 'हैदराबाद' },
                { id : 5, name : 'जग्तियाल' },
                { id : 6, name : 'जंगाओं' },
                { id : 7, name : 'जयशंकर' },
                { id : 8, name : 'जोगुलाम्बा' },
                { id : 9, name : 'कमारेड्डी' },
                { id : 10, name : 'करीमनगर' },
                { id : 11, name : 'खम्मम' },
                { id : 12, name : 'कोमरम भीम' },
                { id : 13, name : 'महाबूबाबाद' },
                { id : 14, name : 'महबूबनगर' },
                { id : 15, name : 'मंचेरियल' },
                { id : 16, name : 'मेदक' },
                { id : 17, name : 'मेदचल मालकाजगिरि' },
                { id : 18, name : 'मुलुगु' },
                { id : 19, name : 'नगरकुरनूल' },
                { id : 20, name : 'नालगोंडा' },
                { id : 21, name : 'नारायणपेट' },
                { id : 22, name : 'निर्मल' },
                { id : 23, name : 'निजामाबाद' },
                { id : 24, name : 'पेड्डापल्लि' },
                { id : 25, name : 'राजन्ना सिर्सिल्ला' },
                { id : 26, name : 'रंगा रेड्डी' },
                { id : 27, name : 'संगारेड्डी' },
                { id : 28, name : 'सिद्दिपेट' },
                { id : 29, name : 'सूर्यापेट' },
                { id : 30, name : 'विकाराबाद' },
                { id : 31, name : 'वानापर्थि' },
                { id : 32, name : 'वारंगल' },
                { id : 33, name : 'यदाद्रि भुवनागिरि' }
            ]
        },
        {
            id        : 33,
            name      : 'त्रिपुरा',
            districts : [
                { id : 1, name : 'धलाई' },
                { id : 2, name : 'गोमती' },
                { id : 3, name : 'खोवाई' },
                { id : 4, name : 'उत्तर त्रिपुरा' },
                { id : 5, name : 'सेपहिजाला' },
                { id : 6, name : 'दक्षिण त्रिपुरा' },
                { id : 7, name : 'उनाकोटि' },
                { id : 8, name : 'पश्चिम त्रिपुरा' }
            ]
        },
        {
            id        : 34,
            name      : 'उत्तर प्रदेश',
            districts : [
                { id : 1, name : 'आगरा' },
                { id : 2, name : 'अलीगढ़' },
                { id : 3, name : 'अंबेडकर नगर' },
                { id : 4, name : 'अमेठी' },
                { id : 5, name : 'अमरोहा' },
                { id : 6, name : 'औरैया' },
                { id : 7, name : 'अयोध्या' },
                { id : 8, name : 'आज़मगढ़' },
                { id : 9, name : 'बागपत' },
                { id : 10, name : 'बहराइच' },
                { id : 11, name : 'बलिया' },
                { id : 12, name : 'बलरामपुर' },
                { id : 13, name : 'बांदा' },
                { id : 14, name : 'बाराबंकी' },
                { id : 15, name : 'बरेली' },
                { id : 16, name : 'बस्ती' },
                { id : 17, name : 'भदोही' },
                { id : 18, name : 'बिजनौर' },
                { id : 19, name : 'बदायूं' },
                { id : 20, name : 'बुलंदशहर' },
                { id : 21, name : 'चंदौली' },
                { id : 22, name : 'चित्रकूट' },
                { id : 23, name : 'देवरिया' },
                { id : 24, name : 'एटा' },
                { id : 25, name : 'एटावा' },
                { id : 26, name : 'फ़र्रूख़ाबाद' },
                { id : 27, name : 'फतेहपुर' },
                { id : 28, name : 'फ़िरोज़ाबाद' },
                { id : 29, name : 'गौतम बुद्ध नगर' },
                { id : 30, name : 'गाज़ियाबाद' },
                { id : 31, name : 'ग़ाज़ीपुर' },
                { id : 32, name : 'गोंडा' },
                { id : 33, name : 'गोरखपुर' },
                { id : 34, name : 'हमीरपुर' },
                { id : 35, name : 'हापुड़' },
                { id : 36, name : 'हरदोई' },
                { id : 37, name : 'हाथरस' },
                { id : 38, name : 'जालौन' },
                { id : 39, name : 'जौनपुर' },
                { id : 40, name : 'झाँसी' },
                { id : 41, name : 'कन्नौज' },
                { id : 42, name : 'कानपुर देहात' },
                { id : 43, name : 'कानपुर नगर' },
                { id : 44, name : 'कासगंज' },
                { id : 45, name : 'कौशाम्बी' },
                { id : 46, name : 'लखीमपुर खीरी' },
                { id : 47, name : 'कुशीनगर' },
                { id : 48, name : 'ललितपुर' },
                { id : 49, name : 'लखनऊ' },
                { id : 50, name : 'महाराजगंज' },
                { id : 51, name : 'महोबा' },
                { id : 52, name : 'मैनपुरी' },
                { id : 53, name : 'मथुरा' },
                { id : 54, name : 'मऊ' },
                { id : 55, name : 'मेरठ' },
                { id : 56, name : 'मिर्ज़ापुर' },
                { id : 57, name : 'मुरादाबाद' },
                { id : 58, name : 'मुजफ्फरनगर' },
                { id : 59, name : 'पीलीभीत' },
                { id : 60, name : 'प्रतापगढ़' },
                { id : 61, name : 'प्रयागराज' },
                { id : 62, name : 'रायबरेली' },
                { id : 63, name : 'रामपुर' },
                { id : 64, name : 'सहारनपुर' },
                { id : 65, name : 'संभल' },
                { id : 66, name : 'संत कबीर नगर' },
                { id : 67, name : 'शाहजहांपुर' },
                { id : 68, name : 'शामली' },
                { id : 69, name : 'श्रावस्ती' },
                { id : 70, name : 'सिद्धार्थनगर' },
                { id : 71, name : 'सीतापुर' },
                { id : 72, name : 'सोनभद्र' },
                { id : 73, name : 'सुल्तानपुर' },
                { id : 74, name : 'उन्नाव' },
                { id : 75, name : 'वाराणसी' }
            ]
        },
        {
            id        : 35,
            name      : 'उत्तराखंड',
            districts : [
                { id : 1, name : 'अल्मोड़ा' },
                { id : 2, name : 'बागेश्वर' },
                { id : 3, name : 'चमोली' },
                { id : 4, name : 'चंपावत' },
                { id : 5, name : 'देहरादून' },
                { id : 6, name : 'हरिद्वार' },
                { id : 7, name : 'नैनीताल' },
                { id : 8, name : 'पौड़ी' },
                { id : 9, name : 'पिथौरागढ़' },
                { id : 10, name : 'रुद्रप्रयाग' },
                { id : 11, name : 'टेहरी' },
                { id : 12, name : 'उधम सिंह नगर' },
                { id : 13, name : 'उत्तरकाशी' }
            ]
        },
        {
            id        : 36,
            name      : 'पश्चिम बंगाल',
            districts : [
                { id : 1, name : 'आलीपुरदुआर' },
                { id : 2, name : 'बांकुरा' },
                { id : 3, name : 'बीरभूम' },
                { id : 4, name : 'कूच बिहार' },
                { id : 5, name : 'दक्षिण दिनाजपुर' },
                { id : 6, name : 'दार्जिलिंग' },
                { id : 7, name : 'हुगली' },
                { id : 8, name : 'हावड़ा' },
                { id : 9, name : 'जलपाईगुड़ी' },
                { id : 10, name : 'झाड़ग्राम' },
                { id : 11, name : 'कालिम्पोंग' },
                { id : 12, name : 'कोलकाता' },
                { id : 13, name : 'मालदा' },
                { id : 14, name : 'मुर्शिदाबाद' },
                { id : 15, name : 'नदिया' },
                { id : 16, name : 'उत्तर 24 परगना' },
                { id : 17, name : 'पश्चिम बर्धमान' },
                { id : 18, name : 'पश्चिम मेदिनीपुर' },
                { id : 19, name : 'पूर्व बर्धमान' },
                { id : 20, name : 'पूर्व मेदिनीपुर' },
                { id : 21, name : 'पुरुलिया' },
                { id : 22, name : 'दक्षिण 24 परगना' },
                { id : 23, name : 'उत्तर दिनाजपुर' }
            ]
        }
    ],
    occupationCategoryOptions : [
        {
            id          : 1,
            category    : 'निर्माण',
            occupations : [
                { name : 'इमारतें', id : 1 },
                { name : 'सड़कें', id : 2 },
                { name : 'पुल', id : 3 },
                { name : 'अन्य बुनियादी ढांचे', id : 4 }
            ]
        },
        {
            id          : 2,
            category    : 'कृषि',
            occupations : [
                { name : 'खेती', id : 1 },
                { name : 'वृक्षारोपण कार्य', id : 2 },
                { name : 'फसल की कटाई', id : 3 },
                { name : 'अन्य बुनियादी ढांचे', id : 4 }
            ]
        },
        {
            id          : 3,
            category    : 'घरेलू सहायक',
            occupations : [
                { name : 'घर की सफाई', id : 1 },
                { name : 'खाना बनाना', id : 2 },
                { name : 'बच्चों की देखभाल', id : 3 },
                { name : 'बुजुर्गों की देखभाल', id : 4 }
            ]
        },
        {
            id          : 4,
            category    : 'विनिर्माण और औद्योगिक श्रमिक',
            occupations : [
                { name : 'कपड़ा', id : 1 },
                { name : 'प्लाईवुड', id : 2 },
                { name : 'खाद्य प्रसंस्करण', id : 3 },
                { name : 'परिधान निर्माण', id : 4 }
            ]
        },
        {
            id          : 5,
            category    : 'हॉस्पिटैलिटी और सेवा उद्योग',
            occupations : [
                { name : 'गृह व्यवस्था', id : 1 },
                { name : 'सर्विंग', id : 2 },
                { name : 'फ्रंट डेस्क', id : 3 },
                { name : 'रखरखाव', id : 4 }
            ]
        },
        {
            id          : 6,
            category    : 'परिवहन और रसद',
            occupations : [
                { name : 'ड्राइवर', id : 1 },
                { name : 'लोडर', id : 2 },
                { name : 'सहायक', id : 3 },
                { name : 'भंडारण', id : 4 }
            ]
        },
        {
            id          : 7,
            category    : 'पथ विक्रेता और लघु व्यवसाय',
            occupations : [
                { name : 'पान की दुकान', id : 1 },
                { name : 'बुजी की दुकान', id : 2 },
                { name : 'फास्ट फूड', id : 3 },
                { name : 'अन्य', id : 4 }
            ]
        },
        {
            id          : 8,
            category    : 'स्वास्थ्य देखभाल सहायता स्टाफ',
            occupations : [
                { name : 'नर्स सहायक', id : 1 },
                { name : 'चौकीदार', id : 2 },
                { name : 'रोगी परिचारक', id : 3 }
            ]
        },
        {
            id          : 9,
            category    : 'मत्स्य पालन और समुद्री उद्योग',
            occupations : [
                { name : 'मछली पकड़ना', id : 1 },
                { name : 'मछली प्रसंस्करण', id : 2 },
                { name : 'नौका निर्माण', id : 3 },
                { name : 'समुद्री उद्योग', id : 4 }
            ]
        },
        {
            id          : 10,
            category    : 'अन्य',
            occupations : [
                { name : 'सफाई कर्मचारी', id : 1 },
                { name : 'सुरक्षा गार्ड', id : 2 },
                { name : 'हाथ से काम करने वाले मजदूर', id : 3 }
            ]
        }
    ],
    questionnaire : [
        {
            id          : 1,
            category_id : 1,
            questions   : [
                'क्या आपने पहले कभी काम से संबंधित चोट या दुर्घटना का अनुभव किया है?',
                'क्या आप वर्तमान में अपने काम से संबंधित किसी शारीरिक समस्या या दर्द का अनुभव कर रहे हैं?',
                'क्या आपको कन्स्ट्रकशन उद्योग के लिए विशिष्ट रूप से कोई व्यावसायिक स्वास्थ्य और सुरक्षा प्रशिक्षण या जागरूकता कार्यक्रम के बारे में बताया गया है?',
                'क्या आप सामान्य निर्माण-संबंधी स्वास्थ्य जोखिमों, जैसे गिरने, शोर के संपर्क या रासायनिक खतरों से बचने के लिए सुरक्षा प्रोटोकॉल और उपायों के बारे में जानते हैं?',
                'क्या कन्स्ट्रकशन वाले स्थलों पर काम करते समय आपके पास हेलमेट, दस्ताने, सुरक्षा चश्मे और हार्नेस जैसे व्यक्तिगत सुरक्षा उपकरण (पीपीई) का प्रयोग करते हैं?',
                'क्या आप पेशीकंकाली से जुड़ी चोटों से बचने के लिए उचित लिफ्टिंग टेक्निक और श्रम-दक्षता संबंधी चीजों के महत्व के बारे में जानते हैं?',
                'क्या आपने सामान्य व्यावसायिक स्वास्थ्य जोखिमों, जैसे टिटनेस, हैपेटाइटिस बी के लिए टीका, या अन्य ऐसा ही कोई टीका लगवाया है?',
                'क्या कन्स्ट्रकशन के काम से जुड़ी स्वास्थ्य समस्याओं, जैसे सांस से संबंधी समस्याएं, सुनने की क्षमता में कमी, या त्वचा संबंधी विकारों के लिए आपकी नियमित रूप से जांच या देखरेख की जाती है?',
                'क्या आपको थकान से बचने और बेहतर स्वास्थ्य बनाए रखने के लिए अपने काम की शिफ्ट के दौरान नियमित ब्रेक और आराम के लिए समय प्रदान किया जाता है?',
                'क्या आप कन्स्ट्रकशन वाले स्थल पर किसी भी स्वास्थ्य आपात स्थिति या चिंता के मामले में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों के बारे में जानते हैं?'
            ]
        },
        {
            id          : 2,
            category_id : 2,
            questions   : [
                'क्या आपने पहले कभी कृषि संबंधी कामकाज के दौरान काम से संबंधित किसी चोट या दुर्घटना का अनुभव किया है?',
                'क्या आप वर्तमान में कृषि में अपने काम से संबंधित किसी शारीरिक समस्या या स्वास्थ्य संबंधी समस्या का सामना कर रहे हैं?',
                'क्या आप कृषि के काम में कीटनाशकों, उर्वरकों या अन्य रसायनों के इस्तेमाल से जुड़े संभावित स्वास्थ्य जोखिमों के बारे में जानते हैं?',
                'क्या आपके पास कृषि रसायनों का इस्तेमाल समय या संभावित जोखिमों से भरे काम करते समय दस्ताने, मास्क या चश्मे जैसे व्यक्तिगत सुरक्षा उपकरण (पीपीई) का प्रयोग करते हैं और आप उनका समय-समय पर प्रयोग करते हैं?',
                'क्या आप कृषि संबंधी काम करते समय पेशीकंकाली से जुड़ी चोटों से बचने के लिए उचित लिफ्टिंग टेक्निक और श्रम-दक्षता संबंधी चीजों के महत्व के बारे में जानते हैं?',
                'क्या आप सामान्य कृषि स्वास्थ्य जोखिमों, जैसे गर्मी से संबंधित बीमारियों, सांस संबंधी समस्याओं, या पशुओं से होने वाली बीमारियों के जोखिम को रोकने के लिए सुरक्षा दिशानिर्देशों और कामकाज के तरीकों के बारे में जानते हैं?',
                'क्या आपको कृषि रसायनों और उपकरणों के सुरक्षित रख-रखाव और भंडारण के संबंध में कोई प्रशिक्षण या जानकारी प्राप्त हुई है?',
                'क्या कृषि के काम से जुड़ी स्वास्थ्य समस्याओं, जैसे सांस से संबंधी समस्याएं, त्वचा संबंधी विकार या एलर्जी के लिए आपकी नियमित रूप से जांच या देखरेख की जाती है?',
                'क्या आपको खेतों में काम करते समय गर्मी से संबंधित बीमारियों और डीहाइड्रेशन से बचने के लिए नियमित ब्रेक, स्वच्छ पेयजल और छायादार क्षेत्र उपलब्ध कराए जाते हैं?',
                'क्या आप किसी स्वास्थ्य आपात स्थिति या कृषि में अपने काम से संबंधित चिंताओं के मामले में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों के बारे में जानते हैं?'
            ]
        },
        {
            id          : 3,
            category_id : 3,
            questions   : [
                'क्या आपने पहले कभी घरेलू कामकाज के दौरान काम से संबंधित किसी चोट या दुर्घटना का अनुभव किया है?',
                'क्या आप वर्तमान में एक घरेलू सहायक के तौर पर अपने काम से संबंधित किसी शारीरिक समस्या या स्वास्थ्य संबंधी समस्या का सामना कर रहे हैं?',
                'क्या आप घरेलू काम करते समय पेशीकंकाली से जुड़ी चोटों से बचने के लिए उचित लिफ्टिंग टेक्निक और श्रम-दक्षता संबंधी चीजों के महत्व के बारे में जानते हैं?',
                'क्या आपके पास सफाई रसायनों का इस्तेमाल करते समय या संभावित खतरों वाले काम करते समय व्यक्तिगत सुरक्षा उपकरण (पीपीई) जैसे दस्ताने, मास्क या एप्रन रहते हैं और आप उनका समय समय पर प्रयोग करते हैं?',
                'क्या आप सफाई रसायनों, डिटर्जेंट और अन्य घरेलू उत्पादों के सुरक्षित इस्तेमाल और भंडारण के बारे में जानते हैं?',
                'क्या आपको थकान से बचने और बेहतर स्वास्थ्य बनाए रखने के लिए अपने काम की शिफ्ट के दौरान नियमित ब्रेक और आराम के लिए समय प्रदान किया जाता है?',
                'क्या आप सफाई का काम करते समय घर के अंदर अच्छी वायु गुणवत्ता और वेंटिलेशन बनाए रखने के महत्व के बारे में जानते हैं?',
                'क्या घरेलू सहायक के तौर पर आपके काम से जुड़ी स्वास्थ्य समस्याओं, जैसे सांस से संबंधी समस्याएं, एलर्जी या त्वचा संबंधी विकारों के लिए आपकी नियमित रूप से जांच या देखरेख की जाती है?',
                'क्या आपको बुनियादी प्राथमिक चिकित्सा के बारे में प्रशिक्षण या जानकारी प्रदान की गई है, जिसमें आपातकालीन संपर्क नंबरों और दुर्घटनाओं या स्वास्थ्य आपात स्थितियों के मामले में की जाने वाली कार्रवाइयों के बारे में जानकारी शामिल है?',
                'क्या आप किसी स्वास्थ्य आपात स्थिति या घरेलू सहायक के तौर पर अपने काम से संबंधित चिंताओं के मामले में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों के बारे में जानते हैं?'
            ]
        },
        {
            id          : 4,
            category_id : 4,
            questions   : [
                'क्या आपने पहले कभी विनिर्माण या औद्योगिक स्थानों पर काम करते समय काम से संबंधित किसी चोट या दुर्घटना का अनुभव किया है?',
                'क्या आप वर्तमान में विनिर्माण या औद्योगिक स्थानों पर अपने काम से संबंधित किसी शारीरिक समस्या या स्वास्थ्य संबंधी समस्या का सामना कर रहे हैं?',
                'क्या आप विनिर्माण या औद्योगिक क्षेत्र में अपनी विशिष्ट नौकरी भूमिका से जुड़े संभावित स्वास्थ्य जोखिमों के बारे में जानते हैं?',
                'क्या संभावित जोखिमों वाले क्षेत्रों में काम करते समय आपके पास सुरक्षात्मक चश्मे, दस्ताने, कान के लिए सुरक्षा उपकरण, या मास्क जैसे व्यक्तिगत सुरक्षा उपकरण (पीपीई) रहते हैं और आप उनका समय-समय पर प्रयोग करते हैं?',
                'क्या आप सामान्य औद्योगिक स्वास्थ्य जोखिमों, जैसे हानिकारक रसायनों के संपर्क में आना, शोर के कारण सुनने की क्षमता में कमी, या श्रम-दक्षता संबंधी चोटों से बचने के लिए सुरक्षा प्रोटोकॉल और उपायों के बारे में जानते हैं?',
                'क्या आपको थकान से बचने और बेहतर स्वास्थ्य बनाए रखने के लिए अपने काम की शिफ्ट के दौरान नियमित ब्रेक और आराम के लिए समय प्रदान किया जाता है?',
                'क्या आप अपना काम करते समय पेशीकंकाली संबंधी चोटों के जोखिम को कम करने के लिए उचित मुद्रा बनाए रखने और श्रम-दक्षता संबंधी उपकरण का इस्तेमाल करने के महत्व के बारे में जानते हैं?',
                'क्या विनिर्माण या औद्योगिक क्षेत्र में आपके काम से जुड़ी स्वास्थ्य समस्याओं, जैसे सांस से संबंधी समस्याएं, सुनने की क्षमता में कमी, या व्यावसायिक संबंधित बीमारियों के लिए आपकी नियमित रूप से जांच या देखरेख की जाती है?',
                'क्या आपको बुनियादी प्राथमिक चिकित्सा के बारे में प्रशिक्षण या जानकारी प्रदान की गई है, जिसमें आपातकालीन संपर्क नंबरों और दुर्घटनाओं या स्वास्थ्य आपात स्थितियों के मामले में की जाने वाली कार्रवाइयों के बारे में जानकारी शामिल है?',
                'क्या आप विनिर्माण या औद्योगिक क्षेत्र में अपने काम से संबंधित किसी स्वास्थ्य आपात स्थिति या चिंताओं के मामले में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों के बारे में जानते हैं?'
            ]
        },
        {
            id          : 5,
            category_id : 5,
            questions   : [
                'क्या आपने पहले कभी हॉस्पिटैलिटी और सेवा उद्योग में काम करते समय काम से संबंधित किसी चोट या दुर्घटना का अनुभव किया है?',
                'क्या आप वर्तमान में हॉस्पिटैलिटी और सेवा उद्योग में अपने काम से संबंधित किसी शारीरिक समस्या या स्वास्थ्य संबंधी समस्या का सामना कर रहे हैं?',
                'क्या आप हॉस्पिटैलिटी और सेवा उद्योग में अपनी विशिष्ट नौकरी भूमिका से जुड़े संभावित स्वास्थ्य जोखिमों के बारे में जानते हैं?',
                'क्या आपके पास भोजन, रसायनों का इस्तेमाल करते समय या संभावित खतरों वाले कामों को करते समय दस्ताने, एप्रन या मास्क जैसे व्यक्तिगत सुरक्षा उपकरण (पीपीई) तक रहते हैं और आप उनका समय-समय पर प्रयोग करते हैं?',
                'क्या आप खाने-पीने की चीजों से होने वाली बीमारियों से बचने और खाने-पीने की चीजों की सुरक्षा सुनिश्चित करने के लिए उचित भोजन प्रबंधन और स्वच्छता संबंधी तौर-तरीकों के बारे में जानते हैं?',
                'क्या आपको थकान से बचने और बेहतर स्वास्थ्य बनाए रखने के लिए अपने काम की शिफ्ट के दौरान नियमित ब्रेक और आराम के लिए समय प्रदान किया जाता है?',
                'क्या आप अपना काम करते समय पेशीकंकाली संबंधी चोटों के जोखिम को कम करने के लिए उचित मुद्रा बनाए रखने और श्रम-दक्षता संबंधी उपकरण का इस्तेमाल करने के महत्व के बारे में जानते हैं?',
                'क्या हॉस्पिटैलिटी और सेवा उद्योग में आपके काम से जुड़ी स्वास्थ्य समस्याओं, जैसे बार-बार मोच आना, सांस से संबंधी समस्याएं, या तनाव से संबंधित समस्याओं के लिए आपकी नियमित रूप से जांच या देखरेख की जाती है?',
                'क्या आपको बुनियादी प्राथमिक चिकित्सा के बारे में प्रशिक्षण या जानकारी प्रदान की गई है, जिसमें आपातकालीन संपर्क नंबरों और दुर्घटनाओं या स्वास्थ्य आपात स्थितियों के मामले में की जाने वाली कार्रवाइयों के बारे में जानकारी शामिल है?',
                'क्या आप हॉस्पिटैलिटी और सेवा उद्योग में अपने काम से संबंधित किसी स्वास्थ्य आपात स्थिति या चिंताओं के मामले में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों के बारे में जानते हैं?'
            ]
        },
        {
            id          : 6,
            category_id : 6,
            questions   : [
                'क्या आपने पहले कभी ट्रांसपोर्टेशन और लॉजिस्टिक्स में काम करते समय किसी कार्य-संबंधी चोट या दुर्घटना का अनुभव किया है?',
                'क्या आप वर्तमान में ट्रांसपोर्टेशन और लॉजिस्टिक्स में अपने काम से संबंधित किसी शारीरिक समस्या या स्वास्थ्य संबंधी समस्या का सामना कर रहे हैं?',
                'क्या आप ट्रांसपोर्टेशन और लॉजिस्टिक्स क्षेत्र में अपनी विशिष्ट नौकरी की भूमिका से जुड़े संभावित स्वास्थ्य जोखिमों के बारे में जानते हैं?',
                'क्या आपके पास संभावित जोखिमों से भरे काम करते समय हेलमेट, सुरक्षा जैकेट, दस्ताने या मास्क जैसे व्यक्तिगत सुरक्षा उपकरण (पीपीई) तक रहते हैं और आप उनका समय-समय पर प्रयोग करते हैं?',
                'क्या आप सीट बेल्ट का सही उपयोग, यातायात नियमों का पालन और सड़क सुरक्षा बनाए रखने सहित सुरक्षित ड्राइविंग के तौर-तरीकों के बारे में जानते हैं?',
                'क्या आपको थकान से बचने और बेहतर स्वास्थ्य बनाए रखने के लिए अपने काम की शिफ्ट के दौरान नियमित ब्रेक और आराम के लिए समय प्रदान किया जाता है?',
                'क्या आप गाड़ी चलाते समय या माल ढोते समय पेशीकंकाली संबंधी चोटों के जोखिम को कम करने के लिए उचित मुद्रा बनाए रखने और श्रम-दक्षता संबंधी उपकरण, जैसे कमर के लिए सहारा या समायोजित की जा सकने वाली सीट का प्रयोग करने के महत्व के बारे में जानते हैं?',
                'क्या ट्रांसपोर्टेशन और लॉजिस्टिक्स क्षेत्र में आपके काम से जुड़ी स्वास्थ्य समस्याओं, जैसे पीठ का दर्द, देखने में समस्या, या नींद संबंधी विकार, के लिए आपकी नियमित रूप से जांच या देखरेख की जाती है?',
                'क्या आपको बुनियादी प्राथमिक चिकित्सा के बारे में प्रशिक्षण या जानकारी प्रदान की गई है, जिसमें आपातकालीन संपर्क नंबरों की जानकारी होना और सड़क पर या कार्यस्थल पर दुर्घटनाओं या स्वास्थ्य आपात स्थितियों के मामले में की जाने वाली कार्रवाइयां शामिल हैं?',
                'क्या आप ट्रांसपोर्टेशन और लॉजिस्टिक्स क्षेत्र में अपने काम से संबंधित किसी स्वास्थ्य आपात स्थिति या चिंताओं के मामले में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों के बारे में जानते हैं?'
            ]
        },
        {
            id          : 7,
            category_id : 7,
            questions   : [
                'क्या आपने पहले कभी पथ विक्रेता और लघु व्यवसायी के रूप में काम करते समय काम से संबंधित किसी चोट या दुर्घटना का अनुभव किया है?',
                'क्या आप वर्तमान में पथ विक्रेता और लघु व्यवसायी के रूप में अपने काम से संबंधित किसी शारीरिक समस्या या स्वास्थ्य संबंधी समस्या का सामना कर रहे हैं?',
                'क्या आप पथ विक्रेता और लघु व्यवसायी के रूप में अपनी विशिष्ट नौकरी भूमिका से जुड़े संभावित स्वास्थ्य जोखिमों के बारे में जानते हैं?',
                'क्या आपके पास भोजन संभालते समय या संभावित खतरों वाले कामों को करते समय दस्ताने, एप्रन या मास्क जैसे व्यक्तिगत सुरक्षा उपकरण (पीपीई) रहते हैं और आप उनका समय-समय पर प्रयोग करते हैं?',
                'क्या आप खाने-पीने की चीजों से होने वाली बीमारियों से बचने और खाने-पीने की चीजों की सुरक्षा सुनिश्चित करने के लिए उचित भोजन प्रबंधन और स्वच्छता संबंधी तौर-तरीकों के बारे में जानते हैं?',
                'क्या आपको थकान से बचने और बेहतर स्वास्थ्य बनाए रखने के लिए अपने काम की शिफ्ट के दौरान नियमित ब्रेक और आराम के लिए समय प्रदान किया जाता है?',
                'क्या आप एक पथ विक्रेता और लघु व्यवसायी के रूप में अपना काम करते समय पेशीकंकाली संबंधी चोटों के जोखिम को कम करने के लिए उचित मुद्रा बनाए रखने और श्रम-दक्षता संबंधी उपकरण का इस्तेमाल करने के महत्व के बारे में जानते हैं?',
                'क्या आप आपके काम से जुड़ी स्वास्थ्य समस्याओं, जैसे सांस से संबंधी समस्याएं, त्वचा संबंधी विकार या तनाव से संबंधित समस्याओं के लिए आपकी नियमित रूप से जांच या देखरेख की जाती है?',
                'क्या आपको अपने कार्यस्थल पर बुनियादी प्राथमिक चिकित्सा के बारे में प्रशिक्षण या जानकारी प्रदान की गई है, जिसमें आपातकालीन संपर्क नंबरों और दुर्घटनाओं या स्वास्थ्य आपात स्थितियों के मामले में की जाने वाली कार्रवाइयों के बारे में जानकारी शामिल है?',
                'क्या आप पथ विक्रेता और लघु व्यवसायी के रूप में अपने काम से संबंधित किसी स्वास्थ्य आपात स्थिति या चिंताओं के मामले में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों के बारे में जानते हैं?'
            ]
        },
        {
            id          : 8,
            category_id : 8,
            questions   : [
                'क्या आपको कभी अपने या अपने आश्रितों के लिए स्वास्थ्य सेवाएँ प्राप्त करने में चुनौतियों का सामना करना पड़ा है?',
                'क्या आप स्वास्थ्य देखभाल सहायता स्टाफ सदस्य के रूप में आपके लिए उपलब्ध स्वास्थ्य देखभाल लाभों और संसाधनों से अवगत हैं?',
                'क्या आपको कभी आवश्यकता पड़ने पर समय पर चिकित्सा उपचार या परामर्श प्राप्त करने में किसी कठिनाई का सामना करना पड़ा है?',
                'क्या आप स्वास्थ्य संस्थान द्वारा प्रदान की जाने वाली स्वास्थ्य सुविधाओं से संतुष्ट हैं?',
                'क्या आपने स्वास्थ्य देखभाल सहायता स्टाफ सदस्य के रूप में स्वास्थ्य सेवाओं के लिए अपने अधिकारों और हकदारियों के संबंध में प्रशिक्षण या जानकारी प्राप्त की है?',
                'क्या आप अपने कार्यस्थल में स्वास्थ्य संबंधी चिंताओं या व्यावसायिक खतरों की रिपोर्ट करने की प्रक्रिया से अवगत हैं?',
                'क्या आपको स्वास्थ्य सेवा उद्योग में अपनी भूमिका से संबंधित आवश्यक टीकाकरण या निवारक उपाय प्राप्त करने में किसी बाधा का सामना करना पड़ा है?',
                'क्या आपको अपने स्वास्थ्य की निगरानी करने और किसी भी संभावित स्वास्थ्य समस्याओं का पता लगाने के लिए नियमित स्वास्थ्य जांच या स्क्रीनिंग प्रदान की जाती है?',
                'क्या आपके पास काम से संबंधित तनाव या मानसिक स्वास्थ्य को संबोधित करने के लिए मानसिक स्वास्थ्य सहायता या परामर्श सेवाओं तक पहुंच है?',
                'क्या आप काम के दौरान आपातकालीन चिकित्सा सहायता प्राप्त करने के लिए उपलब्ध प्रक्रियाओं और संसाधनों के बारें में जानते है?'
            ]
        },
        {
            id          : 9,
            category_id : 9,
            questions   : [
                'क्या आपको कभी मत्स्य पालन और समुद्री उद्योग में काम करते समय अपने या अपने आश्रितों के लिए स्वास्थ्य सेवाएँ प्राप्त करने में चुनौतियों का सामना करना पड़ा है?',
                'क्या आप मत्स्य पालन और समुद्री उद्योग श्रमिक के रूप में आपके लिए उपलब्ध स्वास्थ्य देखभाल लाभों और संसाधनों से अवगत हैं?',
                'क्या आपको कभी आवश्यकता पड़ने पर समय पर चिकित्सा उपचार या परामर्श प्राप्त करने में किसी कठिनाई का सामना करना पड़ा है? अगर हां तो कृपया विवरण दें।',
                'क्या आप मत्स्य पालन और समुद्री उद्योग में अपने काम के संबंध में अपने नियोक्ता या संस्थान द्वारा प्रदान की जाने वाली स्वास्थ्य सुविधाओं से संतुष्ट हैं? यदि नहीं, तो आप क्या सुधार करवाना चाहेंगे?',
                'क्या आप व्यावसायिक खतरों या मत्स्य पालन और समुद्री उद्योग से जुड़ी चोटों, जैसे पानी से होने वाली बीमारियों या मछली पकड़ने के उपकरण को संभालने में लगने वाली चोटों के लिए उपलब्ध विशिष्ट स्वास्थ्य सेवाओं या सहायता के बारे में जानते हैं?',
                'क्या आपने मत्स्य पालन और समुद्री उद्योग श्रमिक के रूप में स्वास्थ्य सेवाओं के लिए अपने अधिकारों और हकदारियों के संबंध में प्रशिक्षण या जानकारी प्राप्त की है?',
                'क्या आप जहाज़ पर या समुद्र में आपातकालीन चिकित्सा सहायता प्राप्त करने के लिए उपलब्ध प्रक्रियाओं और संसाधनों से परिचित हैं?',
                'क्या आपको मत्स्य पालन और समुद्री उद्योग में अपनी भूमिका से संबंधित आवश्यक टीकाकरण या निवारक उपाय प्राप्त करने में किसी बाधा का सामना करना पड़ा है?',
                'क्या आपको अपनी सेहत का ध्यान रखने और किसी संभावित स्वास्थ्य समस्या का पता लगाने के लिए नियमित स्वास्थ्य जांच या स्क्रीनिंग प्रदान की जाती है?',
                'क्या आपके पास मत्स्य पालन और समुद्री उद्योग में काम करते समय काम से संबंधित तनाव या मानसिक स्वास्थ्य पर ध्यान देने के लिए मानसिक स्वास्थ्य सहायता या परामर्श सेवाओं तक पहुंच है?'
            ]
        },
        {
            id          : 10,
            category_id : 10,
            questions   : [
                'क्या आपको कभी अपने या अपने आश्रितों के लिए स्वास्थ्य सेवाएँ प्राप्त करने में चुनौतियों का सामना करना पड़ा है?',
                'क्या आप अपने विशिष्ट उद्योग या क्षेत्र में एक मजदूर के रूप में उपलब्ध स्वास्थ्य देखभाल लाभों और संसाधनों से अवगत हैं?',
                'क्या आपको कभी आवश्यकता पड़ने पर समय पर चिकित्सा उपचार या परामर्श प्राप्त करने में किसी कठिनाई का सामना करना पड़ा है? ',
                'क्या आप स्वास्थ्य संस्थान द्वारा प्रदान की जाने वाली स्वास्थ्य सुविधाओं से संतुष्ट हैं?',
                'क्या आप अपने कार्यक्षेत्र से जुड़े विशिष्ट व्यावसायिक स्वास्थ्य जोखिमों से अवगत हैं?',
                'क्या आप अपने क्षेत्र में एक मजदूर के रूप में स्वास्थ्य सेवाओं के अपने अधिकारों और हकदारियों से परिचित हैं?',
                'क्या आपको अपनी भूमिका से संबंधित आवश्यक टीकाकरण या निवारक उपाय प्राप्त करने में किसी बाधा का सामना करना पड़ा है?',
                'क्या आपको अपनी सेहत का ध्यान रखने और किसी संभावित स्वास्थ्य समस्या का पता लगाने के लिए नियमित स्वास्थ्य जांच या स्क्रीनिंग प्रदान की जाती है?',
                'क्या आप अपने क्षेत्र में उपलब्ध स्वास्थ्य सुविधाओं और संसाधनों से अवगत हैं? यदि नहीं, तो क्या आप उन तक पहुंचने के तरीके के बारे में अधिक जानकारी प्राप्त करना चाहेंगे?',
                'क्या आपके पास काम से संबंधित तनाव या मानसिक स्वास्थ्य को संबोधित करने के लिए मानसिक स्वास्थ्य सहायता या परामर्श सेवाओं तक पहुंच है?'
            ]
        }
    ]
};

export default hindi;
