const bengali = {
    generalContents : {
        menuContents : {
            projectTitle : 'হেলথ মিত্র',
            home         : 'হোম ',
            about        : 'সম্বন্ধে',
            contactUs    : 'যোগাযোগ করুন',
            language     : 'ভাষা',
            registerNow  : 'রেজিস্টার করুন'
        },
        footerContents : {
            copyright : 'কপিরাইট',
            callUs    : 'কল করুন'
        }
    },
    // Page Contents
    homePageContents : {
        languageContent : {
            labelText          : 'এগিয়ে যেতে একটি ভাষা পছন্দ করুন',
            selectLanguageText : 'ভাষা নির্বাচন করুন',
            submitButton       : 'চালিয়ে যান'
        },
        bannerContent : {
            welcomeText : 'হেলথ মিটারে স্বাগত',
            text1       : 'বিজ্ঞান ও প্রযুক্তি বিভাগ, GOI এবং মহাত্মা গান্ধী বিশ্ববিদ্যালয় প্রজেক্ট',
            text2       : '"কেরালায় আন্তঃরাজ্য অভিবাসী শ্রমিকদের মধ্যে স্বাস্থ্যসেবার অ্যাক্সেসের উপর সামাজিক \n' +
                'প্রাতিষ্ঠানিক এবং প্রযুক্তিগত হস্তক্ষেপের প্রভাব" বিষয়ে বিজ্ঞান ও প্রকৌশল গবেষণা বোর্ড প্রকল্পের একটি উদ্যোগ \n' +
                '(CRG/2021/004314)।\n',
            registerNow : 'রেজিস্টার করুন',
            callUsText1 : 'কল করুন',
            callUsText2 : 'যেকোনো অনুসন্ধানের জন্য'
        },
        aboutContent : {
            heading : ' পরিচিত হওয়া যাক',
            text1   : 'আমরা আন্তঃরাজ্য অভিবাসী শ্রমিকদের স্বাস্থ্যের অধিকারের বুঝে  জীবন উন্নত করতে এবং স্বাস্থ্যসেবা \n' +
                'পাওয়ার জন্য প্রয়োজনীয় যোগাযোগ প্রদান করি।',
            text2 : 'আমাদের লক্ষ্য হল সমাজ এবং তাদের পরিবারের জন্য নিজেদের ভালো ভবিষ্যত গড়ে তোলার সংগ্রাম \n' +
                'করার সময় কোনও কর্মী যাতে অসুবিধার সম্মুখীন না হয় তা নিশ্চিত করা।'
        },
        contactUs : {
            title        : 'যোগাযোগ করুন',
            formContents : {
                name         : 'পূর্ণ নাম',
                email        : 'আপনার ইমেইল',
                phone        : 'আপনার ফোন',
                message      : 'আপনার ম্যাসেজ',
                submitButton : 'ম্যাসেজ পাঠান'
            }
        }
    },
    registrationContents : {
        title        : 'রেজিস্ট্রেশন',
        formContents : {
            basicDetailsTitle            : 'সাধারণ বিবরণ',
            nativeAddressTitleText       : 'স্থানীয় ঠিকানা',
            keralaAddressTitleText       : 'কেরালার ঠিকানা',
            phoneValidation              : 'ফোন নাম্বারের সাথে আকাউন্ট ইতিমধ্যে আছে',
            name                         : 'পূর্ণ নাম ',
            phone                        : 'ফোন  ',
            age                          : 'বয়স',
            gender                       : 'লিঙ্গ',
            maritalStatus                : 'বৈবাহিক অবস্থা',
            nativeLocality               : 'স্থানীয় শহর/স্থান ',
            nativeState                  : 'রাজ্য ',
            nativeDistrict               : 'জেলা',
            keralaLocality               : 'শহর/ কেরালার স্থান ',
            keralaDistrict               : 'কেরালার জেলা ',
            durationInKerala             : 'কেরালাতে থাকার সময়কাল ',
            submitButton1                : 'এগিয়ে যান ',
            occupationTitleText          : 'পেশা ',
            occupationCategory           : 'পেশার বিভাগ ',
            occupation                   : 'পেশা ',
            occupationSelectMessage      : 'একটি বিভাগ বেঁছে নিন ',
            medicalHistoryTitleText      : 'শারীরিক রোগ ',
            medicalHistoryWarningMessage : 'একটি পেশার বিভাগ বা পেশা বেঁছে নিন',
            submitButton2                : 'সাবমিট',
            backButton                   : 'পিছনে যান ',
            okayButton                   : 'ওকে '

        },
        successPageContents : {
            successMessage : 'সফল ',
            thanks         : 'রেজিস্ট্রারিং এর জন্য ধন্যবাদ ',
            noteTitle      : 'নোট ',
            noteContent    : 'নিম্নের রেজিস্টার বিবরণের কোন আপডেটের জন্য আডমিনিস্টেটরের সাথে নিঃসন্দেহে যোগাযোগ করুন'
        }
    },
    // Options and Questionnaire
    genderOptions : [
        { name : 'পুরুষ ', id : 1 },
        { name : 'Feমহিলা male', id : 2 },
        { name : 'উভয়লিঙ্গ', id : 3 },
        { name : 'জানাতে চাই না ', id : 4 }
    ],
    maritalStatusOptions : [
        { name : 'অবিবাহিত ', id : 1 },
        { name : 'বিবাহিত ', id : 2 },
        { name : 'বিচ্ছেদ ', id : 3 },
        { name : 'সঙ্গী পরলোকগত', id : 4 }
    ],
    durationOptions : [
        { name : '1 বছরের কম ', id : 1 },
        { name : '1 থেকে 2 বছরের মধ্যে ', id : 2 },
        { name : '3 থেকে 5 বছরের মধ্যে ', id : 3 },
        { name : '5 থেকে 10 বছরের মধ্যে ', id : 4 }
    ],
    stateDistrictOptions : [
        {
            id        : 1,
            name      : 'আন্দামান নিকোবার',
            districts : [
                { id : 1, name : 'নিকোবার' },
                { id : 2, name : 'উত্তর মধ্য আন্দামান' },
                { id : 3, name : 'দক্ষিণ আন্দামান' }
            ]

        },
        {
            id        : 2,
            name      : 'আন্ধ্র প্রদেশ',
            districts : [
                { id : 1, name : 'আনন্তপুর' },
                { id : 2, name : 'চিত্তূর' },
                { id : 3, name : 'পূর্ব গোদাবারী' },
                { id : 4, name : 'আল্লুরি সীতারাম রাজু' },
                { id : 5, name : 'আনাকাপল্লি' },
                { id : 6, name : 'অন্নামায়া' },
                { id : 7, name : 'বাপতলা' },
                { id : 8, name : 'এলুরু' },
                { id : 9, name : 'গুন্টূর' },
                { id : 10, name : 'কাড়াপা' },
                { id : 11, name : 'কাকিনাড়া' },
                { id : 12, name : 'কোনাসীমা' },
                { id : 13, name : 'কৃষ্ণা' },
                { id : 14, name : 'কুর্নুল' },
                { id : 15, name : 'মান্যাম' },
                { id : 16, name : 'এন টি রামা রাও' },
                { id : 17, name : 'নান্দ্যাল' },
                { id : 18, name : 'নেল্লোর' },
                { id : 19, name : 'পল্লানাডু' },
                { id : 20, name : 'প্রকাশম' },
                { id : 21, name : 'শ্রী বালাজী' },
                { id : 22, name : 'শ্রী সত্য সাই' },
                { id : 23, name : 'শ্রীকাকুলম' },
                { id : 24, name : 'ভিশাখাপত্তনম' },
                { id : 25, name : 'ভিজিয়ানাগরাম' },
                { id : 26, name : 'পশ্চিম গোদাবারী' }
            ]
        },
        {
            id        : 3,
            name      : 'অরুণাচল প্রদেশ',
            districts : [
                { id : 1, name : 'আঞ্জও' },
                { id : 2, name : 'চাঙ্গলাং' },
                { id : 3, name : 'ডিবাং ভ্যালি' },
                { id : 4, name : 'ইস্ট কামেঙ' },
                { id : 5, name : 'ইস্ট সিয়াং' },
                { id : 6, name : 'ক্যামলে' },
                { id : 7, name : 'ক্রা ডাড়ি' },
                { id : 8, name : 'কুরুং কুমে' },
                { id : 9, name : 'লেপা রাড়া' },
                { id : 10, name : 'লোহিত' },
                { id : 11, name : 'লংডিং' },
                { id : 12, name : 'লোয়ার ডিবাং ভ্যালি' },
                { id : 13, name : 'লোয়ার সিয়াং' },
                { id : 14, name : 'লোয়ার সুবানসিরি' },
                { id : 15, name : 'নামসাই' },
                { id : 16, name : 'পাক্কে কেসাং' },
                { id : 17, name : 'পাপুম পারে' },
                { id : 18, name : 'শী যোমি' },
                { id : 19, name : 'সিয়াং' },
                { id : 20, name : 'তাওয়াং' },
                { id : 21, name : 'তিরাপ' },
                { id : 22, name : 'আপার সিয়াং' },
                { id : 23, name : 'আপার সুবানসিরি' },
                { id : 24, name : 'ওয়েস্ট কামেঙ' },
                { id : 25, name : 'ওয়েস্ট সিয়াং' }
            ]
        },
        {
            id        : 4,
            name      : 'অসম',
            districts : [
                { id : 1, name : 'বক্সা' },
                { id : 2, name : 'বৰপেটা' },
                { id : 3, name : 'বিশ্বনাথ' },
                { id : 4, name : 'বঙাইগাঁও' },
                { id : 5, name : 'কাছাৰ' },
                { id : 6, name : 'চৰাইড়িও' },
                { id : 7, name : 'চিৰাং' },
                { id : 8, name : 'ডাৰং' },
                { id : 9, name : 'ধেমাজি' },
                { id : 10, name : 'ধুবুৰী' },
                { id : 11, name : 'ডিব্ৰুগড়' },
                { id : 12, name : 'ডিমা হাছাও' },
                { id : 13, name : 'গোলপাৰা' },
                { id : 14, name : 'গোলাঘাট' },
                { id : 15, name : 'হাইলাকণ্ডি' },
                { id : 16, name : 'হোজাই' },
                { id : 17, name : 'জৰহাট' },
                { id : 18, name : 'কামৰূপ' },
                { id : 19, name : 'কামৰূপ মেট্ৰ' },
                { id : 20, name : 'কাৰ্বি আংলং' },
                { id : 21, name : 'কাৰিমগঞ্জ' },
                { id : 22, name : 'কোকৰাঝাৰ' },
                { id : 23, name : 'লখিমপুৰ' },
                { id : 24, name : 'মাজুলি' },
                { id : 25, name : 'মৰীগাঁও' },
                { id : 26, name : 'নগাঁও' },
                { id : 27, name : 'নালবাৰী' },
                { id : 28, name : 'শিৱসাগৰ' },
                { id : 29, name : 'ছোনিতপুৰ' },
                { id : 30, name : 'দক্ষিণ সালমাৰা-মানকাচাৰ' },
                { id : 31, name : 'তিনিচুকিয়া' },
                { id : 32, name : 'উদালগুৰী' },
                { id : 33, name : 'পাশ্চিম কাৰ্বি আংলং' }
            ]
        },
        {
            id        : 5,
            name      : 'বিহার',
            districts : [
                { id : 1, name : 'আরারিয়া' },
                { id : 2, name : 'আরওয়াল' },
                { id : 3, name : 'ঔরঙ্গাবাদ' },
                { id : 4, name : 'বাঁকা' },
                { id : 5, name : 'বেগুসারায়' },
                { id : 6, name : 'ভাগালপুর' },
                { id : 7, name : 'ভোজপুর' },
                { id : 8, name : 'বুক্সর' },
                { id : 9, name : 'দারভাঙ্গা' },
                { id : 10, name : 'পূর্ব চম্পারণ' },
                { id : 11, name : 'গয়া' },
                { id : 12, name : 'গোপালগঞ্জ' },
                { id : 13, name : 'জামুই' },
                { id : 14, name : 'জেহানাবাদ' },
                { id : 15, name : 'কাইমুর' },
                { id : 16, name : 'কটিহার' },
                { id : 17, name : 'খাগড়িয়া' },
                { id : 18, name : 'কিশানগঞ্জ' },
                { id : 19, name : 'লাখীসারায়' },
                { id : 20, name : 'মাধেপুরা' },
                { id : 21, name : 'মধুবানী' },
                { id : 22, name : 'মুংগের' },
                { id : 23, name : 'মুজাফ্ফরপুর' },
                { id : 24, name : 'নালান্দা' },
                { id : 25, name : 'নবাদা' },
                { id : 26, name : 'পটনা' },
                { id : 27, name : 'পূর্ণিয়া' },
                { id : 28, name : 'রোহতাস' },
                { id : 29, name : 'সহরসা' },
                { id : 30, name : 'সমস্তিপুর' },
                { id : 31, name : 'সারান' },
                { id : 32, name : 'শেখপুরা' },
                { id : 33, name : 'শেওহার' },
                { id : 34, name : 'সিতামড়ি' },
                { id : 35, name : 'সিওয়ান' },
                { id : 36, name : 'সুপাল' },
                { id : 37, name : 'ভৈশালী' },
                { id : 38, name : 'পশ্চিম চম্পারণ' }
            ]
        },
        {
            id        : 6,
            name      : 'চণ্ডিগড়',
            districts : [
                { id : 1, name : 'চণ্ডিগড়' }
            ]
        },
        {
            id        : 7,
            name      : 'ছত্তিশগড়',
            districts : [
                { id : 1, name : 'বালোদ' },
                { id : 2, name : 'বালোদা বাজার' },
                { id : 3, name : 'বালরামপুর' },
                { id : 4, name : 'বাস্তার' },
                { id : 5, name : 'বেমেতারা' },
                { id : 6, name : 'বিজাপুর' },
                { id : 7, name : 'বিলাসপুর' },
                { id : 8, name : 'দান্তেওয়াড়া' },
                { id : 9, name : 'ধামতারি' },
                { id : 10, name : 'দুর্গ' },
                { id : 11, name : 'গারিয়াবান্দ' },
                { id : 12, name : 'গৌরেলা পেন্ড্রা মারওয়াহি' },
                { id : 13, name : 'জাঞ্জগির চাঁপা' },
                { id : 14, name : 'যশপুর' },
                { id : 15, name : 'কবিরধাম' },
                { id : 16, name : 'কানকের' },
                { id : 17, name : 'খাইরাগঢ়' },
                { id : 18, name : 'কন্ডাগাওন' },
                { id : 19, name : 'কোরবা' },
                { id : 20, name : 'কোরিয়া' },
                { id : 21, name : 'মহাসামুন্দ' },
                { id : 22, name : 'মানেন্দ্রগড়' },
                { id : 23, name : 'মোহলা মানপুর' },
                { id : 24, name : 'মুংগেলি' },
                { id : 25, name : 'নারায়ণপুর' },
                { id : 26, name : 'রায়গঢ়' },
                { id : 27, name : 'রায়পুর' },
                { id : 28, name : 'রাজনন্দগাঁও' },
                { id : 29, name : 'শক্তি' },
                { id : 30, name : 'সরঙ্গড় বিলাইগঢ়' },
                { id : 31, name : 'সুকমা' },
                { id : 32, name : 'সুরাজপুর' },
                { id : 33, name : 'সুরগুজা' }
            ]
        },
        {
            id        : 8,
            name      : 'ডাদরা এবং নাগর হাভেলি এবং দামান এবং দীউ',
            districts : [
                { id : 1, name : 'ডাদরা এবং নাগর হাভেলি' },
                { id : 2, name : 'দামান' },
                { id : 3, name : 'দীউ' }
            ]
        },
        {
            id        : 9,
            name      : 'দিল্লি',
            districts : [
                { id : 1, name : 'সেন্ট্রাল দিল্লি' },
                { id : 2, name : 'ইস্ট দিল্লি' },
                { id : 3, name : 'নিউ দিল্লি' },
                { id : 4, name : 'নর্থ দিল্লি' },
                { id : 5, name : 'নর্থ ইস্ট দিল্লি' },
                { id : 6, name : 'নর্থ ওয়েস্ট দিল্লি' },
                { id : 7, name : 'শাহদারা' },
                { id : 8, name : 'সাউথ দিল্লি' },
                { id : 9, name : 'সাউথ ইস্ট দিল্লি' },
                { id : 10, name : 'সাউথ ওয়েস্ট দিল্লি' },
                { id : 11, name : 'ওয়েস্ট দিল্লি' }
            ]
        },
        {
            id        : 10,
            name      : 'গোয়া',
            districts : [
                { id : 1, name : 'নর্থ গোয়া' },
                { id : 2, name : 'সাউথ গোয়া' }
            ]
        },
        {
            id        : 11,
            name      : 'গুজরাট',
            districts : [
                { id : 1, name : 'অহমেদাবাদ' },
                { id : 2, name : 'অমরেলি' },
                { id : 3, name : 'আনন্দ' },
                { id : 4, name : 'আরাবালি' },
                { id : 5, name : 'বনাসকান্থা' },
                { id : 6, name : 'ভারুচ' },
                { id : 7, name : 'ভাভনগর' },
                { id : 8, name : 'বোতাদ' },
                { id : 9, name : 'ছোট উদয়পুর' },
                { id : 10, name : 'দাহোদ' },
                { id : 11, name : 'ডাঙ্গ' },
                { id : 12, name : 'দেবভূমি দ্বারকা' },
                { id : 13, name : 'গান্ধীনগর' },
                { id : 14, name : 'গীর সোমনাথ' },
                { id : 15, name : 'জামনগর' },
                { id : 16, name : 'জুনাগঢ়' },
                { id : 17, name : 'খেড়া' },
                { id : 18, name : 'কাচ্ছ' },
                { id : 19, name : 'মহিসাগর' },
                { id : 20, name : 'মেহসানা' },
                { id : 21, name : 'মোরবি' },
                { id : 22, name : 'নর্মদা' },
                { id : 23, name : 'নবসারি' },
                { id : 24, name : 'পাঞ্চমহল' },
                { id : 25, name : 'পাটান' },
                { id : 26, name : 'পোরবন্দর' },
                { id : 27, name : 'রাজকোট' },
                { id : 28, name : 'সাবারকান্ঠা' },
                { id : 29, name : 'সুরাত' },
                { id : 30, name : 'সুরেন্দ্রনগর' },
                { id : 31, name : 'তাপি' },
                { id : 32, name : 'ভদোদরা' },
                { id : 33, name : 'ভালসাদ' }
            ]
        },
        {
            id        : 12,
            name      : 'হরিয়ানা',
            districts : [
                { id : 1, name : 'অম্বালা' },
                { id : 2, name : 'ভিবানি' },
                { id : 3, name : 'চরখি দাদরি' },
                { id : 4, name : 'ফরিদাবাদ' },
                { id : 5, name : 'ফতেহাবাদ' },
                { id : 6, name : 'গুরুগ্রাম' },
                { id : 7, name : 'হিসার' },
                { id : 8, name : 'ঝজ্জার' },
                { id : 9, name : 'জিন্দ' },
                { id : 10, name : 'কৈথাল' },
                { id : 11, name : 'কর্নাল' },
                { id : 12, name : 'কুরুক্ষেত্র' },
                { id : 13, name : 'মহেন্দ্রগড়' },
                { id : 14, name : 'নুহ' },
                { id : 15, name : 'পালওয়াল' },
                { id : 16, name : 'পাঞ্চকুলা' },
                { id : 17, name : 'পানিপাট' },
                { id : 18, name : 'রেওয়ারি' },
                { id : 19, name : 'রোহতক' },
                { id : 20, name : 'সিরসা' },
                { id : 21, name : 'সোনিপাট' },
                { id : 22, name : 'যমুনানগর' }
            ]
        },
        {
            id        : 13,
            name      : 'হিমাচল প্রদেশ',
            districts : [
                { id : 1, name : 'বিলাসপুর' },
                { id : 2, name : 'চম্বা' },
                { id : 3, name : 'হামিরপুর' },
                { id : 4, name : 'কাঙ্গরা' },
                { id : 5, name : 'কিন্নৌর' },
                { id : 6, name : 'কুল্লু' },
                { id : 7, name : 'লাহল স্পিটি' },
                { id : 8, name : 'মান্দি' },
                { id : 9, name : 'শিমলা' },
                { id : 10, name : 'সিরমৌর' },
                { id : 11, name : 'সোলান' },
                { id : 12, name : 'উনা' }
            ]
        },
        {
            id        : 14,
            name      : 'জম্মু ও কাশ্মীর',
            districts : [
                { id : 1, name : 'অনন্তনগ' },
                { id : 2, name : 'বান্দিপোরা' },
                { id : 3, name : 'বারামুল্লা' },
                { id : 4, name : 'বুদগাম' },
                { id : 5, name : 'দোদা' },
                { id : 6, name : 'গান্দেরবাল' },
                { id : 7, name : 'জাম্মু' },
                { id : 8, name : 'কাঠুয়া' },
                { id : 9, name : 'কিস্তুয়ার' },
                { id : 10, name : 'কুলগাম' },
                { id : 11, name : 'কুপড়া' },
                { id : 12, name : 'পুনছ' },
                { id : 13, name : 'পুলওয়ামা' },
                { id : 14, name : 'রাজড়ি' },
                { id : 15, name : 'রামবান' },
                { id : 16, name : 'রিয়াসি' },
                { id : 17, name : 'সাম্বা' },
                { id : 18, name : 'শপিয়ান' },
                { id : 19, name : 'শ্রীনগর' },
                { id : 20, name : 'উধামপুর' }
            ]
        },
        {
            id        : 15,
            name      : 'ঝারখণ্ড',
            districts : [
                { id : 1, name : 'বোকারো' },
                { id : 2, name : 'চাত্রা' },
                { id : 3, name : 'দেওঘর' },
                { id : 4, name : 'ধানবাদ' },
                { id : 5, name : 'ডুমকা' },
                { id : 6, name : 'ইস্ট সিংহভুম' },
                { id : 7, name : 'গড়ওয়া' },
                { id : 8, name : 'গিরিডিহ' },
                { id : 9, name : 'গোড়ড়া' },
                { id : 10, name : 'গুমলা' },
                { id : 11, name : 'হাজারিবাগ' },
                { id : 12, name : 'জামতারা' },
                { id : 13, name : 'খুন্টি' },
                { id : 14, name : 'কোডেরমা' },
                { id : 15, name : 'লতেহার' },
                { id : 16, name : 'লোহারডাগা' },
                { id : 17, name : 'পাকুড়' },
                { id : 18, name : 'পালামু' },
                { id : 19, name : 'রামগড়' },
                { id : 20, name : 'রাঞ্চি' },
                { id : 21, name : 'সাহেবগঞ্জ' },
                { id : 22, name : 'সেরাইকেলা খারসাওয়ান' },
                { id : 23, name : 'সিমডেগা' },
                { id : 24, name : 'ওয়েস্ট সিংহভুম' }
            ]
        },
        {
            id        : 16,
            name      : 'কর্ণাটক',
            districts : [
                { id : 1, name : 'বাগালকোট' },
                { id : 2, name : 'বাংগালোর গ্রামীণ' },
                { id : 3, name : 'বাংগালোর শহর' },
                { id : 4, name : 'বেলগাম' },
                { id : 5, name : 'বেলারি' },
                { id : 6, name : 'বিদার' },
                { id : 7, name : 'চামারাজানগর' },
                { id : 8, name : 'চিক্কাবল্লাপুর' },
                { id : 9, name : 'চিক্কামাগালুরু' },
                { id : 10, name : 'চিত্রদুর্গ' },
                { id : 11, name : 'দক্ষিণ কান্নাড়' },
                { id : 12, name : 'দাভানগেরে' },
                { id : 13, name : 'ধারবাড়ি' },
                { id : 14, name : 'গাদাগ' },
                { id : 15, name : 'কালাবুরগী' },
                { id : 16, name : 'হাসান' },
                { id : 17, name : 'হাভেরি' },
                { id : 18, name : 'কোডাগু' },
                { id : 19, name : 'কোলার' },
                { id : 20, name : 'কোপ্পাল' },
                { id : 21, name : 'মান্ড্যা' },
                { id : 22, name : 'মাইসুরু' },
                { id : 23, name : 'রাইচুর' },
                { id : 24, name : 'রামানাগর' },
                { id : 25, name : 'শিমোগা' },
                { id : 26, name : 'তুমকুর' },
                { id : 27, name : 'উডুপি' },
                { id : 28, name : 'উত্তর কান্নাড়' },
                { id : 29, name : 'ভিজায়ানাগর' },
                { id : 30, name : 'ভিজয়াপুরা' },
                { id : 31, name : 'যাদগির' }
            ]
        },
        {
            id        : 17,
            name      : 'কেরালা',
            districts : [
                { id : 1, name : 'আলাপ্পুজহ' },
                { id : 2, name : 'এরনাকুলাম' },
                { id : 3, name : 'ইডুক্কি' },
                { id : 4, name : 'কান্নুর' },
                { id : 5, name : 'কাসারাগোড' },
                { id : 6, name : 'কোলাম' },
                { id : 7, name : 'কোট্টায়ং' },
                { id : 8, name : 'কোজিকোড' },
                { id : 9, name : 'মালপ্পুরম' },
                { id : 10, name : 'পালক্কাড়' },
                { id : 11, name : 'পথানামথিত্ত' },
                { id : 12, name : 'তিরুভানন্তপুরম' },
                { id : 13, name : 'ত্রিশূর' },
                { id : 14, name : 'ওয়ায়ানাড' }
            ]
        },
        {
            id        : 18,
            name      : 'লাদাখ',
            districts : [
                { id : 1, name : 'কারগিল' },
                { id : 2, name : 'লে' }
            ]
        },
        {
            id        : 19,
            name      : 'লক্ষদ্বীপ',
            districts : [
                { id : 1, name : 'লক্ষদ্বীপ' }
            ]
        },
        {
            id        : 20,
            name      : 'মধ্যপ্রদেশ',
            districts : [
                { id : 1, name : 'আগর মালওয়া' },
                { id : 2, name : 'আলিরাজপুর' },
                { id : 3, name : 'অনুপ্র' },
                { id : 4, name : 'আশোকনগর' },
                { id : 5, name : 'বালাঘাট' },
                { id : 6, name : 'বারোয়ানি' },
                { id : 7, name : 'বেতুল' },
                { id : 8, name : 'ভিন্দ' },
                { id : 9, name : 'ভোপাল' },
                { id : 10, name : 'বুরহানপুর' },
                { id : 11, name : 'চাচাউরা' },
                { id : 12, name : 'ছত্তরপুর' },
                { id : 13, name : 'ছিন্দবাড়া' },
                { id : 14, name : 'দামোহ' },
                { id : 15, name : 'দাতিয়া' },
                { id : 16, name : 'দেওয়াস' },
                { id : 17, name : 'ধার' },
                { id : 18, name : 'দিনদোরি' },
                { id : 19, name : 'গুনা' },
                { id : 20, name : 'গ্বালিওর' },
                { id : 21, name : 'হারদা' },
                { id : 22, name : 'হোসাংবাদ' },
                { id : 23, name : 'ইন্দোর' },
                { id : 24, name : 'জাবলপুর' },
                { id : 25, name : 'ঝাবুয়া' },
                { id : 26, name : 'কাটনি' },
                { id : 27, name : 'খানদুয়া' },
                { id : 28, name : 'খারগোন' },
                { id : 29, name : 'মাইহার' },
                { id : 30, name : 'মান্ডলা' },
                { id : 31, name : 'মান্ডসাউর' },
                { id : 32, name : 'মোরেনা' },
                { id : 33, name : 'নাগড়া' },
                { id : 34, name : 'নারসিংপুর' },
                { id : 35, name : 'নিমুচ' },
                { id : 36, name : 'নিউয়ারি' },
                { id : 37, name : 'পান্না' },
                { id : 38, name : 'রাইসেন' },
                { id : 39, name : 'রাজগঢ়' },
                { id : 40, name : 'রাতলাম' },
                { id : 41, name : 'রিওয়া' },
                { id : 42, name : 'সাগর' },
                { id : 43, name : 'সাতনা' },
                { id : 44, name : 'সিহোর' },
                { id : 45, name : 'সেওনি' },
                { id : 46, name : 'শাহদোল' },
                { id : 47, name : 'শাজাপুর' },
                { id : 48, name : 'শেওপুর' },
                { id : 49, name : 'শিবপুরি' },
                { id : 50, name : 'সিধি' },
                { id : 51, name : 'সিঙ্গরাউলি' },
                { id : 52, name : 'টিকামগড়' },
                { id : 53, name : 'উজ্জায়িন' },
                { id : 54, name : 'উমারিয়া' },
                { id : 55, name : 'ভিদিশা' }
            ]
        },
        {
            id        : 21,
            name      : 'মহারাষ্ট্র',
            districts : [
                { id : 1, name : 'আহমেদনগর' },
                { id : 2, name : 'আকোলা' },
                { id : 3, name : 'অমরাভতি' },
                { id : 4, name : 'ঔরংগাবাদ' },
                { id : 5, name : 'বিড়' },
                { id : 6, name : 'ভাণ্ডারা' },
                { id : 7, name : 'বুলঢানা' },
                { id : 8, name : 'চন্দ্রপুর' },
                { id : 9, name : 'ধুলে' },
                { id : 10, name : 'গাডচিরোলি' },
                { id : 11, name : 'গোন্দিয়া' },
                { id : 12, name : 'হিঙ্গোলি' },
                { id : 13, name : 'জলগাঁও' },
                { id : 14, name : 'জলনা' },
                { id : 15, name : 'কলহাপুর' },
                { id : 16, name : 'লাতুর' },
                { id : 17, name : 'মুম্বই শহর' },
                { id : 18, name : 'মুম্বই উপনগর' },
                { id : 19, name : 'নাগপুর' },
                { id : 20, name : 'নান্দেদ' },
                { id : 21, name : 'নন্দুরবার' },
                { id : 22, name : 'নাসিক' },
                { id : 23, name : 'ওসমানাবাদ' },
                { id : 24, name : 'পালগড়' },
                { id : 25, name : 'পরবহানি' },
                { id : 26, name : 'পুনে' },
                { id : 27, name : 'রায়গাড়' },
                { id : 28, name : 'রাতনাগিরি' },
                { id : 29, name : 'সাংলি' },
                { id : 30, name : 'সাতারা' },
                { id : 31, name : 'সিন্ধুদুর্গ' },
                { id : 32, name : 'সোলাপুর' },
                { id : 33, name : 'থেন' },
                { id : 34, name : 'ওয়ার্ডহা' },
                { id : 35, name : 'ওয়াশিম' },
                { id : 36, name : 'যবতমাল' }
            ]
        },
        {
            id        : 22,
            name      : 'মণিপুর',
            districts : [
                { id : 1, name : 'বিষ্ণুপুর' },
                { id : 2, name : 'চান্দেল' },
                { id : 3, name : 'চুরাচান্দপুর' },
                { id : 4, name : 'ইম্ফাল পূর্ব' },
                { id : 5, name : 'ইম্ফাল পশ্চিম' },
                { id : 6, name : 'জিরিবাম' },
                { id : 7, name : 'কাকচিং' },
                { id : 8, name : 'কামজোং' },
                { id : 9, name : 'কাংপোকপি' },
                { id : 10, name : 'নোনে' },
                { id : 11, name : 'ফেরজোল' },
                { id : 12, name : 'সেনাপাটি' },
                { id : 13, name : 'তামেঙ্গলং' },
                { id : 14, name : 'টেংনৌপাল' },
                { id : 15, name : 'থৌবাল' },
                { id : 16, name : 'উকহ্রুল' }
            ]
        },
        {
            id        : 23,
            name      : 'মেঘালয়',
            districts : [
                { id : 1, name : 'পূর্ব গারো হিলস' },
                { id : 2, name : 'পূর্ব জাইন্টিয়া হিলস' },
                { id : 3, name : 'পূর্ব খাসি হিলস' },
                { id : 4, name : 'মাইরাং' },
                { id : 5, name : 'উত্তর গারো হিলস' },
                { id : 6, name : 'রি ভোই' },
                { id : 7, name : 'দক্ষিণ গারো হিলস' },
                { id : 8, name : 'দক্ষিণ পশ্চিম গারো হিলস' },
                { id : 9, name : 'দক্ষিণ পশ্চিম খাসি হিলস' },
                { id : 10, name : 'পশ্চিম গারো হিলস' },
                { id : 11, name : 'পশ্চিম জাইন্টিয়া হিলস' },
                { id : 12, name : 'পশ্চিম খাসি হিলস' }
            ]
        },
        {
            id        : 24,
            name      : 'মিজোরাম',
            districts : [
                { id : 1, name : 'আইজওয়্ল' },
                { id : 2, name : 'চামফাই' },
                { id : 3, name : 'হনাথিয়াল' },
                { id : 4, name : 'খাওজওয়্ল' },
                { id : 5, name : 'কোলাসিব' },
                { id : 6, name : 'লাউঙ্গত্লাই' },
                { id : 7, name : 'লুঙ্গলে' },
                { id : 8, name : 'মামিট' },
                { id : 9, name : 'সাইহা' },
                { id : 10, name : 'সাইতুয়াল' },
                { id : 11, name : 'সেরচিপ' }
            ]
        },
        {
            id        : 25,
            name      : 'নাগাল্যান্ড',
            districts : [
                { id : 1, name : 'চুমুকেদিমা' },
                { id : 2, name : 'ডিমাপুর' },
                { id : 3, name : 'কিফায়ার' },
                { id : 4, name : 'কোহিমা' },
                { id : 5, name : 'লংলেং' },
                { id : 6, name : 'মোকোকচুং' },
                { id : 7, name : 'মন' },
                { id : 8, name : 'নিউল্যান্ড' },
                { id : 9, name : 'নোকলাক' },
                { id : 10, name : 'পেরেন' },
                { id : 11, name : 'ফেক' },
                { id : 12, name : 'শামাতোর' },
                { id : 13, name : 'ত্সেমিনিয়ু' },
                { id : 14, name : 'তুয়েনসাঙ' },
                { id : 15, name : 'ওখা' },
                { id : 16, name : 'জুনহেবোটো' }
            ]
        },
        {
            id        : 26,
            name      : 'ওড়িশা',
            districts : [
                { id : 1, name : 'অঙ্গুল' },
                { id : 2, name : 'বালাঙ্গীর' },
                { id : 3, name : 'বালাসোর' },
                { id : 4, name : 'বারগড়' },
                { id : 5, name : 'ভাদ্রক' },
                { id : 6, name : 'বৌধ' },
                { id : 7, name : 'কাটাক' },
                { id : 8, name : 'ডেবাগড়' },
                { id : 9, name : 'ঢেঙ্কানাল' },
                { id : 10, name : 'গজাপতি' },
                { id : 11, name : 'গঞ্জম' },
                { id : 12, name : 'জগৎসিংহপুর' },
                { id : 13, name : 'জাজপুর' },
                { id : 14, name : 'ঝারসুগুড়া' },
                { id : 15, name : 'কালাহাণ্ডি' },
                { id : 16, name : 'কন্ধমাল' },
                { id : 17, name : 'কেন্দ্রপাড়া' },
                { id : 18, name : 'কেন্দুঝার' },
                { id : 19, name : 'খোরদ়া' },
                { id : 20, name : 'কোরাপুট' },
                { id : 21, name : 'মালকানগিরি' },
                { id : 22, name : 'ময়ুরভঞ্জ' },
                { id : 23, name : 'নবরংগপুর' },
                { id : 24, name : 'নয়াগড়' },
                { id : 25, name : 'নুয়াপাড়া' },
                { id : 26, name : 'পুরী' },
                { id : 27, name : 'রায়গড়' },
                { id : 28, name : 'সাম্বলপুর' },
                { id : 29, name : 'সুবর্ণপুর' },
                { id : 30, name : 'সুন্দরগড়' }
            ]
        },
        {
            id        : 27,
            name      : 'পুদুচেরি',
            districts : [
                { id : 1, name : 'কারায়িকাল' },
                { id : 2, name : 'মাহে' },
                { id : 3, name : 'পুদুচেরি' },
                { id : 4, name : 'যানাম' }
            ]
        },
        {
            id        : 28,
            name      : 'পাঞ্জাব',
            districts : [
                { id : 1, name : 'অমৃতসর' },
                { id : 2, name : 'বরনালা' },
                { id : 3, name : 'বাথিন্দা' },
                { id : 4, name : 'ফরিদকোট' },
                { id : 5, name : 'ফতেহগড়' },
                { id : 6, name : 'ফাজিলকা' },
                { id : 7, name : 'ফিরোজপুর' },
                { id : 8, name : 'গুরুদাসপুর' },
                { id : 9, name : 'হোসাইয়ারপুর' },
                { id : 10, name : 'জালান্দার' },
                { id : 11, name : 'কাপুরথলা' },
                { id : 12, name : 'লুধিয়ানা' },
                { id : 13, name : 'মালেরকোটলা' },
                { id : 14, name : 'মানসা' },
                { id : 15, name : 'মোগা' },
                { id : 16, name : 'মোহালি' },
                { id : 17, name : 'মুক্তসর' },
                { id : 18, name : 'পাথানকোট' },
                { id : 19, name : 'পটিয়ালা' },
                { id : 20, name : 'রূপনগর' },
                { id : 21, name : 'সাংগরুর' },
                { id : 22, name : 'শহীদ ভগত সিং নগর' },
                { id : 23, name : 'তার্ন তারান' }
            ]
        },
        {
            id        : 29,
            name      : 'রাজস্থান',
            districts : [
                { id : 1, name : 'আজমের' },
                { id : 2, name : 'আলওয়ার' },
                { id : 3, name : 'বানস্বড়া' },
                { id : 4, name : 'বারান' },
                { id : 5, name : 'বারমের' },
                { id : 6, name : 'ভারতপুর' },
                { id : 7, name : 'ভিলওয়ারা' },
                { id : 8, name : 'বিকানের' },
                { id : 9, name : 'বুন্ডি' },
                { id : 10, name : 'চিত্তোরগড়' },
                { id : 11, name : 'চুরু' },
                { id : 12, name : 'ডাউসা' },
                { id : 13, name : 'ধোলপুর' },
                { id : 14, name : 'ডাংগারপুর' },
                { id : 15, name : 'হানুমানগড়' },
                { id : 16, name : 'জয়পুর' },
                { id : 17, name : 'জৈসালমের' },
                { id : 18, name : 'জালোর' },
                { id : 19, name : 'ঝালাওয়ার' },
                { id : 20, name : 'ঝুনঝুনু' },
                { id : 21, name : 'জোধপুর' },
                { id : 22, name : 'করৌলি' },
                { id : 23, name : 'কোটা' },
                { id : 24, name : 'নাগৌর' },
                { id : 25, name : 'পালি' },
                { id : 26, name : 'প্রতাপগড়' },
                { id : 27, name : 'রাজসামন্দ' },
                { id : 28, name : 'সাওয়াই মাধবপুর' },
                { id : 29, name : 'শিকার' },
                { id : 30, name : 'সিরোহি' },
                { id : 31, name : 'শ্রী গঙ্গানগর' },
                { id : 32, name : 'টোঙ্ক' },
                { id : 33, name : 'উদয়পুর' }
            ]
        },
        {
            id        : 30,
            name      : 'সিকিম',
            districts : [
                { id : 1, name : 'ইস্ট সিকিম' },
                { id : 2, name : 'নর্থ সিকিম' },
                { id : 3, name : 'পাক্যং' },
                { id : 4, name : 'সোরেং' },
                { id : 5, name : 'সাউথ সিকিম' },
                { id : 6, name : 'ওয়েস্ট সিকিম' }
            ]
        },
        {
            id        : 31,
            name      : 'তামিলনাড়ু',
            districts : [
                { id : 1, name : 'আরিয়ালুর' },
                { id : 2, name : 'চেঙ্গালপট্টু' },
                { id : 3, name : 'চেন্নাই' },
                { id : 4, name : 'কোয়েমব্যাটুর' },
                { id : 5, name : 'কুড্ডালোর' },
                { id : 6, name : 'ধর্মাপুরি' },
                { id : 7, name : 'দিন্ডিগুল' },
                { id : 8, name : 'এরোড' },
                { id : 9, name : 'কল্লাকুরিচি' },
                { id : 10, name : 'কাঞ্চিপুরম' },
                { id : 11, name : 'কনিয়াকুমারী' },
                { id : 12, name : 'কারুর' },
                { id : 13, name : 'কৃষ্ণনগরী' },
                { id : 14, name : 'মাদুরাই' },
                { id : 15, name : 'ময়িলাদুথুরাই' },
                { id : 16, name : 'নাগাপত্তিনাম' },
                { id : 17, name : 'নামাক্কাল' },
                { id : 18, name : 'নিলগিরিস' },
                { id : 19, name : 'পেরাম্বালুর' },
                { id : 20, name : 'পুদুক্কোট্টাই' },
                { id : 21, name : 'রামানাথাপুরাম' },
                { id : 22, name : 'রানিপেট' },
                { id : 23, name : 'সালেম' },
                { id : 24, name : 'শিভগঙ্গা' },
                { id : 25, name : 'টেঙ্কাসি' },
                { id : 26, name : 'তঞ্জাভুর' },
                { id : 27, name : 'থেনি' },
                { id : 28, name : 'থুতকুড়ি' },
                { id : 29, name : 'তিরুচিরাপল্লি' },
                { id : 30, name : 'তিরুনেলভেলি' },
                { id : 31, name : 'তিরুপত্তুর' },
                { id : 32, name : 'তিরুপুর' },
                { id : 33, name : 'তিরুভাল্লুর' },
                { id : 34, name : 'তিরুভান্নামালাই' },
                { id : 35, name : 'তিরুভারুর' },
                { id : 36, name : 'ভেলোর' },
                { id : 37, name : 'ভিলুপ্পুরাম' },
                { id : 38, name : 'ভিরুধুনাগর' }
            ]
        },
        {
            id        : 32,
            name      : 'তেলঙ্গানা',
            districts : [
                { id : 1, name : 'আদিলাবাদ' },
                { id : 2, name : 'ভদ্রাদ্রি কোঠাগুড়েম' },
                { id : 3, name : 'হানামকোণ্ডা' },
                { id : 4, name : 'হাইদ্রাবাদ' },
                { id : 5, name : 'জগতিয়াল' },
                { id : 6, name : 'জাঙ্গাওঁ' },
                { id : 7, name : 'জয়শংকর' },
                { id : 8, name : 'যোগুলাম্বা' },
                { id : 9, name : 'কামারেড্ডি' },
                { id : 10, name : 'করীমনগর' },
                { id : 11, name : 'খাম্মাম' },
                { id : 12, name : 'কোমরাম ভীম' },
                { id : 13, name : 'মাহাবুবাবাদ' },
                { id : 14, name : 'মাহবুবনগর' },
                { id : 15, name : 'মাঞ্চেরিয়াল' },
                { id : 16, name : 'মেদাক' },
                { id : 17, name : 'মেদচাল মালকাজগিরি' },
                { id : 18, name : 'মুলুগু' },
                { id : 19, name : 'নাগরকুর্নুল' },
                { id : 20, name : 'নলগোন্ডা' },
                { id : 21, name : 'নারায়ণপেট' },
                { id : 22, name : 'নির্মল' },
                { id : 23, name : 'নিজামাবাদ' },
                { id : 24, name : 'পেদ্দাপল্লি' },
                { id : 25, name : 'রাজন্য সিরসিল্লা' },
                { id : 26, name : 'রঙ্গা রেড্ডি' },
                { id : 27, name : 'সাংগারেড্ডি' },
                { id : 28, name : 'সিদ্ধিপেট' },
                { id : 29, name : 'সুর্যাপেট' },
                { id : 30, name : 'ভিকারাবাদ' },
                { id : 31, name : 'ওয়ানাপার্থি' },
                { id : 32, name : 'ওয়ারঙ্গল' },
                { id : 33, name : 'ইয়াদাদ্রি ভুবনাগিরি' }
            ]
        },
        {
            id        : 33,
            name      : 'ত্রিপুরা',
            districts : [
                { id : 1, name : 'ঢালাই' },
                { id : 2, name : 'গোমতি' },
                { id : 3, name : 'খোয়াই' },
                { id : 4, name : 'উত্তর ত্রিপুরা' },
                { id : 5, name : 'সেপাহিজালা' },
                { id : 6, name : 'দক্ষিণ ত্রিপুরা' },
                { id : 7, name : 'উনাকোটি' },
                { id : 8, name : 'পশ্চিম ত্রিপুরা' }
            ]
        },
        {
            id        : 34,
            name      : 'উত্তর প্রদেশ',
            districts : [
                { id : 1, name : 'আগরা' },
                { id : 2, name : 'আলিগড়' },
                { id : 3, name : 'আম্বেডকর নগর' },
                { id : 4, name : 'আমেথি' },
                { id : 5, name : 'আমরোহা' },
                { id : 6, name : 'ঔরাইয়া' },
                { id : 7, name : 'অয়োধ্যা' },
                { id : 8, name : 'আজমগড়' },
                { id : 9, name : 'বাগপাত' },
                { id : 10, name : 'বাহরাইচ' },
                { id : 11, name : 'বলিয়া' },
                { id : 12, name : 'বালরামপুর' },
                { id : 13, name : 'বান্দা' },
                { id : 14, name : 'বারাবাংকি' },
                { id : 15, name : 'ব্যারেলি' },
                { id : 16, name : 'বাস্তি' },
                { id : 17, name : 'ভদহোয়ি' },
                { id : 18, name : 'বিজনর' },
                { id : 19, name : 'বুদাউন' },
                { id : 20, name : 'বুলন্দশহর' },
                { id : 21, name : 'চাঁদৌলি' },
                { id : 22, name : 'চিত্রকুট' },
                { id : 23, name : 'দেওরিয়া' },
                { id : 24, name : 'এতাহ' },
                { id : 25, name : 'এতাওয়া' },
                { id : 26, name : 'ফররুখাবাদ' },
                { id : 27, name : 'ফতেহপুর' },
                { id : 28, name : 'ফিরোজাবাদ' },
                { id : 29, name : 'গৌতম বুদ্ধ নগর' },
                { id : 30, name : 'গাজিয়াবাদ' },
                { id : 31, name : 'গাজীপুর' },
                { id : 32, name : 'গোন্ডা' },
                { id : 33, name : 'গোরখপুর' },
                { id : 34, name : 'হামিরপুর' },
                { id : 35, name : 'হাপুড়' },
                { id : 36, name : 'হারদোই' },
                { id : 37, name : 'হাথরস' },
                { id : 38, name : 'জালাউন' },
                { id : 39, name : 'জৌনপুর' },
                { id : 40, name : 'ঝাঁসি' },
                { id : 41, name : 'কান্নাজ' },
                { id : 42, name : 'কানপুর দেহাট' },
                { id : 43, name : 'কানপুর নগর' },
                { id : 44, name : 'কাসগঞ্জ' },
                { id : 45, name : 'কৌসাম্বি' },
                { id : 46, name : 'লাখিমপুর খেড়ি' },
                { id : 47, name : 'কুশিনগর' },
                { id : 48, name : 'লালিতপুর' },
                { id : 49, name : 'লাখনউ' },
                { id : 50, name : 'মহারাজগঞ্জ' },
                { id : 51, name : 'মাহোবা' },
                { id : 52, name : 'মইনপুরি' },
                { id : 53, name : 'মথুরা' },
                { id : 54, name : 'মাউ' },
                { id : 55, name : 'মিরজাপুর' },
                { id : 56, name : 'মুরাদাবাদ' },
                { id : 57, name : 'মুজাফফরনগর' },
                { id : 58, name : 'মুজাফফরনগর' },
                { id : 59, name : 'পিলিভিট' },
                { id : 60, name : 'প্রতাপগড়' },
                { id : 61, name : 'প্রায়াগ' },
                { id : 62, name : 'রায়বারেলি' },
                { id : 63, name : 'রামপুর' },
                { id : 64, name : 'সাহারানপুর' },
                { id : 65, name : 'সাম্ভল' },
                { id : 66, name : 'সান্ত কবির নগর' },
                { id : 67, name : 'শাহজাহানপুর' },
                { id : 68, name : 'শ্যামলী' },
                { id : 69, name : 'শ্রাবস্তি' },
                { id : 70, name : 'সিদ্ধার্থনগর' },
                { id : 71, name : 'সিতাপুর' },
                { id : 72, name : 'সোনভদ্র' },
                { id : 73, name : 'সুলতানপুর' },
                { id : 74, name : 'উন্নাও' },
                { id : 75, name : 'বারাণসি' }
            ]
        },
        {
            id        : 35,
            name      : 'উত্তরাখণ্ড',
            districts : [
                { id : 1, name : 'আলমোড়া' },
                { id : 2, name : 'বাগেশ্বর' },
                { id : 3, name : 'চামোলি' },
                { id : 4, name : 'চম্পাওয়াট' },
                { id : 5, name : 'দেহরাদুন' },
                { id : 6, name : 'হরিদ্বার' },
                { id : 7, name : 'নৈনিতাল' },
                { id : 8, name : 'পৌরী' },
                { id : 9, name : 'পিথোড়াগড়' },
                { id : 10, name : 'রুদ্রপ্রয়াগ' },
                { id : 11, name : 'তেহ্রি' },
                { id : 12, name : 'উধাম সিংহ নগর' },
                { id : 13, name : 'উত্তরকাশি' }
            ]
        },
        {
            id        : 36,
            name      : 'পশ্চিমবঙ্গ',
            districts : [
                { id : 1, name : 'আলিপুরদুয়ার' },
                { id : 2, name : 'বাঁকুড়া' },
                { id : 3, name : 'বীরভূম' },
                { id : 4, name : 'কোচবিহার' },
                { id : 5, name : 'দক্ষিণ দিনাজপুর' },
                { id : 6, name : 'দার্জিলিং' },
                { id : 7, name : 'হুগলী' },
                { id : 8, name : 'হাওড়া' },
                { id : 9, name : 'জলপাইগুড়ি' },
                { id : 10, name : 'ঝাড়গ্রাম' },
                { id : 11, name : 'কালিম্পং' },
                { id : 12, name : 'কলকাতা' },
                { id : 13, name : 'মালদা' },
                { id : 14, name : 'মুর্শিদাবাদ' },
                { id : 15, name : 'নদিয়া' },
                { id : 16, name : 'উত্তর ২৪ পরগনা' },
                { id : 17, name : 'পশ্চিম বর্ধমান' },
                { id : 18, name : 'পশ্চিম মেদিনীপুর' },
                { id : 19, name : 'পূর্ব বর্ধমান' },
                { id : 20, name : 'পূর্ব মেদিনীপুর' },
                { id : 21, name : 'পুরুলিয়া' },
                { id : 22, name : 'দক্ষিণ ২৪ পরগনা' },
                { id : 23, name : 'উত্তর দিনাজপুর' }
            ]
        }
    ],
    occupationCategoryOptions : [
        {
            id          : 1,
            category    : 'নির্মাণ',
            occupations : [
                { name : 'ঘর বাড়ি', id : 1 },
                { name : 'রাস্তা', id : 2 },
                { name : 'সেতু', id : 3 },
                { name : 'অনান্য পরিকাঠামো', id : 4 }
            ]
        },
        {
            id          : 2,
            category    : 'কৃষিকাজ',
            occupations : [
                { name : 'চাষবাস', id : 1 },
                { name : 'বৃক্ষরোপণ', id : 2 },
                { name : 'ফসল কাটা', id : 3 },
                { name : 'অনান্য পরিকাঠামো', id : 4 }
            ]
        },
        {
            id          : 3,
            category    : 'গৃহকর্মী',
            occupations : [
                { name : 'বাড়ি পরিস্কার ', id : 1 },
                { name : 'রান্না', id : 2 },
                { name : 'শিশুর যত্ন ', id : 3 },
                { name : 'বড়দের যত্ন', id : 4 }
            ]
        },
        {
            id          : 4,
            category    : 'উৎপাদন এবং শিল্পকর্মী',
            occupations : [
                { name : 'টেক্সটাইল ', id : 1 },
                { name : 'প্লাইউড', id : 2 },
                { name : 'খাদ্য প্রক্রিয়াকরণ', id : 3 },
                { name : 'বস্ত্র উৎপাদন', id : 4 }
            ]
        },
        {
            id          : 5,
            category    : 'আতিথেয়তা এবং পরিষেবা শিল্প',
            occupations : [
                { name : 'গৃহস্থালি', id : 1 },
                { name : 'পরিষেবা দেওয়া ', id : 2 },
                { name : 'ফ্রন্ট ডেস্ক', id : 3 },
                { name : 'রক্ষণাবেক্ষণ', id : 4 }
            ]
        },
        {
            id          : 6,
            category    : 'পরিবহন এবং লজিস্টিক',
            occupations : [
                { name : 'ড্রাইভার', id : 1 },
                { name : 'লোডার', id : 2 },
                { name : 'হেল্পার ', id : 3 },
                { name : 'গুদামজাতকরণ', id : 4 }
            ]
        },
        {
            id          : 7,
            category    : 'রাস্তার বিক্রেতা এবং ছোট ব্যবসা',
            occupations : [
                { name : 'পানের দোকান', id : 1 },
                { name : 'বুজির দোকান', id : 2 },
                { name : 'ফাস্ট ফুড', id : 3 },
                { name : 'অন্যান্য', id : 4 }
            ]
        },
        {
            id          : 8,
            category    : 'হেলথ কেয়ার সাপোর্ট স্টাফ',
            occupations : [
                { name : 'নার্স সহকারীরা', id : 1 },
                { name : 'দারোয়ান', id : 2 },
                { name : 'রোগীর পরিচর্যাকারী', id : 3 }
            ]
        },
        {
            id          : 9,
            category    : 'মৎস্য ও সামুদ্রিক শিল্প',
            occupations : [
                { name : 'মাছ ধরা', id : 1 },
                { name : 'মাছ প্রক্রিয়াকরণ', id : 2 },
                { name : 'নৌকা বিল্ডিং', id : 3 },
                { name : 'সামুদ্রিক শিল্প', id : 4 }
            ]
        },
        {
            id          : 10,
            category    : 'সামুদ্রিক শিল্প',
            occupations : [
                { name : 'স্যানিটেশন কর্মীরা', id : 1 },
                { name : 'নিরাপত্তারক্ষী', id : 2 },
                { name : 'কায়িক শ্রমিক', id : 3 }
            ]
        }
    ],
    questionnaire : [
        {
            id          : 1,
            category_id : 1,
            questions   : [
                'আপনি কি অতীতে কাজের সাথে সম্পর্কিত কোনো আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে আপনার কাজের সাথে সম্পর্কিত কোন শারীরিক অস্বস্তি বা ব্যথা অনুভব করছেন?',
                'আপনি কি নির্মাণ শিল্পের জন্য নির্দিষ্ট কোন পেশাগত স্বাস্থ্য এবং নিরাপত্তা প্রশিক্ষণ বা সচেতনতা প্রোগ্রাম পেয়েছেন?',
                'আপনি কি নিরাপত্তা প্রোটোকল এবং সাধারণ নির্মাণ-সম্পর্কিত স্বাস্থ্য ঝুঁকি, যেমন পতন, শব্দ এক্সপোজার, বা রাসায়নিক বিপদ প্রতিরোধের ব্যবস্থা সম্পর্কে সচেতন?',
                'নির্মাণ সাইটে কাজ করার সময় আপনার কি হেলমেট, গ্লাভস, নিরাপত্তা গগলস এবং জোতাগুলির মতো ব্যক্তিগত সুরক্ষামূলক সরঞ্জাম (PPE) অ্যাক্সেস আছে?',
                'আপনি কি সঠিক উত্তোলন কৌশল এবং এরগনোমিক্সের গুরুত্ব সম্পর্কে অবগত আছেন যাতে পেশীবহুল আঘাত রোধ করা যায়?',
                'টিটেনাস, হেপাটাইটিস বি বা অন্যান্য প্রাসঙ্গিক ভ্যাকসিনের মতো সাধারণ পেশাগত স্বাস্থ্য ঝুঁকির বিরুদ্ধে আপনি কি টিকা নিয়েছেন?',
                'আপনি কি নিয়মিত স্ক্রীনিং বা স্বাস্থ্যের অবস্থার জন্য পর্যবেক্ষণ করছেন যা নির্মাণ কাজের সাথে যুক্ত হতে পারে, যেমন শ্বাসযন্ত্রের সমস্যা, শ্রবণশক্তি হ্রাস বা ত্বকের ব্যাধি?',
                'ক্লান্তি রোধ করতে এবং সামগ্রিক সুস্থতা বজায় রাখতে আপনার কাজের শিফটের সময় কি আপনাকে নিয়মিত বিরতি এবং বিশ্রামের সময় দেওয়া হয়?',
                'আপনি কি নির্মাণ সাইটে থাকাকালীন কোনো স্বাস্থ্য জরুরী অবস্থা বা উদ্বেগের ক্ষেত্রে উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?'
            ]
        },
        {
            id          : 2,
            category_id : 2,
            questions   : [
                'কৃষিতে কাজ করার সময় আপনি কি অতীতে কাজের সাথে সম্পর্কিত কোনো আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে কৃষিতে আপনার কাজের সাথে সম্পর্কিত কোনো শারীরিক অস্বস্তি বা স্বাস্থ্য সমস্যার সম্মুখীন হচ্ছেন?',
                'আপনি কি কীটনাশক, সার, বা কৃষি পদ্ধতিতে অন্যান্য রাসায়নিক ব্যবহারের সাথে যুক্ত সম্ভাব্য স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'কৃষি রাসায়নিক দ্রব্য পরিচালনা করার সময় বা সম্ভাব্য বিপদের সাথে কাজ সম্পাদন করার সময় আপনার কি ব্যক্তিগত সুরক্ষামূলক সরঞ্জাম (PPE) যেমন গ্লাভস, মাস্ক বা গগলস ব্যবহার করার অ্যাক্সেস আছে এবং ধারাবাহিকভাবে ব্যবহার করেন?',
                'আপনি কি সঠিক উত্তোলন কৌশল এবং এরগনোমিক্সের গুরুত্ব সম্পর্কে অবগত আছেন যাতে কৃষি কাজগুলি সম্পাদন করার সময় পেশীর আঘাত রোধ করতে?',
                'আপনি কি সাধারণ কৃষি স্বাস্থ্য ঝুঁকি, যেমন তাপ-সম্পর্কিত অসুস্থতা, শ্বাসকষ্টের সমস্যা বা জুনোটিক রোগের সংস্পর্শে আসা প্রতিরোধ করার জন্য নিরাপত্তা নির্দেশিকা এবং অনুশীলন সম্পর্কে জানেন?',
                'আপনি কি কৃষি রাসায়নিক এবং সরঞ্জামের নিরাপদ হ্যান্ডলিং এবং স্টোরেজ সম্পর্কিত কোন প্রশিক্ষণ বা তথ্য পেয়েছেন?',
                'আপনি কি নিয়মিত স্ক্রীনিং বা পর্যবেক্ষণ করছেন স্বাস্থ্যের অবস্থার জন্য যা কৃষি কাজের সাথে যুক্ত হতে পারে, যেমন শ্বাসযন্ত্রের সমস্যা, ত্বকের ব্যাধি বা অ্যালার্জি?',
                'ক্ষেতে কাজ করার সময় তাপ-সম্পর্কিত অসুস্থতা এবং ডিহাইড্রেশন প্রতিরোধ করার জন্য আপনাকে কি নিয়মিত বিরতি, বিশুদ্ধ পানীয় জলের অ্যাক্সেস এবং ছায়াযুক্ত জায়গা দেওয়া হয়েছে?',
                'আপনি কি কোনো স্বাস্থ্য জরুরী অবস্থা বা কৃষিতে আপনার কাজের সাথে সম্পর্কিত উদ্বেগের ক্ষেত্রে উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?'
            ]
        },
        {
            id          : 3,
            category_id : 3,
            questions   : [
                'কৃষিতে কাজ করার সময় আপনি কি অতীতে কাজের সাথে সম্পর্কিত কোনো আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে কৃষিতে আপনার কাজের সাথে সম্পর্কিত কোনো শারীরিক অস্বস্তি বা স্বাস্থ্য সমস্যার সম্মুখীন হচ্ছেন?',
                'আপনি কি কীটনাশক, সার, বা কৃষি পদ্ধতিতে অন্যান্য রাসায়নিক ব্যবহারের সাথে যুক্ত সম্ভাব্য স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'কৃষি রাসায়নিক দ্রব্য পরিচালনা করার সময় বা সম্ভাব্য বিপদের সাথে কাজ সম্পাদন করার সময় আপনার কি ব্যক্তিগত সুরক্ষামূলক সরঞ্জাম (PPE) যেমন গ্লাভস, মাস্ক বা গগলস ব্যবহার করার অ্যাক্সেস আছে এবং ধারাবাহিকভাবে ব্যবহার করেন?',
                'আপনি কি সঠিক উত্তোলন কৌশল এবং এরগনোমিক্সের গুরুত্ব সম্পর্কে অবগত আছেন যাতে কৃষি কাজগুলি সম্পাদন করার সময় পেশীর আঘাত রোধ করতে?',
                'আপনি কি সাধারণ কৃষি স্বাস্থ্য ঝুঁকি, যেমন তাপ-সম্পর্কিত অসুস্থতা, শ্বাসকষ্টের সমস্যা বা জুনোটিক রোগের সংস্পর্শে আসা প্রতিরোধ করার জন্য নিরাপত্তা নির্দেশিকা এবং অনুশীলন সম্পর্কে জানেন?',
                'আপনি কি কৃষি রাসায়নিক এবং সরঞ্জামের নিরাপদ হ্যান্ডলিং এবং স্টোরেজ সম্পর্কিত কোন প্রশিক্ষণ বা তথ্য পেয়েছেন?',
                'আপনি কি নিয়মিত স্ক্রীনিং বা পর্যবেক্ষণ করছেন স্বাস্থ্যের অবস্থার জন্য যা কৃষি কাজের সাথে যুক্ত হতে পারে, যেমন শ্বাসযন্ত্রের সমস্যা, ত্বকের ব্যাধি বা অ্যালার্জি?',
                'ক্ষেতে কাজ করার সময় তাপ-সম্পর্কিত অসুস্থতা এবং ডিহাইড্রেশন প্রতিরোধ করার জন্য আপনাকে কি নিয়মিত বিরতি, বিশুদ্ধ পানীয় জলের অ্যাক্সেস এবং ছায়াযুক্ত জায়গা দেওয়া হয়েছে?',
                'আপনি কি কোনো স্বাস্থ্য জরুরী অবস্থা বা কৃষিতে আপনার কাজের সাথে সম্পর্কিত উদ্বেগের ক্ষেত্রে উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?'
            ]
        },
        {
            id          : 4,
            category_id : 4,
            questions   : [
                'উত্পাদন বা শিল্প সেটিংসে কাজ করার সময় আপনি কি অতীতে কোনও কাজের-সম্পর্কিত আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে উত্পাদন বা শিল্প সেটিংসে আপনার কাজের সাথে সম্পর্কিত কোনও শারীরিক অস্বস্তি বা স্বাস্থ্য সমস্যার সম্মুখীন হচ্ছেন?',
                'আপনি কি উত্পাদন বা শিল্প খাতে আপনার নির্দিষ্ট কাজের ভূমিকার সাথে যুক্ত সম্ভাব্য স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'সম্ভাব্য ঝুঁকিপূর্ণ এলাকায় কাজ করার সময় আপনার কি ব্যক্তিগত সুরক্ষামূলক সরঞ্জাম (PPE) যেমন নিরাপত্তা গগলস, গ্লাভস, কানের সুরক্ষা বা মুখোশের অ্যাক্সেস আছে এবং ধারাবাহিকভাবে ব্যবহার করেন?',
                'আপনি কি ক্ষতিকারক রাসায়নিকের সংস্পর্শে আসা, শব্দ-প্ররোচিত শ্রবণশক্তি হ্রাস বা অর্গোনমিক-সম্পর্কিত আঘাতের মতো সাধারণ শিল্প স্বাস্থ্য ঝুঁকি প্রতিরোধের জন্য নিরাপত্তা প্রোটোকল এবং ব্যবস্থা সম্পর্কে জানেন?',
                'ক্লান্তি রোধ করতে এবং সামগ্রিক সুস্থতা বজায় রাখতে আপনার কাজের শিফটের সময় কি আপনাকে নিয়মিত বিরতি এবং বিশ্রামের সময় দেওয়া হয়?',
                'আপনি কি সঠিক ভঙ্গি বজায় রাখার গুরুত্ব সম্পর্কে সচেতন এবং আপনার কাজের কাজগুলি সম্পাদন করার সময় পেশীবহুল আঘাতের ঝুঁকি কমাতে ergonomic সরঞ্জাম ব্যবহার করে?',
                'আপনি কি নিয়মিত স্বাস্থ্যের অবস্থার জন্য স্ক্রীনিং বা পর্যবেক্ষণ করছেন যা উত্পাদন বা শিল্প ক্ষেত্রে আপনার কাজের সাথে যুক্ত হতে পারে, যেমন শ্বাসযন্ত্রের সমস্যা, শ্রবণশক্তি হ্রাস বা পেশাগত সম্পর্কিত অসুস্থতা?',
                'কর্মক্ষেত্রে দুর্ঘটনা বা স্বাস্থ্য জরুরী অবস্থার ক্ষেত্রে জরুরী যোগাযোগের নম্বর এবং পদক্ষেপের জ্ঞান সহ জরুরী পদ্ধতির বিষয়ে আপনাকে কি প্রশিক্ষণ বা তথ্য দেওয়া হয়েছে?',
                'আপনি কি উৎপাদন বা শিল্প ক্ষেত্রে কাজ করার সময় কোন স্বাস্থ্যের জরুরী অবস্থার ক্ষেত্রে উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে অবগত আছেন ?'
            ]
        },
        {
            id          : 5,
            category_id : 5,
            questions   : [
                'হসপিটালিটি এবং সার্ভিস ইন্ডাস্ট্রিতে কাজ করার সময় আপনি কি অতীতে কাজের সাথে সম্পর্কিত কোন আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে আতিথেয়তা এবং পরিষেবা শিল্পে আপনার কাজের সাথে সম্পর্কিত কোনও শারীরিক অস্বস্তি বা স্বাস্থ্য সমস্যার সম্মুখীন হচ্ছেন?',
                'আপনি কি আতিথেয়তা এবং পরিষেবা শিল্পে আপনার নির্দিষ্ট কাজের ভূমিকার সাথে যুক্ত সম্ভাব্য স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'খাবার, রাসায়নিক দ্রব্য পরিচালনা করার সময় বা সম্ভাব্য বিপদের সাথে কাজ করার সময় আপনার কি ব্যক্তিগত সুরক্ষামূলক সরঞ্জাম (PPE) যেমন গ্লাভস, অ্যাপ্রন বা মুখোশের অ্যাক্সেস আছে এবং ধারাবাহিকভাবে ব্যবহার করেন?',
                'আপনি কি খাদ্যজনিত অসুস্থতা প্রতিরোধ এবং খাদ্য নিরাপত্তা নিশ্চিত করার জন্য সঠিক খাদ্য পরিচালনা এবং স্বাস্থ্যবিধি অনুশীলন সম্পর্কে জানেন?',
                'ক্লান্তি রোধ করতে এবং সামগ্রিক সুস্থতা বজায় রাখতে আপনার কাজের শিফটের সময় কি আপনাকে নিয়মিত বিরতি এবং বিশ্রামের সময় দেওয়া হয়?',
                'আপনি কি আপনার কাজের কাজগুলি সম্পাদন করার সময় পেশীবহুল আঘাতের ঝুঁকি কমাতে ভাল ভঙ্গি বজায় রাখার এবং এরগনোমিক সরঞ্জাম ব্যবহার করার গুরুত্ব সম্পর্কে সচেতন?',
                'আতিথেয়তা এবং পরিষেবা শিল্পে আপনার কাজের সাথে সম্পর্কিত হতে পারে এমন স্বাস্থ্যের অবস্থার জন্য আপনি কি নিয়মিত স্ক্রীনিং বা পর্যবেক্ষণ করছেন, যেমন পুনরাবৃত্তিমূলক স্ট্রেন ইনজুরি, শ্বাসকষ্টের সমস্যা বা স্ট্রেস-সম্পর্কিত অবস্থা?',
                'কর্মক্ষেত্রে দুর্ঘটনা বা স্বাস্থ্যের জরুরী অবস্থার ক্ষেত্রে জরুরী যোগাযোগের নম্বর এবং পদক্ষেপের জ্ঞান সহ জরুরী পদ্ধতির বিষয়ে আপনাকে কি প্রশিক্ষণ বা তথ্য দেওয়া হয়েছে?',
                'আতিথেয়তা এবং পরিষেবা শিল্পে কাজের সময় কোনো স্বাস্থ্যের জরুরী অবস্থা বা উদ্বেগের ক্ষেত্রে আপনি কি উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?'
            ]
        },
        {
            id          : 6,
            category_id : 6,
            questions   : [
                'পরিবহণ এবং লজিস্টিকসে কাজ করার সময় আপনি কি অতীতে কোন কাজের সাথে সম্পর্কিত আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে পরিবহন এবং লজিস্টিকসে আপনার কাজের সাথে সম্পর্কিত কোনও শারীরিক অস্বস্তি বা স্বাস্থ্য সমস্যা অনুভব করছেন?',
                'আপনি কি পরিবহন এবং লজিস্টিক সেক্টরে আপনার নির্দিষ্ট কাজের ভূমিকার সাথে যুক্ত সম্ভাব্য স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'সম্ভাব্য বিপদের সাথে কাজ করার সময় আপনার কি ব্যক্তিগত প্রতিরক্ষামূলক সরঞ্জাম (PPE) যেমন হেলমেট, সেফটি ভেস্ট, গ্লাভস বা মুখোশের অ্যাক্সেস আছে এবং ধারাবাহিকভাবে ব্যবহার করেন?',
                'সঠিক সিটবেল্ট ব্যবহার, ট্রাফিক নিয়ম মেনে চলা এবং রাস্তার নিরাপত্তা বজায় রাখা সহ নিরাপদ ড্রাইভিং অনুশীলন সম্পর্কে আপনি কি জানেন?',
                'ক্লান্তি রোধ করতে এবং সামগ্রিক সুস্থতা বজায় রাখতে আপনার কাজের শিফটের সময় কি আপনাকে নিয়মিত বিরতি এবং বিশ্রামের সময় দেওয়া হয়?',
                'আপনি কি ভাল ভঙ্গি বজায় রাখা এবং ড্রাইভিং বা পণ্য পরিচালনার সময় পেশীর আঘাতের ঝুঁকি কমাতে কটিদেশীয় সমর্থন বা সামঞ্জস্যযোগ্য আসনের মতো অর্গোনমিক সরঞ্জাম ব্যবহার করার গুরুত্ব সম্পর্কে সচেতন?',
                'আপনি কি নিয়মিতভাবে স্বাস্থ্যগত অবস্থার জন্য স্ক্রীনিং বা পর্যবেক্ষণ করছেন যা পরিবহন এবং লজিস্টিক সেক্টরে আপনার কাজের সাথে যুক্ত হতে পারে, যেমন পিঠে ব্যথা, দৃষ্টি সমস্যা বা ঘুমের ব্যাধি?',
                'রাস্তা বা কর্মস্থলে দুর্ঘটনা বা স্বাস্থ্যের জরুরী অবস্থার ক্ষেত্রে জরুরী যোগাযোগের নম্বর এবং পদক্ষেপের জ্ঞান সহ জরুরী পদ্ধতি সম্পর্কে আপনাকে কি প্রশিক্ষণ বা তথ্য প্রদান করা হয়েছে?',
                'আপনি কি পরিবহন ও লজিস্টিক সেক্টরে আপনার কাজের সাথে সম্পর্কিত কোনো স্বাস্থ্যের জরুরী অবস্থা বা উদ্বেগের ক্ষেত্রে উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?'
            ]
        },
        {
            id          : 7,
            category_id : 7,
            questions   : [
                'রাস্তার বিক্রেতা হিসাবে বা একটি ছোট ব্যবসায় কাজ করার সময় আপনি কি অতীতে কোনও কাজের-সম্পর্কিত আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে রাস্তার বিক্রেতা বা একটি ছোট ব্যবসায় আপনার কাজের সাথে সম্পর্কিত কোনো শারীরিক অস্বস্তি বা স্বাস্থ্য সমস্যার সম্মুখীন হচ্ছেন?',
                'আপনি কি রাস্তার বিক্রেতা বা ছোট ব্যবসায় আপনার নির্দিষ্ট কাজের ভূমিকার সাথে যুক্ত সম্ভাব্য স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'আপনার কি ব্যক্তিগত সুরক্ষামূলক সরঞ্জাম (PPE) যেমন গ্লাভস, এপ্রোন বা মুখোশের অ্যাক্সেস আছে এবং খাবার পরিচালনা করার সময় বা সম্ভাব্য বিপদের সাথে কাজগুলি সম্পাদন করার সময় কি নিয়মিত ব্যবহার করেন?',
                'আপনি কি খাদ্যজনিত অসুস্থতা প্রতিরোধ এবং খাদ্য নিরাপত্তা নিশ্চিত করার জন্য সঠিক খাদ্য পরিচালনা এবং স্বাস্থ্যবিধি অনুশীলন সম্পর্কে জানেন?',
                'ক্লান্তি রোধ করতে এবং সামগ্রিক সুস্থতা বজায় রাখতে আপনার কাজের শিফটের সময় কি আপনাকে নিয়মিত বিরতি এবং বিশ্রামের সময় দেওয়া হয়?',
                'আপনি কি রাস্তার বিক্রেতা হিসাবে বা একটি ছোট ব্যবসায় কাজ করার সময় পেশীর আঘাতের ঝুঁকি কমাতে ভাল ভঙ্গি বজায় রাখার এবং এরগনোমিক সরঞ্জাম ব্যবহার করার গুরুত্ব সম্পর্কে সচেতন?',
                'আপনার কাজের সাথে সম্পর্কিত হতে পারে এমন স্বাস্থ্যের অবস্থার জন্য আপনি কি নিয়মিত স্ক্রীন বা পর্যবেক্ষণ করছেন, যেমন শ্বাসযন্ত্রের সমস্যা, ত্বকের ব্যাধি বা স্ট্রেস-সম্পর্কিত অবস্থা?',
                'আপনার কর্মক্ষেত্রে দুর্ঘটনা বা স্বাস্থ্য জরুরী অবস্থার ক্ষেত্রে জরুরি যোগাযোগ নম্বর এবং পদক্ষেপের জ্ঞান সহ জরুরী পদ্ধতির প্রশিক্ষণ বা তথ্য প্রদান করা হয়েছে?',
                'আপনি কি রাস্তার বিক্রেতা হিসাবে বা একটি ছোট ব্যবসায় আপনার কাজের সাথে সম্পর্কিত কোনও স্বাস্থ্য জরুরী অবস্থা বা উদ্বেগের ক্ষেত্রে উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?'
            ]
        },
        {
            id          : 8,
            category_id : 8,
            questions   : [
                'রাস্তার বিক্রেতা হিসাবে বা একটি ছোট ব্যবসায় কাজ করার সময় আপনি কি অতীতে কোনও কাজের-সম্পর্কিত আঘাত বা দুর্ঘটনার সম্মুখীন হয়েছেন?',
                'আপনি কি বর্তমানে রাস্তার বিক্রেতা বা একটি ছোট ব্যবসায় আপনার কাজের সাথে সম্পর্কিত কোনো শারীরিক অস্বস্তি বা স্বাস্থ্য সমস্যার সম্মুখীন হচ্ছেন?',
                'আপনি কি রাস্তার বিক্রেতা বা ছোট ব্যবসায় আপনার নির্দিষ্ট কাজের ভূমিকার সাথে যুক্ত সম্ভাব্য স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'আপনার কি ব্যক্তিগত সুরক্ষামূলক সরঞ্জাম (PPE) যেমন গ্লাভস, এপ্রোন বা মুখোশের অ্যাক্সেস আছে এবং খাবার পরিচালনা করার সময় বা সম্ভাব্য বিপদের সাথে কাজগুলি সম্পাদন করার সময় কি নিয়মিত ব্যবহার করেন?',
                'আপনি কি খাদ্যজনিত অসুস্থতা প্রতিরোধ এবং খাদ্য নিরাপত্তা নিশ্চিত করার জন্য সঠিক খাদ্য পরিচালনা এবং স্বাস্থ্যবিধি অনুশীলন সম্পর্কে জানেন?',
                'ক্লান্তি রোধ করতে এবং সামগ্রিক সুস্থতা বজায় রাখতে আপনার কাজের শিফটের সময় কি আপনাকে নিয়মিত বিরতি এবং বিশ্রামের সময় দেওয়া হয়?',
                'আপনি কি রাস্তার বিক্রেতা হিসাবে বা একটি ছোট ব্যবসায় কাজ করার সময় পেশীর আঘাতের ঝুঁকি কমাতে ভাল ভঙ্গি বজায় রাখার এবং এরগনোমিক সরঞ্জাম ব্যবহার করার গুরুত্ব সম্পর্কে সচেতন?',
                'আপনার কাজের সাথে সম্পর্কিত হতে পারে এমন স্বাস্থ্যের অবস্থার জন্য আপনি কি নিয়মিত স্ক্রীন বা পর্যবেক্ষণ করছেন, যেমন শ্বাসযন্ত্রের সমস্যা, ত্বকের ব্যাধি বা স্ট্রেস-সম্পর্কিত অবস্থা?',
                'আপনার কর্মক্ষেত্রে দুর্ঘটনা বা স্বাস্থ্য জরুরী অবস্থার ক্ষেত্রে জরুরি যোগাযোগ নম্বর এবং পদক্ষেপের জ্ঞান সহ জরুরী পদ্ধতির প্রশিক্ষণ বা তথ্য প্রদান করা হয়েছে?',
                'আপনি কি রাস্তার বিক্রেতা হিসাবে বা একটি ছোট ব্যবসায় আপনার কাজের সাথে সম্পর্কিত কোনও স্বাস্থ্য জরুরী অবস্থা বা উদ্বেগের ক্ষেত্রে উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?'
            ]
        },
        {
            id          : 9,
            category_id : 9,
            questions   : [
                'মৎস্য ও সামুদ্রিক শিল্পে কাজ করার সময় আপনি কি কখনও নিজের বা আপনার নির্ভরশীলদের জন্য স্বাস্থ্যসেবা পরিষেবা অ্যাক্সেস করার ক্ষেত্রে চ্যালেঞ্জের সম্মুখীন হয়েছেন?',
                'আপনি কি একজন মৎস্য ও সামুদ্রিক শিল্পের শ্রমিক হিসাবে আপনার জন্য উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?',
                'প্রয়োজনে সময়মত চিকিৎসা বা পরামর্শ পাওয়ার ক্ষেত্রে আপনি কি কোন সমস্যার সম্মুখীন হয়েছেন? হ্যাঁ, বিবরণ প্রদান করুন।',
                'আপনি কি মৎস্য ও সামুদ্রিক শিল্পে আপনার কাজের সাথে আপনার নিয়োগকর্তা বা প্রতিষ্ঠান কর্তৃক প্রদত্ত স্বাস্থ্যসেবা সুবিধার সাথে সন্তুষ্ট? যদি না হয়, আপনি কি উন্নতির পরামর্শ দেবেন?',
                'আপনি কি মৎস্য ও সামুদ্রিক শিল্পের সাথে সম্পর্কিত পেশাগত বিপদ বা আঘাতের জন্য উপলব্ধ নির্দিষ্ট স্বাস্থ্যসেবা পরিষেবা বা সহায়তা সম্পর্কে সচেতন, যেমন জলবাহিত রোগ বা মাছ ধরার সরঞ্জাম পরিচালনার কারণে আঘাত?',
                'আপনি কি একজন মৎস্য ও সামুদ্রিক শিল্পের শ্রমিক হিসাবে স্বাস্থ্যসেবা পরিষেবার জন্য আপনার অধিকার এবং অধিকার সম্পর্কিত প্রশিক্ষণ বা তথ্য পেয়েছেন?',
                'আপনি কি জাহাজে বা সমুদ্রে জরুরী চিকিৎসা সহায়তা চাওয়ার জন্য উপলব্ধ পদ্ধতি এবং সংস্থানগুলির সাথে পরিচিত?',
                'মৎস্য ও সামুদ্রিক শিল্পে আপনার ভূমিকার জন্য প্রয়োজনীয় টিকা বা প্রতিরোধমূলক ব্যবস্থা গ্রহণে আপনি কি কোনো বাধার সম্মুখীন হয়েছেন?',
                'আপনার সুস্থতা নিরীক্ষণ করতে এবং কোনও সম্ভাব্য স্বাস্থ্য সমস্যা সনাক্ত করতে আপনাকে কি নিয়মিত স্বাস্থ্য পরীক্ষা বা স্ক্রিনিং দেওয়া হয়েছে?',
                'মৎস্য ও সামুদ্রিক শিল্পে কাজ করার সময় আপনার কি মানসিক স্বাস্থ্য সহায়তা বা কাউন্সেলিং পরিষেবাগুলিতে কাজ-সম্পর্কিত চাপ বা মানসিক সুস্থতা মোকাবেলার অ্যাক্সেস আছে?'
            ]
        },
        {
            id          : 10,
            category_id : 10,
            questions   : [
                'আপনি কি কখনও নিজের বা আপনার নির্ভরশীলদের জন্য স্বাস্থ্যসেবা পরিষেবা অ্যাক্সেস করার ক্ষেত্রে চ্যালেঞ্জের সম্মুখীন হয়েছেন?',
                'আপনি কি আপনার নির্দিষ্ট শিল্প বা সেক্টরে একজন শ্রমিক হিসাবে আপনার জন্য উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন?',
                'প্রয়োজনে সময়মত চিকিৎসা বা পরামর্শ পেতে আপনার কি কোন অসুবিধা আছে?',
                'আপনি কি স্বাস্থ্য প্রতিষ্ঠান কর্তৃক প্রদত্ত স্বাস্থ্যসেবা সুবিধা নিয়ে সন্তুষ্ট?',
                'আপনি কি আপনার কাজের লাইনের সাথে যুক্ত নির্দিষ্ট পেশাগত স্বাস্থ্য ঝুঁকি সম্পর্কে সচেতন?',
                'আপনি কি আপনার সেক্টরে একজন শ্রমিক হিসাবে স্বাস্থ্যসেবা পরিষেবার অধিকার এবং অধিকারের সাথে পরিচিত?',
                'আপনার ভূমিকার জন্য প্রয়োজনীয় টিকা বা প্রতিরোধমূলক ব্যবস্থা গ্রহণের ক্ষেত্রে আপনি কি কোনো বাধার সম্মুখীন হয়েছেন?',
                'আপনার সুস্থতা নিরীক্ষণ করতে এবং কোনও সম্ভাব্য স্বাস্থ্য সমস্যা সনাক্ত করতে আপনাকে কি নিয়মিত স্বাস্থ্য পরীক্ষা বা স্ক্রিনিং দেওয়া হয়েছে?',
                'আপনি কি আপনার এলাকায় উপলব্ধ স্বাস্থ্যসেবা সুবিধা এবং সংস্থান সম্পর্কে সচেতন? যদি না হয়, আপনি কি সেগুলি অ্যাক্সেস করতে আরও তথ্য চান?',
                'কাজের সাথে সম্পর্কিত চাপ বা মানসিক সুস্থতার জন্য আপনার কি মানসিক স্বাস্থ্য সহায়তা বা কাউন্সেলিং পরিষেবাগুলিতে অ্যাক্সেস আছে?'
            ]
        }
    ]
};

export default bengali;
