const assamese = {
    generalContents : {
        menuContents : {
            projectTitle : 'হেলথমিত্ৰ',
            home         : 'মূল পৃষ্ঠা',
            about        : 'আমাৰ বিষয়ে',
            contactUs    : 'আমাৰ সৈতে যোগাযোগ কৰক',
            language     : 'ভাষা',
            registerNow  : 'এতিয়াই পঞ্জীয়ন কৰক'
        },
        footerContents : {
            copyright : 'স্বত্বাধিকাৰ',
            callUs    : 'আমাক ফোন কৰক'
        }
    },
    // Page Contents
    homePageContents : {
        languageContent : {
            labelText          : 'অনুগ্ৰহ কৰি অব্যাহত ৰাখিবলৈ এটা ভাষা বাছনি কৰক',
            selectLanguageText : 'এটা ভাষা নিৰ্বাচন কৰক',
            submitButton       : 'অব্যাহত ৰাখক'
        },
        bannerContent : {
            welcomeText : 'হেলথমিত্ৰলৈ স্বাগতম',
            text1       : 'বিজ্ঞান আৰু প্ৰযুক্তি বিভাগ, ভাৰত চৰকাৰ আৰু মহাত্মা গান্ধী বিশ্ববিদ্যালয় প্ৰকল্প',
            text2       : '"কেৰালাত আন্তঃৰাজ্যিক প্ৰব্ৰজনকাৰী শ্ৰমিকসকলৰ মাজত স্বাস্থ্যসেৱা প্ৰাপ্তিৰ ওপৰত সামাজিক প্ৰতিষ্ঠানগত আৰু \n' +
                'প্ৰযুক্তিগত হস্তক্ষেপৰ প্ৰভাৱ" সন্দৰ্ভত বিজ্ঞান আৰু অভিযান্ত্ৰিক গৱেষণা পৰিষদপ্ৰকল্পৰ এক প্ৰচেষ্টা (CRG/2021/004314)।',
            registerNow : 'এতিয়াই পঞ্জীয়ন কৰক',
            callUsText1 : 'আমাক কল কৰক',
            callUsText2 : 'যিকোনো অনুসন্ধানৰ বাবে যোগাযোগ কৰক'
        },
        aboutContent : {
            heading : 'আহক আমি পৰিচয় দিওঁ',
            text1   : 'আমি আন্তঃৰাজ্যিক প্ৰব্ৰজনকাৰী শ্ৰমিকসকলৰ জীৱন উন্নত কৰাৰ বাবে স্বাস্থ্যসেৱা প্ৰাপ্ত কৰাৰ বাবে \n' +
                'অত্যাৱশ্যকীয় বাৰ্তালাপ প্ৰদান কৰি আৰু তেওঁলোকৰ স্বাস্থ্যৰ অধিকাৰৰ বাবে ওকালতি কৰিবলৈ নিজকে সমৰ্পিত কৰিছোঁ।',
            text2 : 'আমাৰ লক্ষ্য হৈছে সমাজ আৰু তেওঁলোকৰ পৰিয়ালৰ বাবে এক উন্নত ভৱিষ্যত গঢ়ি তুলিবলৈ চেষ্টা \n' +
                'কৰোঁতে কোনো শ্ৰমিকে যাতে কষ্টৰ সন্মুখীন নহয় সেয়া নিশ্চিত কৰা।'
        },
        contactUs : {
            title        : 'আমাৰ সৈতে যোগাযোগ কৰক',
            formContents : {
                name         : 'সম্পূৰ্ণ নাম',
                email        : 'আপোনাৰ ইমেইল',
                phone        : 'আপোনাৰ ফোন',
                message      : 'আপোনাৰ বাৰ্তা',
                submitButton : 'বাৰ্তা প্ৰেৰণ কৰক'
            }
        }
    },
    registrationContents : {
        title        : 'পঞ্জীয়ন',
        formContents : {
            basicDetailsTitle            : 'মৌলিক বিৱৰণ',
            nativeAddressTitleText       : 'স্থানীয় ঠিকনা',
            keralaAddressTitleText       : 'কেৰালাৰ ঠিকনা',
            phoneValidation              : 'ফোন নম্বৰৰ সৈতে একাউণ্ট ইতিমধ্যে বিদ্যমান।',
            name                         : 'সম্পূৰ্ণ নাম',
            phone                        : 'ফোন',
            age                          : 'বয়স',
            gender                       : 'লিঙ্গ',
            maritalStatus                : 'বৈবাহিক স্থিতি',
            nativeLocality               : 'স্থায়ী চহৰ/স্থান',
            nativeState                  : 'স্থায়ী ৰাজ্য',
            nativeDistrict               : 'স্থায়ী জিলা',
            keralaLocality               : 'কেৰালাৰ চহৰ/অঞ্চল',
            keralaDistrict               : 'কেৰালাৰ জিলা',
            durationInKerala             : 'কেৰালাত থকা বছৰৰ সময়কাল',
            submitButton1                : 'অব্যাহত ৰাখক',
            occupationTitleText          : 'জীৱিকা',
            occupationCategory           : 'জীৱিকাৰ শ্ৰেণী',
            occupation                   : 'জীৱিকা',
            occupationSelectMessage      : 'অনুগ্ৰহ কৰি এটা শ্ৰেণী বাছনি কৰক',
            medicalHistoryTitleText      : 'চিকিৎসা ইতিহাস',
            medicalHistoryWarningMessage : 'অনুগ্ৰহ কৰি এটা জীৱিকা শ্ৰেণী আৰু জীৱিকা বাছনি কৰক।',
            submitButton2                : 'দাখিল কৰক',
            backButton                   : 'পিছলৈ যাওক',
            okayButton                   : 'ঠিক আছে'

        },
        successPageContents : {
            successMessage : 'সফল',
            thanks         : 'পঞ্জীয়ন কৰাৰ বাবে আপোনাক ধন্যবাদ!',
            noteTitle      : 'টোকা',
            noteContent    : 'অনুগ্ৰহ কৰি তলত পঞ্জীভুক্ত বিৱৰণত যিকোনো উন্নীতিকৰণৰ বাবে প্ৰশাসকৰ ওচৰলৈ যাবলৈ দ্বিধা নকৰিব।'
        }
    },
    // Options and Questionnaire
    genderOptions : [
        { name : 'পুৰুষ', id : 1 },
        { name : 'মহিলা', id : 2 },
        { name : 'কিন্বৰ', id : 3 },
        { name : 'ব্যক্ত কৰিব নিবিচাৰে', id : 4 }
    ],
    maritalStatusOptions : [
        { name : 'অবিবাহিত', id : 1 },
        { name : 'বিবাহিত', id : 2 },
        { name : 'বিবাহ বিচ্ছেদিত', id : 3 },
        { name : 'জীৱনসংগীৰ মৃত্যু হৈছে', id : 4 }
    ],
    durationOptions : [
        { name : '1 বছৰতকৈ কম', id : 1 },
        { name : '1-3 বছৰ', id : 2 },
        { name : '3-5 বছৰ', id : 3 },
        { name : '5-10 বছৰ', id : 4 }
    ],
    stateDistrictOptions : [
        {
            id        : 1,
            name      : 'আন্দামান নিকোবৰ',
            districts : [
                { id : 1, name : 'নিকোবৰ' },
                { id : 2, name : 'উত্তৰ মধ্য আন্দামান' },
                { id : 3, name : 'দক্ষিণ আন্দামান' }
            ]
        },
        {
            id        : 2,
            name      : 'অন্ধ্ৰ প্ৰদেশ',
            districts : [
                { id : 1, name : 'অনন্তপুৰ' },
                { id : 2, name : 'চিত্তূৰ' },
                { id : 3, name : 'ইষ্ট গোদাবাৰী' },
                { id : 4, name : 'অল্লুৰি সীতাৰাম রাজু' },
                { id : 5, name : 'আনাকাপল্লি' },
                { id : 6, name : 'আন্নামায়া' },
                { id : 7, name : 'বাপাটলা' },
                { id : 8, name : 'এলুৰু' },
                { id : 9, name : 'গুণ্টুৰ' },
                { id : 10, name : 'কাডাপা' },
                { id : 11, name : 'কাকিনাডা' },
                { id : 12, name : 'কোনাছিমা' },
                { id : 13, name : 'কৃষ্ণা' },
                { id : 14, name : 'কুৰ্ণুল' },
                { id : 15, name : 'মান্যম' },
                { id : 16, name : 'এন টি রামা ৰাও' },
                { id : 17, name : 'নান্দ্যাল' },
                { id : 18, name : 'নেল্লোৰ' },
                { id : 19, name : 'পালনাদু' },
                { id : 20, name : 'প্ৰকাশম' },
                { id : 21, name : 'শ্ৰী বালাজী' },
                { id : 22, name : 'শ্ৰী সত্য সাই' },
                { id : 23, name : 'শ্ৰীকাকুলম' },
                { id : 24, name : 'বিশাখাপত্তনম' },
                { id : 25, name : 'ভিজিয়ানাগৰাম' },
                { id : 26, name : 'পূৰ্ব গোদাবাৰী' }
            ]
        },
        {
            id        : 3,
            name      : 'অৰুণাচল প্ৰদেশ',
            districts : [
                { id : 1, name : 'আঞ্জাও' },
                { id : 2, name : 'চাংলাং' },
                { id : 3, name : 'দিবাং ভেলে' },
                { id : 4, name : 'পূৰ্ব কামেং' },
                { id : 5, name : 'পূৰ্ব সীং' },
                { id : 6, name : 'কাম্লে' },
                { id : 7, name : 'ক্ৰা দাদী' },
                { id : 8, name : 'কুৰুং কুমে' },
                { id : 9, name : 'লেপা ৰাদা' },
                { id : 10, name : 'লহিত' },
                { id : 11, name : 'লংডিং' },
                { id : 12, name : 'ল’ৱা দিবাং ভেলে' },
                { id : 13, name : 'ল’ৱা সীং' },
                { id : 14, name : 'ল’ৱা ছুবানচিৰি' },
                { id : 15, name : 'নামচাই' },
                { id : 16, name : 'পাকে কেছাং' },
                { id : 17, name : 'পাপুম পাৰে' },
                { id : 18, name : 'শি ইয়োমি' },
                { id : 19, name : 'সীং' },
                { id : 20, name : 'তাওৱাং' },
                { id : 21, name : 'টিৰাপ' },
                { id : 22, name : 'অপাৰ সীং' },
                { id : 23, name : 'অপাৰ ছুবানচিৰি' },
                { id : 24, name : 'পশ্চিম কামেং' },
                { id : 25, name : 'পশ্চিম সীং' }
            ]
        },
        {
            id        : 4,
            name      : 'অসম',
            districts : [
                { id : 1, name : 'বাকচা' },
                { id : 2, name : 'বাৰপেটা' },
                { id : 3, name : 'বিশ্বনাথ' },
                { id : 4, name : 'বঙাইগাওঁ' },
                { id : 5, name : 'কাছাৰ' },
                { id : 6, name : 'চাৰাইদেও' },
                { id : 7, name : 'চিৰাং' },
                { id : 8, name : 'দাৰাং' },
                { id : 9, name : 'ধেমাজি' },
                { id : 10, name : 'ধুবুৰি' },
                { id : 11, name : 'ডিব্ৰুগড়' },
                { id : 12, name : 'ডিমা হাছাও' },
                { id : 13, name : 'গোলপাৰা' },
                { id : 14, name : 'গোলাঘাট' },
                { id : 15, name : 'হাইলাকণ্ডি' },
                { id : 16, name : 'হাজাই' },
                { id : 17, name : 'যোৰহাট' },
                { id : 18, name : 'কামৰূপ' },
                { id : 19, name : 'কামৰূপ মেট্ৰপলিটন' },
                { id : 20, name : 'কাৰ্বি আংলং' },
                { id : 21, name : 'কাৰিমগঞ্জ' },
                { id : 22, name : 'কোকৰাঝাৰ' },
                { id : 23, name : 'লখিমপুৰ' },
                { id : 24, name : 'মাজুলি' },
                { id : 25, name : 'মৰিগাঁও' },
                { id : 26, name : 'নগাঁও' },
                { id : 27, name : 'নালবাৰি' },
                { id : 28, name : 'শিৱসাগৰ' },
                { id : 29, name : 'ছোনিতপুৰ' },
                { id : 30, name : 'দক্ষিণ সালমাৰা-মানকাচাৰ' },
                { id : 31, name : 'তিনিচুকিয়া' },
                { id : 32, name : 'উদালগুৰি' },
                { id : 33, name : 'পশ্চিম কাৰ্বি আংলং' }
            ]
        },
        {
            id        : 5,
            name      : 'বিহাৰ',
            districts : [
                { id : 1, name : 'অৰারিয়া' },
                { id : 2, name : 'আৰৱাল' },
                { id : 3, name : 'ঔৰাংগাবাদ' },
                { id : 4, name : 'বাঁকা' },
                { id : 5, name : 'বেগুসৰায়' },
                { id : 6, name : 'ভাগলপুৰ' },
                { id : 7, name : 'ভোজপুৰ' },
                { id : 8, name : 'বুকসৰ' },
                { id : 9, name : 'দাৰভঙ্গা' },
                { id : 10, name : 'পূৰ্ব চম্পাৰণ' },
                { id : 11, name : 'গয়া' },
                { id : 12, name : 'গোপালগঞ্জ' },
                { id : 13, name : 'জামুই' },
                { id : 14, name : 'জেহানাবাদ' },
                { id : 15, name : 'কৈমুৰ' },
                { id : 16, name : 'কাটিহাৰ' },
                { id : 17, name : 'খাগৰিয়া' },
                { id : 18, name : 'কিশাঙ্গঞ্জ' },
                { id : 19, name : 'লাখিছৰায়' },
                { id : 20, name : 'মাধেপুৰা' },
                { id : 21, name : 'মাধুবানী' },
                { id : 22, name : 'মুঙ্গেৰ' },
                { id : 23, name : 'মুজাফ্ফৰপুৰ' },
                { id : 24, name : 'নালান্দা' },
                { id : 25, name : 'নবাদা' },
                { id : 26, name : 'পটনা' },
                { id : 27, name : 'পূৰ্ণিয়া' },
                { id : 28, name : 'ৰোহতাস' },
                { id : 29, name : 'সহৰসা' },
                { id : 30, name : 'সমস্তিপুৰ' },
                { id : 31, name : 'সাৰণ' },
                { id : 32, name : 'শেখপুৰা' },
                { id : 33, name : 'শিওহাৰ' },
                { id : 34, name : 'সীতামড়ি' },
                { id : 35, name : 'সিওয়ান' },
                { id : 36, name : 'সুপৌল' },
                { id : 37, name : 'ভৈশালী' },
                { id : 38, name : 'পাশ্চিম চম্পাৰণ' }
            ]
        },
        {
            id        : 6,
            name      : 'চণ্ডিগড়',
            districts : [
                { id : 1, name : 'চণ্ডিগড়' }
            ]
        },
        {
            id        : 7,
            name      : 'ছত্তিসগড়',
            districts : [
                { id : 1, name : 'বালোদ' },
                { id : 2, name : 'বালোদা বাজাৰ' },
                { id : 3, name : 'বালরামপুৰ' },
                { id : 4, name : 'বাস্তৰ' },
                { id : 5, name : 'বেমেতাৰা' },
                { id : 6, name : 'বিজাপুৰ' },
                { id : 7, name : 'বিলাসপুৰ' },
                { id : 8, name : 'দন্তেৱাড়া' },
                { id : 9, name : 'ধামতাৰি' },
                { id : 10, name : 'দুৰ্গ' },
                { id : 11, name : 'গৰিয়াবন্দ' },
                { id : 12, name : 'গৌৰেলা পেন্ড্ৰা মাৰৱাহি' },
                { id : 13, name : 'জাঞ্জগিৰ চাম্পা' },
                { id : 14, name : 'জাসপুৰ' },
                { id : 15, name : 'কবিৰধাম' },
                { id : 16, name : 'কাংকেৰ' },
                { id : 17, name : 'খৈৰাগড়' },
                { id : 18, name : 'কন্দাগাঁও' },
                { id : 19, name : 'কোৰ্বা' },
                { id : 20, name : 'কৰিয়া' },
                { id : 21, name : 'মহাসমুণ্ড' },
                { id : 22, name : 'মানেন্দ্ৰগড়' },
                { id : 23, name : 'মহলা মানপুৰ' },
                { id : 24, name : 'মুঙ্গেলি' },
                { id : 25, name : 'নাৰায়ণপুৰ' },
                { id : 26, name : 'ৰায়গড়' },
                { id : 27, name : 'ৰাইপুৰ' },
                { id : 28, name : 'ৰাজনন্দগাঁও' },
                { id : 29, name : 'শক্তি' },
                { id : 30, name : 'সৰঙ্গৰ বিলাইগড়' },
                { id : 31, name : 'সুকমা' },
                { id : 32, name : 'সুৰাজপুৰ' },
                { id : 33, name : 'সুৰ্গুজা' }
            ]
        },
        {
            id        : 8,
            name      : 'ডাদৰা আৰু নগাৰ হাভেলি আৰু ডামান আৰু দিউ',
            districts : [
                { id : 1, name : 'ডাদৰা আৰু নগাৰ হাভেলি' },
                { id : 2, name : 'ডামান' },
                { id : 3, name : 'দিউ' }
            ]
        },
        {
            id        : 9,
            name      : 'দিল্লী',
            districts : [
                { id : 1, name : 'মধ্য দিল্লী' },
                { id : 2, name : 'পূৱ দিল্লী' },
                { id : 3, name : 'নতুন দিল্লী' },
                { id : 4, name : 'উত্তৰ দিল্লী' },
                { id : 5, name : 'উত্তৰ পূৱ দিল্লী' },
                { id : 6, name : 'উত্তৰ পশ্চিম দিল্লী' },
                { id : 7, name : 'শাহদাৰা' },
                { id : 8, name : 'দক্ষিণ দিল্লী' },
                { id : 9, name : 'দক্ষিণ পূৱ দিল্লী' },
                { id : 10, name : 'দক্ষিণ পশ্চিম দিল্লী' },
                { id : 11, name : 'পশ্চিম দিল্লী' }
            ]
        },
        {
            id        : 10,
            name      : 'গোৱা',
            districts : [
                { id : 1, name : 'উত্তৰ গোৱা' },
                { id : 2, name : 'দক্ষিণ গোৱা' }
            ]
        },
        {
            id        : 11,
            name      : 'গুজৰাট',
            districts : [
                { id : 1, name : 'অহমেদাবাদ' },
                { id : 2, name : 'আম্ৰেলি' },
                { id : 3, name : 'আনন্দ' },
                { id : 4, name : 'আৰাবলি' },
                { id : 5, name : 'বনাসকান্ঠা' },
                { id : 6, name : 'ভারুচ' },
                { id : 7, name : 'ভাভনগৰ' },
                { id : 8, name : 'বোতাদ' },
                { id : 9, name : 'ছোটা উদাইপুৰ' },
                { id : 10, name : 'দাহ' },
                { id : 11, name : 'দাং' },
                { id : 12, name : 'দেবভুমি দ্বাৰকা' },
                { id : 13, name : 'গান্ধীনগৰ' },
                { id : 14, name : 'গিৰ ছমনাথ' },
                { id : 15, name : 'জামনগৰ' },
                { id : 16, name : 'জুনাগঢ' },
                { id : 17, name : 'খেদা' },
                { id : 18, name : 'কচ্ছ' },
                { id : 19, name : 'মাহিসাগৰ' },
                { id : 20, name : 'মেহছানা' },
                { id : 21, name : 'মোৰবি' },
                { id : 22, name : 'নাৰমদা' },
                { id : 23, name : 'নবসাৰি' },
                { id : 24, name : 'পাংচমাহল' },
                { id : 25, name : 'পাটন' },
                { id : 26, name : 'পৰবন্দৰ' },
                { id : 27, name : 'ৰাজকোট' },
                { id : 28, name : 'ছবাৰকান্ঠা' },
                { id : 29, name : 'সুৰত' },
                { id : 30, name : 'সুৰেন্দ্ৰনগৰ' },
                { id : 31, name : 'তাপি' },
                { id : 32, name : 'ভাদোদাৰা' },
                { id : 33, name : 'ভালসাদ' }
            ]
        },
        {
            id        : 12,
            name      : 'হাৰিয়ানা',
            districts : [
                { id : 1, name : 'অম্বালা' },
                { id : 2, name : 'ভিবানি' },
                { id : 3, name : 'চাৰখি দাদ্ৰি' },
                { id : 4, name : 'ফৰিদাবাদ' },
                { id : 5, name : 'ফতেহাবাদ' },
                { id : 6, name : 'গুৰুগ্ৰাম' },
                { id : 7, name : 'হিসাৰ' },
                { id : 8, name : 'ঝাজ্জাৰ' },
                { id : 9, name : 'জিন্দ' },
                { id : 10, name : 'কৈথল' },
                { id : 11, name : 'কৰ্নাল' },
                { id : 12, name : 'কুৰুক্ষেত্ৰ' },
                { id : 13, name : 'মহেন্দ্ৰাগঢ়' },
                { id : 14, name : 'নুহ' },
                { id : 15, name : 'পালৱাল' },
                { id : 16, name : 'পাঞ্চকুলা' },
                { id : 17, name : 'পানিপাট' },
                { id : 18, name : 'ৰিওয়াৰি' },
                { id : 19, name : 'ৰোহতাক' },
                { id : 20, name : 'সিৰসা' },
                { id : 21, name : 'ছোনীপত' },
                { id : 22, name : 'যমুনানগৰ' }
            ]
        },
        {
            id        : 13,
            name      : 'হিমাচল প্ৰদেশ',
            districts : [
                { id : 1, name : 'বিলাসপুৰ' },
                { id : 2, name : 'চাম্বা' },
                { id : 3, name : 'হামিৰপুৰ' },
                { id : 4, name : 'কাঙ্গৰা' },
                { id : 5, name : 'কিন্নৌৰ' },
                { id : 6, name : 'কুল্লু' },
                { id : 7, name : 'লাহৌল স্পিতি' },
                { id : 8, name : 'মাণ্ডি' },
                { id : 9, name : 'শিমলা' },
                { id : 10, name : 'শিৰমাউৰ' },
                { id : 11, name : 'সোলান' },
                { id : 12, name : 'উনা' }
            ]
        },
        {
            id        : 14,
            name      : 'জম্মু আৰু কাশ্মীৰ',
            districts : [
                { id : 1, name : 'অনন্তনাগ' },
                { id : 2, name : 'বাণ্ডিপোৰা' },
                { id : 3, name : 'বাৰামুল্লা' },
                { id : 4, name : 'বুদ্ধগম' },
                { id : 5, name : 'দোদা' },
                { id : 6, name : 'গান্দেৰবাল' },
                { id : 7, name : 'জম্মু' },
                { id : 8, name : 'কাঠুৱা' },
                { id : 9, name : 'কিছতৱাৰ' },
                { id : 10, name : 'কুলগাম' },
                { id : 11, name : 'কুপোৱাৰা' },
                { id : 12, name : 'পুন্ছ' },
                { id : 13, name : 'পুলোৱামা' },
                { id : 14, name : 'ৰাজৌৰি' },
                { id : 15, name : 'ৰামবাণ' },
                { id : 16, name : 'ৰিয়াছি' },
                { id : 17, name : 'ছাম্বা' },
                { id : 18, name : 'শপিয়ান' },
                { id : 19, name : 'শ্ৰীনগৰ' },
                { id : 20, name : 'উধমপুৰ' }
            ]
        },
        {
            id        : 15,
            name      : 'ঝাৰখণ্ড',
            districts : [
                { id : 1, name : 'বোকাৰো' },
                { id : 2, name : 'চাট্ৰা' },
                { id : 3, name : 'দেওঘৰ' },
                { id : 4, name : 'ধনবাদ' },
                { id : 5, name : 'ডুমকা' },
                { id : 6, name : 'পূৰ্ব সিংভুম' },
                { id : 7, name : 'গঢ়ৱা' },
                { id : 8, name : 'গিৰিডিহ' },
                { id : 9, name : 'গোড়া' },
                { id : 10, name : 'গুমলা' },
                { id : 11, name : 'হাজাৰিবাগ' },
                { id : 12, name : 'জামতাৰা' },
                { id : 13, name : 'খুন্টি' },
                { id : 14, name : 'কোডেৰমা' },
                { id : 15, name : 'লেটেহাৰ' },
                { id : 16, name : 'লোহাৰডাগা' },
                { id : 17, name : 'পাকুৰ' },
                { id : 18, name : 'পালামু' },
                { id : 19, name : 'ৰামগড়' },
                { id : 20, name : 'ৰাঞ্চি' },
                { id : 21, name : 'ছাহেবগঞ্জ' },
                { id : 22, name : 'সেৰাইকেলা খৰসাওৱান' },
                { id : 23, name : 'ছিমদেগা' },
                { id : 24, name : 'পাশ্চিম সিংভুম' }
            ]
        },
        {
            id        : 16,
            name      : 'কাৰ্নাটক',
            districts : [
                { id : 1, name : 'বাগালকোট' },
                { id : 2, name : 'বাংগালোৰ পশ্চিম' },
                { id : 3, name : 'বাংগালোৰ পূৰ্ব' },
                { id : 4, name : 'বেলগাঁব' },
                { id : 5, name : 'বেল্লাৰী' },
                { id : 6, name : 'বিদাৰ' },
                { id : 7, name : 'চামাৰাজনগৰ' },
                { id : 8, name : 'চিক্কাবল্লাপুৰ' },
                { id : 9, name : 'চিক্কামাগলুৰু' },
                { id : 10, name : 'চিত্ৰদুৰ্গা' },
                { id : 11, name : 'দক্ষিণ কান্নাড়' },
                { id : 12, name : 'দাৱনগৰ' },
                { id : 13, name : 'ধৰৱাড' },
                { id : 14, name : 'গাদাগ' },
                { id : 15, name : 'কালাবুৰগি' },
                { id : 16, name : 'হাসান' },
                { id : 17, name : 'হাৱেৰি' },
                { id : 18, name : 'কোডাগু' },
                { id : 19, name : 'কোলাৰ' },
                { id : 20, name : 'কোপ্পল' },
                { id : 21, name : 'মাণ্ড্য়' },
                { id : 22, name : 'মাইচোৰ' },
                { id : 23, name : 'ৰায়চুৰ' },
                { id : 24, name : 'ৰামনগৰ' },
                { id : 25, name : 'শিমোগা' },
                { id : 26, name : 'টুমকুৰ' },
                { id : 27, name : 'উদুপি' },
                { id : 28, name : 'উত্তৰ কান্নাড়' },
                { id : 29, name : 'বিজয়ানগৰ' },
                { id : 30, name : 'বিজয়াপুৰ' },
                { id : 31, name : 'যাদগীৰ' }
            ]
        },
        {
            id        : 17,
            name      : 'কেৰলা',
            districts : [
                { id : 1, name : 'আলাপ্পুজা' },
                { id : 2, name : 'এৰণাকুলম' },
                { id : 3, name : 'ইডুক্কি' },
                { id : 4, name : 'কান্নুৰ' },
                { id : 5, name : 'কাছাৰগোদ' },
                { id : 6, name : 'ক’লম' },
                { id : 7, name : 'কটাইয়াম' },
                { id : 8, name : 'কোজিকোড' },
                { id : 9, name : 'মালাপ্পুরম' },
                { id : 10, name : 'পালক্কাট' },
                { id : 11, name : 'পাথানামথিত্ত' },
                { id : 12, name : 'তিৰুবানান্দপুৰাম' },
                { id : 13, name : 'থ্ৰিচুৰ' },
                { id : 14, name : 'ৱায়ানাড' }
            ]
        },
        {
            id        : 18,
            name      : 'লাদাখ',
            districts : [
                { id : 1, name : 'কাৰগিল' },
                { id : 2, name : 'লে' }
            ]
        },
        {
            id        : 19,
            name      : 'লক্ষদ্বীপ',
            districts : [
                { id : 1, name : 'লক্ষদ্বীপ' }
            ]
        },
        {
            id        : 20,
            name      : 'মধ্যপ্ৰদেশ',
            districts : [
                { id : 1, name : 'আগাৰ মালৱা' },
                { id : 2, name : 'আলিৰাজপুৰ' },
                { id : 3, name : 'অনুপপুৰ' },
                { id : 4, name : 'অশোকনগৰ' },
                { id : 5, name : 'বালাঘাট' },
                { id : 6, name : 'বৰৱাণি' },
                { id : 7, name : 'বেতুল' },
                { id : 8, name : 'ভিন্দ' },
                { id : 9, name : 'ভোপাল' },
                { id : 10, name : 'বুৰহানপুৰ' },
                { id : 11, name : 'চাচুৰা' },
                { id : 12, name : 'ছত্তৰপুৰ' },
                { id : 13, name : 'ছিন্দৱাৰা' },
                { id : 14, name : 'দামহ' },
                { id : 15, name : 'দাতিয়া' },
                { id : 16, name : 'দেৱাছ' },
                { id : 17, name : 'ধাৰ' },
                { id : 18, name : 'দিনদৰি' },
                { id : 19, name : 'গুনা' },
                { id : 20, name : 'গোৱালিয়ৰ' },
                { id : 21, name : 'হাৰদা' },
                { id : 22, name : 'হোছংগাবাদ' },
                { id : 23, name : 'ইন্দোৰ' },
                { id : 24, name : 'জবলপুৰ' },
                { id : 25, name : 'ঝাবুৱা' },
                { id : 26, name : 'কাটনি' },
                { id : 27, name : 'খান্দৱা' },
                { id : 28, name : 'খাৰগোণ' },
                { id : 29, name : 'মাইহাৰ' },
                { id : 30, name : 'মাণ্ডলা' },
                { id : 31, name : 'মাণ্ডসুৰ' },
                { id : 32, name : 'মোৰেনা' },
                { id : 33, name : 'নাগদা' },
                { id : 34, name : 'নাৰসিংপুৰ' },
                { id : 35, name : 'নিমুচ' },
                { id : 36, name : 'নিউৱাৰী' },
                { id : 37, name : 'পান্না' },
                { id : 38, name : 'ৰাইছেন' },
                { id : 39, name : 'ৰাজগড়' },
                { id : 40, name : 'ৰাতলাম' },
                { id : 41, name : 'ৰিবা' },
                { id : 42, name : 'সাগৰ' },
                { id : 43, name : 'সাতনা' },
                { id : 44, name : 'সিহোৰ' },
                { id : 45, name : 'ছেওনি' },
                { id : 46, name : 'শাহদোল' },
                { id : 47, name : 'শাজাপুৰ' },
                { id : 48, name : 'শিওপুৰ' },
                { id : 49, name : 'শিবপুৰি' },
                { id : 50, name : 'সিধি' },
                { id : 51, name : 'সিংৰৌলি' },
                { id : 52, name : 'টিকামগড়' },
                { id : 53, name : 'উজ্জৈন' },
                { id : 54, name : 'উমাৰিয়া' },
                { id : 55, name : 'ভিদিশা' }
            ]
        },
        {
            id        : 21,
            name      : 'Maharashtra',
            districts : [
                { id : 1, name : 'Ahmednagar' },
                { id : 2, name : 'Akola' },
                { id : 3, name : 'Amravati' },
                { id : 4, name : 'Aurangabad' },
                { id : 5, name : 'Beed' },
                { id : 6, name : 'Bhandara' },
                { id : 7, name : 'Buldhana' },
                { id : 8, name : 'Chandrapur' },
                { id : 9, name : 'Dhule' },
                { id : 10, name : 'Gadchiroli' },
                { id : 11, name : 'Gondia' },
                { id : 12, name : 'Hingoli' },
                { id : 13, name : 'Jalgaon' },
                { id : 14, name : 'Jalna' },
                { id : 15, name : 'Kolhapur' },
                { id : 16, name : 'Latur' },
                { id : 17, name : 'Mumbai City' },
                { id : 18, name : 'Mumbai Suburban' },
                { id : 19, name : 'Nagpur' },
                { id : 20, name : 'Nanded' },
                { id : 21, name : 'Nandurbar' },
                { id : 22, name : 'Nashik' },
                { id : 23, name : 'Osmanabad' },
                { id : 24, name : 'Palghar' },
                { id : 25, name : 'Parbhani' },
                { id : 26, name : 'Pune' },
                { id : 27, name : 'Raigad' },
                { id : 28, name : 'Ratnagiri' },
                { id : 29, name : 'Sangli' },
                { id : 30, name : 'Satara' },
                { id : 31, name : 'Sindhudurg' },
                { id : 32, name : 'Solapur' },
                { id : 33, name : 'Thane' },
                { id : 34, name : 'Wardha' },
                { id : 35, name : 'Washim' },
                { id : 36, name : 'Yavatmal' }
            ]
        },
        {
            id        : 21,
            name      : 'মহারাষ্ট্ৰ',
            districts : [
                { id : 1, name : 'আহমেদনগৰ' },
                { id : 2, name : 'আকোলা' },
                { id : 3, name : 'অমৰাবাটি' },
                { id : 4, name : 'অৰঙ্গাবাদ' },
                { id : 5, name : 'বীড' },
                { id : 6, name : 'ভান্দাৰা' },
                { id : 7, name : 'বুলধানা' },
                { id : 8, name : 'চান্দ্ৰাপুৰ' },
                { id : 9, name : 'ধুলে' },
                { id : 10, name : 'গাড়চিৰোলি' },
                { id : 11, name : 'গণ্ডিয়া' },
                { id : 12, name : 'হিংগলি' },
                { id : 13, name : 'জলগাঁও' },
                { id : 14, name : 'জলনা' },
                { id : 15, name : 'কলহাপুৰ' },
                { id : 16, name : 'লাটুৰ' },
                { id : 17, name : 'মুম্বাই চহৰ' },
                { id : 18, name : 'মুম্বাই উপনগৰ' },
                { id : 19, name : 'নাগপুৰ' },
                { id : 20, name : 'নান্দেড়' },
                { id : 21, name : 'নান্দুৰবাৰ' },
                { id : 22, name : 'নাশিক' },
                { id : 23, name : 'অসমানাবাদ' },
                { id : 24, name : 'পালঘৰ' },
                { id : 25, name : 'পৰভাণি' },
                { id : 26, name : 'পুণে' },
                { id : 27, name : 'ৰায়গাড়' },
                { id : 28, name : 'ৰত্নাগিৰি' },
                { id : 29, name : 'সাংগলি' },
                { id : 30, name : 'সাতাৰা' },
                { id : 31, name : 'সিন্ধুদুৰ্গ' },
                { id : 32, name : 'সোলাপুৰ' },
                { id : 33, name : 'থেন' },
                { id : 34, name : 'ৱাৰ্ডহা' },
                { id : 35, name : 'ৱাছিম' },
                { id : 36, name : 'যৱাতমাল' }
            ]
        },
        {
            id        : 22,
            name      : 'মণিপুৰ',
            districts : [
                { id : 1, name : 'বিষ্ণুপুৰ' },
                { id : 2, name : 'চাণ্ডেল' },
                { id : 3, name : 'চুৰাচাণ্ডপুৰ' },
                { id : 4, name : 'ইম্ফাল পূৰ্ব' },
                { id : 5, name : 'ইম্ফাল পশ্চিম' },
                { id : 6, name : 'জিৰিবাম' },
                { id : 7, name : 'কাকচিং' },
                { id : 8, name : 'কামজোং' },
                { id : 9, name : 'কাংপোকপী' },
                { id : 10, name : 'নোনে' },
                { id : 11, name : 'ফেৰজাওল' },
                { id : 12, name : 'সেনাপতি' },
                { id : 13, name : 'তামেংলং' },
                { id : 14, name : 'তেংনৌপাল' },
                { id : 15, name : 'থৌবাল' },
                { id : 16, name : 'উখ্ৰুল' }
            ]
        },
        {
            id        : 23,
            name      : 'মেঘালয়',
            districts : [
                { id : 1, name : 'পূৰ্ব গাৰো পাহাৰ' },
                { id : 2, name : 'পূৰ্ব জায়ন্তিয়া পাহাৰ' },
                { id : 3, name : 'পূৰ্ব খাসি পাহাৰ' },
                { id : 4, name : 'মাইৰাং' },
                { id : 5, name : 'উত্তৰ গাৰো পাহাৰ' },
                { id : 6, name : 'ৰি ভোই' },
                { id : 7, name : 'দক্ষিণ গাৰো পাহাৰ' },
                { id : 8, name : 'দক্ষিণ পশ্চিম গাৰো পাহাৰ' },
                { id : 9, name : 'দক্ষিণ পশ্চিম খাসি পাহাৰ' },
                { id : 10, name : 'পশ্চিম গাৰো পাহাৰ' },
                { id : 11, name : 'পশ্চিম জায়ন্তিয়া পাহাৰ' },
                { id : 12, name : 'পশ্চিম খাসি পাহাৰ' }
            ]
        },
        {
            id        : 24,
            name      : 'মিজোৰাম',
            districts : [
                { id : 1, name : 'আইজওল' },
                { id : 2, name : 'চাম্ফাই' },
                { id : 3, name : 'হ্নাথিয়াল' },
                { id : 4, name : 'খাউজল' },
                { id : 5, name : 'কোলাসিব' },
                { id : 6, name : 'লংগতলাই' },
                { id : 7, name : 'লাঙ্গলে' },
                { id : 8, name : 'মামিট' },
                { id : 9, name : 'সাইহা' },
                { id : 10, name : 'সাইতুয়াল' },
                { id : 11, name : 'সাৰ্চিহিপ' }
            ]
        },
        {
            id        : 25,
            name      : 'নাগাল্যাণ্ড',
            districts : [
                { id : 1, name : 'চুমুকেদিমা' },
                { id : 2, name : 'ডিমাপুৰ' },
                { id : 3, name : 'কিফিৰে' },
                { id : 4, name : 'কোহিমা' },
                { id : 5, name : 'লংলেং' },
                { id : 6, name : 'মোকোকচুং' },
                { id : 7, name : 'মোন' },
                { id : 8, name : 'নিউলান্দ' },
                { id : 9, name : 'নোকলাক' },
                { id : 10, name : 'পেৰেন' },
                { id : 11, name : 'ফেক' },
                { id : 12, name : 'শামাটৰ' },
                { id : 13, name : 'তসেমিন্যু' },
                { id : 14, name : 'টুইঞ্ছং' },
                { id : 15, name : 'ৱোখা' },
                { id : 16, name : 'জুনহেবট' }
            ]
        },
        {
            id        : 26,
            name      : 'ওড়িশা',
            districts : [
                { id : 1, name : 'অংগুল' },
                { id : 2, name : 'বলাংগীৰ' },
                { id : 3, name : 'বালাসৰ' },
                { id : 4, name : 'বাৰগৰ' },
                { id : 5, name : 'ভদ্ৰক' },
                { id : 6, name : 'বৌধ' },
                { id : 7, name : 'কাটক' },
                { id : 8, name : 'দেবগড' },
                { id : 9, name : 'ঢেঙ্কানাল' },
                { id : 10, name : 'গজাপতি' },
                { id : 11, name : 'গঞ্জম' },
                { id : 12, name : 'জগৎসিংহপুৰ' },
                { id : 13, name : 'জাজপুৰ' },
                { id : 14, name : 'ঝাৰসুগুডা' },
                { id : 15, name : 'কলাহাণ্ডি' },
                { id : 16, name : 'কন্ধমাল' },
                { id : 17, name : 'কেন্দ্ৰপাৰা' },
                { id : 18, name : 'কেন্দুঝাৰ' },
                { id : 19, name : 'খোৰধা' },
                { id : 20, name : 'কোৰাপুট' },
                { id : 21, name : 'মালকানগিৰি' },
                { id : 22, name : 'ময়ুৰভঞ্জ' },
                { id : 23, name : 'নবৰঙ্গপুৰ' },
                { id : 24, name : 'নয়াগড়' },
                { id : 25, name : 'নুয়াপাড়া' },
                { id : 26, name : 'পুৰী' },
                { id : 27, name : 'ৰায়াগড়া' },
                { id : 28, name : 'সম্বলপুৰ' },
                { id : 29, name : 'সুবৰ্ণপুৰ' },
                { id : 30, name : 'সুন্দৰগড়' }
            ]
        },
        {
            id        : 27,
            name      : 'পুদুচ্চেরি',
            districts : [
                { id : 1, name : 'কারাইকাল' },
                { id : 2, name : 'মাহে' },
                { id : 3, name : 'পুদুচ্চেরি' },
                { id : 4, name : 'য়ানাম' }
            ]
        },
        {
            id        : 28,
            name      : 'পাঞ্জাব',
            districts : [
                { id : 1, name : 'অমৃতসৰ' },
                { id : 2, name : 'বৰনালা' },
                { id : 3, name : 'বাঠিন্ডা' },
                { id : 4, name : 'ফৰিদকোট' },
                { id : 5, name : 'ফাতেহগড় সাহিব' },
                { id : 6, name : 'ফাজিলকা' },
                { id : 7, name : 'ফিৰোজপুৰ' },
                { id : 8, name : 'গুৰদাসপুৰ' },
                { id : 9, name : 'হশিয়াৰপুৰ' },
                { id : 10, name : 'জালন্ধৰ' },
                { id : 11, name : 'কাপুৰথলা' },
                { id : 12, name : 'লুধিয়ানা' },
                { id : 13, name : 'মালেৰকোটলা' },
                { id : 14, name : 'মানসা' },
                { id : 15, name : 'মোগা' },
                { id : 16, name : 'মোহালি' },
                { id : 17, name : 'মুক্তসৰ' },
                { id : 18, name : 'পাঠানকোট' },
                { id : 19, name : 'পটিয়ালা' },
                { id : 20, name : 'ৰূপনগৰ' },
                { id : 21, name : 'সাংগৰুৰ' },
                { id : 22, name : 'শহীদ ভগত সিং নগৰ' },
                { id : 23, name : 'তাৰ্ণ তাৰণ' }
            ]
        },
        {
            id        : 29,
            name      : 'ৰাজস্থান',
            districts : [
                { id : 1, name : 'আজমেৰ' },
                { id : 2, name : 'আলৱাৰ' },
                { id : 3, name : 'বাংসৱাৰা' },
                { id : 4, name : 'বৰান' },
                { id : 5, name : 'বাৰ্মেৰ' },
                { id : 6, name : 'ভৰতপুৰ' },
                { id : 7, name : 'ভিলৱাৰা' },
                { id : 8, name : 'বিকানেৰ' },
                { id : 9, name : 'বুন্দি' },
                { id : 10, name : 'চিত্তৰগড়' },
                { id : 11, name : 'চুৰু' },
                { id : 12, name : 'দেউসা' },
                { id : 13, name : 'ধোলপুৰ' },
                { id : 14, name : 'দুংগৰপুৰ' },
                { id : 15, name : 'হনুমানগড়' },
                { id : 16, name : 'জয়পুৰ' },
                { id : 17, name : 'জয়সালমেৰ' },
                { id : 18, name : 'জালোরে' },
                { id : 19, name : 'ঝালাওৰ' },
                { id : 20, name : 'ঝুঞ্ঝুনু' },
                { id : 21, name : 'জোধপুৰ' },
                { id : 22, name : 'কৰাউলি' },
                { id : 23, name : 'কোটা' },
                { id : 24, name : 'নাগাওৰ' },
                { id : 25, name : 'পালি' },
                { id : 26, name : 'প্ৰতাপগড়' },
                { id : 27, name : 'ৰাজসামন্দ' },
                { id : 28, name : 'সাঁবাই মাধবপুৰ' },
                { id : 29, name : 'শিকাৰ' },
                { id : 30, name : 'শিৰওহি' },
                { id : 31, name : 'শ্ৰী গঙ্গানগৰ' },
                { id : 32, name : 'টঙ্ক' },
                { id : 33, name : 'উদইপুৰ' }
            ]
        },
        {
            id        : 30,
            name      : 'সিকিম',
            districts : [
                { id : 1, name : 'ইষ্ট সিকিম' },
                { id : 2, name : 'নৰ্থ সিকিম' },
                { id : 3, name : 'পাকোং' },
                { id : 4, name : 'ছোৰেং' },
                { id : 5, name : 'ছাউথ সিকিম' },
                { id : 6, name : 'ৱেষ্ট সিকিম' }
            ]
        },
        {
            id        : 31,
            name      : 'তামিলনাড়ু',
            districts : [
                { id : 1, name : 'আৰিয়ালুৰ' },
                { id : 2, name : 'চেঙ্গালপাট্টু' },
                { id : 3, name : 'চেন্নাই' },
                { id : 4, name : 'কোইম্বাটুৰ' },
                { id : 5, name : 'কাড়ালোৰ' },
                { id : 6, name : 'ধৰ্মাপুৰী' },
                { id : 7, name : 'দিন্ডিগুল' },
                { id : 8, name : 'এৰোড' },
                { id : 9, name : 'কল্লাকুৰিচি' },
                { id : 10, name : 'কাঞ্চিপুৰম' },
                { id : 11, name : 'কন্যাকুমাৰি' },
                { id : 12, name : 'কাৰুৰ' },
                { id : 13, name : 'কৃষ্ণাগিৰি' },
                { id : 14, name : 'মাধুৰাই' },
                { id : 15, name : 'ময়িলাদুথুৰাই' },
                { id : 16, name : 'নাগাপত্তিনম' },
                { id : 17, name : 'নামক্কাল' },
                { id : 18, name : 'নিলগিৰি' },
                { id : 19, name : 'পেৰাম্বালুৰ' },
                { id : 20, name : 'পুদুক্কোট্টাই' },
                { id : 21, name : 'ৰামানাথাপুৰম' },
                { id : 22, name : 'ৰানীপেট' },
                { id : 23, name : 'সেলেম' },
                { id : 24, name : 'চিভাগাঙ্গা' },
                { id : 25, name : 'টেংকাচি' },
                { id : 26, name : 'তনজাভুৰ' },
                { id : 27, name : 'থেনি' },
                { id : 28, name : 'থুঠুকুড়ি' },
                { id : 29, name : 'তিৰুচিৰাপল্লি' },
                { id : 30, name : 'তিৰুনেলভেলি' },
                { id : 31, name : 'তিৰুপত্তুৰ' },
                { id : 32, name : 'তিৰুপ্পুৰ' },
                { id : 33, name : 'তিৰুভাল্লুৰ' },
                { id : 34, name : 'তিৰুভান্নামলাই' },
                { id : 35, name : 'তিৰুভাৰুৰ' },
                { id : 36, name : 'ভেল্লোৰ' },
                { id : 37, name : 'ভিলুপ্পুৰাম' },
                { id : 38, name : 'ভিৰুধুনাগাৰ' }
            ]
        },
        {
            id        : 32,
            name      : 'তেলেংগানা',
            districts : [
                { id : 1, name : 'আদিলাবাদ' },
                { id : 2, name : 'ভদ্রদ্রী কোঠাগুড়েম' },
                { id : 3, name : 'হানামকোণ্ডা' },
                { id : 4, name : 'হায়দরাবাদ' },
                { id : 5, name : 'জাগতিয়াল' },
                { id : 6, name : 'জাঙ্গাওন' },
                { id : 7, name : 'জয়শংকর' },
                { id : 8, name : 'জগুলাম্বা' },
                { id : 9, name : 'কামারেড্ডি' },
                { id : 10, name : 'করিমনগর' },
                { id : 11, name : 'খাম্মাম' },
                { id : 12, name : 'কোমারাম ভীম' },
                { id : 13, name : 'মহাবুবাবাদ' },
                { id : 14, name : 'মাহবুবনগর' },
                { id : 15, name : 'মাঞ্চেরিয়াল' },
                { id : 16, name : 'মেদাক' },
                { id : 17, name : 'মেদচাল মালকাজ্গিরি' },
                { id : 18, name : 'মুলুগু' },
                { id : 19, name : 'নাগরকুর্নুল' },
                { id : 20, name : 'নলগোন্ডা' },
                { id : 21, name : 'নারায়ণপেট' },
                { id : 22, name : 'নির্মল' },
                { id : 23, name : 'নিজামাবাদ' },
                { id : 24, name : 'পেদ্দাপল্লি' },
                { id : 25, name : 'রাজন্না সিৰ্সিল্লা' },
                { id : 26, name : 'ৰাঙা ৰেড্ডি' },
                { id : 27, name : 'সংগাৰেড্ডি' },
                { id : 28, name : 'সিদ্দিপেট' },
                { id : 29, name : 'সুৰ্যপেট' },
                { id : 30, name : 'ভিকাৰাবাদ' },
                { id : 31, name : 'ৱানাপাৰ্থি' },
                { id : 32, name : 'ৱাৰাংগল' },
                { id : 33, name : 'যাদদ্ৰি ভুৱনাগিৰি' }
            ]
        },
        {
            id        : 33,
            name      : 'ত্ৰিপুৰা',
            districts : [
                { id : 1, name : 'ধলাই' },
                { id : 2, name : 'গোমতি' },
                { id : 3, name : 'খোৱাই' },
                { id : 4, name : 'উত্তৰ ত্ৰিপুৰা' },
                { id : 5, name : 'সেপাহিজলা' },
                { id : 6, name : 'দক্ষিণ ত্ৰিপুৰা' },
                { id : 7, name : 'উনাকোটি' },
                { id : 8, name : 'পশ্চিম ত্ৰিপুৰা' }
            ]
        },
        {
            id        : 34,
            name      : 'উত্তৰ প্ৰদেশ',
            districts : [
                { id : 1, name : 'আগ্ৰা' },
                { id : 2, name : 'আলিগড়' },
                { id : 3, name : 'আম্বেডকাৰ নগৰ' },
                { id : 4, name : 'আমেথি' },
                { id : 5, name : 'আম্ৰোহা' },
                { id : 6, name : 'ঔৰাইয়া' },
                { id : 7, name : 'অয়োধ্যা' },
                { id : 8, name : 'আজমগড়' },
                { id : 9, name : 'বাগপাত' },
                { id : 10, name : 'বহুৱাইচ' },
                { id : 11, name : 'বালিয়া' },
                { id : 12, name : 'বালৰামপুৰ' },
                { id : 13, name : 'বাণ্ডা' },
                { id : 14, name : 'বারাবঙ্কি' },
                { id : 15, name : 'বেৰেলি' },
                { id : 16, name : 'বাস্তি' },
                { id : 17, name : 'ভদোহি' },
                { id : 18, name : 'বিজনোৰ' },
                { id : 19, name : 'বুদাউন' },
                { id : 20, name : 'বুলন্দশাৰ' },
                { id : 21, name : 'চান্দৌলি' },
                { id : 22, name : 'চিত্ৰাকুট' },
                { id : 23, name : 'দেওৰিয়া' },
                { id : 24, name : 'এতাহ' },
                { id : 25, name : 'এতাও' },
                { id : 26, name : 'ফৰুখাবাদ' },
                { id : 27, name : 'ফতেহপুৰ' },
                { id : 28, name : 'ফিৰোজাবাদ' },
                { id : 29, name : 'গৌতম বুদ্ধ নগৰ' },
                { id : 30, name : 'গাজিয়াবাদ' },
                { id : 31, name : 'গাজীপুৰ' },
                { id : 32, name : 'গোণ্ডা' },
                { id : 33, name : 'গোৰখপুৰ' },
                { id : 34, name : 'হামিৰপুৰ' },
                { id : 35, name : 'হাপুৰ' },
                { id : 36, name : 'হাৰদৌই' },
                { id : 37, name : 'হাথ্ৰাছ' },
                { id : 38, name : 'জালাউন' },
                { id : 39, name : 'জৌনপুৰ' },
                { id : 40, name : 'ঝাঁসি' },
                { id : 41, name : 'কন্নয়ুজ' },
                { id : 42, name : 'কন্নপুৰ দেহাট' },
                { id : 43, name : 'কন্নপুৰ নগৰ' },
                { id : 44, name : 'কাছগঞ্জ' },
                { id : 45, name : 'কৌশাম্বি' },
                { id : 46, name : 'লখিমপুৰ খেৰি' },
                { id : 47, name : 'কুশিনগৰ' },
                { id : 48, name : 'ললিতপুৰ' },
                { id : 49, name : 'লখনৌ' },
                { id : 50, name : 'মহাৰাজগঞ্জ' },
                { id : 51, name : 'মহোবা' },
                { id : 52, name : 'মইনপুৰি' },
                { id : 53, name : 'মাথুৰা' },
                { id : 54, name : 'মাউ' },
                { id : 55, name : 'মিৰজাপুৰ' },
                { id : 56, name : 'মিৰ্জাপুৰ' },
                { id : 57, name : 'মুৰাদাবাদ' },
                { id : 58, name : 'মুজাফ্ফৰনগৰ' },
                { id : 59, name : 'পিলিভিট' },
                { id : 60, name : 'প্ৰতাপগড' },
                { id : 61, name : 'প্ৰয়াগৰাজ' },
                { id : 62, name : 'ৰায়বৰেলি' },
                { id : 63, name : 'ৰামপুৰ' },
                { id : 64, name : 'সাহাৰনপুৰ' },
                { id : 65, name : 'সম্ভল' },
                { id : 66, name : 'সন্ত কবীৰ নগৰ' },
                { id : 67, name : 'শাহজাহানপুৰ' },
                { id : 68, name : 'শামলি' },
                { id : 69, name : 'শ্ৰাৱষ্টি' },
                { id : 70, name : 'সিদ্ধাৰ্থনগৰ' },
                { id : 71, name : 'সিতাপুৰ' },
                { id : 72, name : 'ছোনভদ্ৰ' },
                { id : 73, name : 'সুলতানপুৰ' },
                { id : 74, name : 'উননাও' },
                { id : 75, name : 'বাৰাণসী' }
            ]
        },
        {
            id        : 35,
            name      : 'উত্তৰাখণ্ড',
            districts : [
                { id : 1, name : 'আলমোৰা' },
                { id : 2, name : 'বাগেশ্বর' },
                { id : 3, name : 'চামোলি' },
                { id : 4, name : 'চাম্পাওৱাট' },
                { id : 5, name : 'দেহৰাদুন' },
                { id : 6, name : 'হারিদ্বাৰ' },
                { id : 7, name : 'নাইনিতাল' },
                { id : 8, name : 'পৌড়ি' },
                { id : 9, name : 'পিথোৰাগড়' },
                { id : 10, name : 'ৰুদ্ৰপ্ৰয়াগ' },
                { id : 11, name : 'টেহ্ৰী' },
                { id : 12, name : 'উধম সিং নগৰ' },
                { id : 13, name : 'উত্তৰাকাশি' }
            ]
        },
        {
            id        : 36,
            name      : 'পশ্চিমবঙ্গ',
            districts : [
                { id : 1, name : 'আলিপুরদুয়ার' },
                { id : 2, name : 'বাঁকুড়া' },
                { id : 3, name : 'বীরভূম' },
                { id : 4, name : 'কোচ বিহার' },
                { id : 5, name : 'দক্ষিণ দিনাজপুর' },
                { id : 6, name : 'দার্জিলিং' },
                { id : 7, name : 'হুগলি' },
                { id : 8, name : 'হাওড়া' },
                { id : 9, name : 'জলপাইগুড়ি' },
                { id : 10, name : 'ঝাড়গ্রাম' },
                { id : 11, name : 'কালিম্পং' },
                { id : 12, name : 'কলকাতা' },
                { id : 13, name : 'মালদা' },
                { id : 14, name : 'মুর্শিদাবাদ' },
                { id : 15, name : 'নদিয়া' },
                { id : 16, name : 'উত্তর ২৪ পরগনা' },
                { id : 17, name : 'পশ্চিম বর্ধমান' },
                { id : 18, name : 'পশ্চিম মেদিনীপুর' },
                { id : 19, name : 'পূর্ব বর্ধমান' },
                { id : 20, name : 'পূর্ব মেদিনীপুর' },
                { id : 21, name : 'পুরুলিয়া' },
                { id : 22, name : 'দক্ষিণ ২৪ পরগনা' },
                { id : 23, name : 'উত্তর দিনাজপুর' }
            ]
        }
    ],
    occupationCategoryOptions : [
        {
            id          : 1,
            category    : 'নিৰ্মাণকাৰ্য্য',
            occupations : [
                { name : 'অট্টালিকা', id : 1 },
                { name : 'ৰাস্তা', id : 2 },
                { name : 'দলং', id : 3 },
                { name : 'অন্যান্য আন্তঃগাঁথনি', id : 4 }
            ]
        },
        {
            id          : 2,
            category    : 'কৃষি',
            occupations : [
                { name : 'খেতি', id : 1 },
                { name : 'বৃক্ষৰোপণৰ কাম', id : 2 },
                { name : 'শস্য চপোৱাৰ কাম', id : 3 },
                { name : 'অন্যান্য আন্তঃগাঁথনি', id : 4 }
            ]
        },
        {
            id          : 3,
            category    : 'ঘৰুৱা সহায়ক',
            occupations : [
                { name : 'ঘৰ চাফাই কৰ্মী', id : 1 },
                { name : 'ৰান্ধনি', id : 2 },
                { name : 'চাইল্ডকেয়াৰ', id : 3 },
                { name : 'বৃদ্ধসকলৰ যত্ন', id : 4 }
            ]
        },
        {
            id          : 4,
            category    : 'উৎপাদন আৰু ঔদ্যোগিক শ্ৰমিক',
            occupations : [
                { name : 'বস্ত্ৰ উদ্য্যগ', id : 1 },
                { name : 'প্লাইউড', id : 2 },
                { name : 'খাদ্য প্ৰক্ৰিয়াকৰণ', id : 3 },
                { name : 'পোছাক নিৰ্মাণ', id : 4 }
            ]
        },
        {
            id          : 5,
            category    : 'আতিথ্য আৰু সেৱা উদ্যোগ',
            occupations : [
                { name : 'হাউচকিপিং', id : 1 },
                { name : 'পৰিৱেশক', id : 2 },
                { name : 'ফ্ৰণ্ট ডেস্ক', id : 3 },
                { name : 'তত্বাৱধায়ক', id : 4 }
            ]
        },
        {
            id          : 6,
            category    : 'পৰিবহন আৰু লজিষ্টিক',
            occupations : [
                { name : 'ড্ৰাইভাৰ', id : 1 },
                { name : 'লোডাৰ', id : 2 },
                { name : 'সহায়ক', id : 3 },
                { name : 'ৱেৰহাউছিং', id : 4 }
            ]
        },
        {
            id          : 7,
            category    : 'পথ বিক্ৰেতা আৰু ক্ষুদ্ৰ ব্যৱসায়',
            occupations : [
                { name : 'পান দোকান', id : 1 },
                { name : 'বুজিৰ দোকান', id : 2 },
                { name : 'ফাষ্ট ফুড', id : 3 },
                { name : 'অন্যান্য', id : 4 }
            ]
        },
        {
            id          : 8,
            category    : 'স্বাস্থ্যসেৱা সহায়ক কৰ্মচাৰী',
            occupations : [
                { name : 'নাৰ্চৰ সহায়ক', id : 1 },
                { name : 'জেনিটৰ', id : 2 },
                { name : 'ৰোগী পৰিচাৰক', id : 3 }
            ]
        },
        {
            id          : 9,
            category    : 'মীন পালন আৰু সামুদ্ৰিক উদ্যোগ',
            occupations : [
                { name : 'মাছ ধৰা', id : 1 },
                { name : 'মাছ প্ৰক্ৰিয়াকৰণ', id : 2 },
                { name : 'নাও নিৰ্মাণ', id : 3 },
                { name : 'সামুদ্ৰিক উদ্যোগ', id : 4 }
            ]
        },
        {
            id          : 10,
            category    : 'সামুদ্ৰিক উদ্যোগ',
            occupations : [
                { name : 'অনাময় কৰ্মী', id : 1 },
                { name : 'নিৰাপত্তাৰক্ষী', id : 2 },
                { name : 'মেনুৱেল শ্ৰমিক', id : 3 }
            ]
        }
    ],
    questionnaire : [
        {
            id          : 1,
            category_id : 1,
            questions   : [
                'আপুনি অতীতত কেতিয়াবা কাম সম্পৰ্কীয় কোনো আঘাত বা দুৰ্ঘটনাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি বৰ্তমান আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো শাৰীৰিক অসুবিধা বা বিষ অনুভৱ কৰি আছে নেকি?',
                'আপুনি নিৰ্মাণ উদ্যোগৰ বাবে নিৰ্দিষ্ট কোনো বৃত্তিগত স্বাস্থ্য আৰু সুৰক্ষা প্ৰশিক্ষণ বা সজাগতা কাৰ্যসূচী প্ৰাপ্ত কৰিছে নেকি?',
                'আপুনি সচৰাচৰ নিৰ্মাণ-সম্পৰ্কীয় স্বাস্থ্য বিপদাশংকা, যেনে পৰি যোৱা, শব্দৰ সংস্পৰ্শলৈ অহা, বা ৰাসায়নিক বিপদ প্ৰতিহত কৰাৰ বাবে সুৰক্ষা প্ৰটোকল আৰু ব্যৱস্থাবোৰৰ বিষয়ে অৱগত নেকি?',
                'নিৰ্মাণ ক্ষেত্ৰত কাম কৰোঁতে আপোনাৰ ব্যক্তিগত সুৰক্ষা সঁজুলি (পিপিই) যেনে হেলমেট, হাতমোজা, সুৰক্ষা গগলছ, আৰু হাৰ্নেছৰ উপলব্ধতা আছে নেকি?',
                'পেশীৰ কংকালৰ আঘাত প্ৰতিহত কৰিবলৈ আপুনি সঠিক উত্তোলন কৌশল আৰু এৰ্গোনোমিক্সৰ গুৰুত্বৰ বিষয়ে অৱগত নেকি?',
                'আপোনাক সাধাৰণ বৃত্তিগত স্বাস্থ্য বিপদাশংকা যেনে টিটেনাছ, হেপাটাইটিছ বি, বা অন্যান্য প্ৰাসঙ্গিক ভেকছিন বিৰুদ্ধে টিকাকৰণ কৰা হৈছে নেকি?',
                'নিৰ্মাণ কাৰ্যৰ সৈতে সম্পৰ্কিত স্বাস্থ্যৰ যেনে শ্বাস-প্ৰশ্বাসৰ সমস্যা, শ্ৰৱণ শক্তি হ্ৰাস, বা ছালৰ বিকাৰজনিত স্থিতিৰ বাবে আপোনাক নিয়মীয়াকৈ পৰীক্ষা বা নিৰীক্ষণ কৰা হয় নেকি?',
                'ভাগৰ প্ৰতিৰোধ কৰিবলৈ আৰু সামগ্ৰিক সুস্থতা বজাই ৰাখিবলৈ আপোনাৰ কামৰ শ্বিফ্টৰ সময়ত আপোনাক নিয়মীয়া বিৰতি আৰু বিশ্ৰামৰ সময় প্ৰদান কৰা হয় নেকি?',
                'নিৰ্মাণ ক্ষেত্ৰত থকা সময়ত কোনো স্বাস্থ্য জৰুৰী অৱস্থা বা উদ্বেগৰ ক্ষেত্ৰত আপুনি উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি?'
            ]
        },
        {
            id          : 2,
            category_id : 2,
            questions   : [
                'কৃষিত কাম কৰোঁতে অতীতত আপুনি কোনো কাম সম্পৰ্কীয় আঘাত বা দুৰ্ঘটনাৰ সন্মুখীন হৈছিল নেকি?',
                'আপুনি বৰ্তমান কৃষিক্ষেত্ৰত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো শাৰীৰিক অসুবিধা বা স্বাস্থ্যজনিত সমস্যাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি কৃষি পদ্ধতিত কীটনাশক, সাৰ বা অন্যান্য ৰাসায়নিক পদাৰ্থৰ ব্যৱহাৰৰ সৈতে সম্পৰ্কিত সাম্ভাব্য স্বাস্থ্য বিপদাশংকাৰ বিষয়ে অৱগত নে?',
                'কৃষিজাত ৰাসায়নিক পদাৰ্থ চম্ভালি থাকোঁতে বা সাম্ভাব্য বিপদৰ সৈতে কাম কৰাৰ সময়ত আপোনাৰ ব্যক্তিগত সুৰক্ষা সঁজুলি (পিপিই) যেনে হাতমোজা, মুখা, বা গগলছ আদিৰ উপলব্ধতা আছে নেকি?',
                'কৃষিকাৰ্য সম্পাদন কৰোঁতে পেশীৰ কংকালৰ আঘাত প্ৰতিহত কৰিবলৈ সঠিক উত্তোলন কৌশল আৰু এৰ্গোনোমিক্সৰ গুৰুত্বৰ বিষয়ে আপুনি অৱগত নে?',
                'সাধাৰণ কৃষিস্বাস্থ্যৰ বিপদাশংকা, যেনে তাপ-সম্পৰ্কীয় ৰোগ, শ্বাস-প্ৰশ্বাসৰ সমস্যা, বা পশুজাত ৰোগৰ সংস্পৰ্শলৈ অহা প্ৰতিহত কৰাৰ বাবে আপুনি সুৰক্ষা নিৰ্দেশনা আৰু পদ্ধতিবোৰৰ বিষয়ে জ্ঞাঅৱোগত নেকি?',
                'আপুনি কৃষিজাত ৰাসায়নিক পদাৰ্থ আৰু সঁজুলিৰ সুৰক্ষিত সঞ্চালন আৰু সংৰক্ষণ সম্পৰ্কে কোনো প্ৰশিক্ষণ বা তথ্য প্ৰাপ্ত কৰিছে নেকি?',
                'কৃষিকৰ্মৰ সৈতে সম্পৰ্কিত হ\'ব পৰা স্বাস্থ্যৰ স্থিতি, যেনে শ্বাস-প্ৰশ্বাসৰ সমস্যা, ছালৰ বিকাৰ, বা এলাৰ্জিৰ বাবে আপোনাক নিয়মীয়াকৈ পৰীক্ষা বা নিৰীক্ষণ কৰা হয় নেকি?',
                'পথাৰত কাম কৰোঁতে তাপ-সম্পৰ্কীয় ৰোগ আৰু ডিহাইড্ৰেচন প্ৰতিহত কৰিবলৈ আপোনাক নিয়মীয়া বিৰতি, পৰিষ্কাৰ খোৱা পানী, আৰু ছাঁ যুক্ত এলেকা প্ৰদান কৰা হয় নেকি?',
                'আপুনি কোনো স্বাস্থ্য জৰুৰী কালীন অৱস্থা বা কৃষিৰ কামৰ সৈতে সম্পৰ্কিত উদ্বেগৰ ক্ষেত্ৰত উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি?'
            ]
        },
        {
            id          : 3,
            category_id : 3,
            questions   : [
                'অতীতত ঘৰুৱা কাম বোৰ সম্পাদন কৰোঁতে আপুনি কাম সম্পৰ্কীয় কোনো আঘাত বা দুৰ্ঘটনাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি বৰ্তমান ঘৰুৱা সহায়ক হিচাপে আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো শাৰীৰিক অসুবিধা বা স্বাস্থ্যজনিত সমস্যাৰ সন্মুখীন হৈছে নেকি?',
                'ঘৰুৱা কাম বোৰ কৰোঁতে পেশীৰ কংকালৰ আঘাত প্ৰতিহত কৰিবলৈ আপুনি সঠিক উত্তোলন কৌশল আৰু এৰ্গোনোমিক্সৰ বিষয়ে অৱগত নেকি?',
                'পৰিষ্কাৰ কৰা ৰাসায়নিক পদাৰ্থ চম্ভালি থাকোঁতে বা সাম্ভাব্য বিপদৰ সৈতে কাম কৰাৰ সময়ত আপোনাৰ ব্যক্তিগত সুৰক্ষা সঁজুলি (পিপিই) যেনে হাতমোজা, মুখা বা এপ্ৰনৰ উপলব্ধতা আছে নেকি আৰু নিৰন্তৰভাৱে ব্যৱহাৰ কৰিব পাৰে নেকি?',
                'পৰিষ্কাৰ কৰা ৰাসায়নিক পদাৰ্থ, ডিটাৰ্জেণ্ট আৰু অন্যান্য ঘৰুৱা সামগ্ৰীৰ সুৰক্ষিত ব্যৱহাৰ আৰু সংৰক্ষণৰ বিষয়ে আপুনি অৱগত নেকি?',
                'ভাগৰ প্ৰতিৰোধ কৰিবলৈ আৰু সামগ্ৰিক সুস্থতা বজাই ৰাখিবলৈ আপোনাৰ কামৰ শ্বিফ্টৰ সময়ত আপোনাক পৰ্যাপ্ত বিশ্ৰামৰ ম্যাদ আৰু বিৰতি প্ৰদান কৰা হৈছে নেকি?',
                'পৰিষ্কাৰ কৰাৰ কাম বোৰ সম্পাদন কৰোঁতে আপুনি ভাল ভিতৰৰ বায়ুৰ গুণমান আৰু বায়ু চলাচল বাহাল ৰখাৰ গুৰুত্বৰ বিষয়ে অৱগত নে?',
                'ঘৰুৱা সহায়ক হিচাপে আপোনাৰ কামৰ সৈতে সম্পৰ্কিত হ\'ব পৰা স্বাস্থ্যৰ স্থিতি যেনে শ্বাস-প্ৰশ্বাসৰ সমস্যা, এলাৰ্জি, বা ছালৰ বিকাৰৰ বাবে আপোনাক নিয়মীয়াকৈ পৰীক্ষা বা নিৰীক্ষণ কৰা হয় নেকি?',
                'আপোনাক প্ৰাথমিক প্ৰাথমিক চিকিৎসাৰ ওপৰত প্ৰশিক্ষণ বা তথ্য প্ৰদান কৰা হৈছে নেকি, যাৰ ভিতৰত আছে জৰুৰীকালীন যোগাযোগ নম্বৰৰ জ্ঞান আৰু দুৰ্ঘটনা বা স্বাস্থ্যজৰুৰী অৱস্থাৰ ক্ষেত্ৰত গ্ৰহণ কৰিব লগা পদক্ষেপ?',
                'আপুনি কোনো স্বাস্থ্য জৰুৰী কালীন অৱস্থা বা ঘৰুৱা সহায়ক হিচাপে আপোনাৰ কামৰ সৈতে সম্পৰ্কিত উদ্বেগৰ ক্ষেত্ৰত উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি?'
            ]
        },
        {
            id          : 4,
            category_id : 4,
            questions   : [
                'নিৰ্মাণ বা ঔদ্যোগিক ছেটিংছত কাম কৰোঁতে আপুনি অতীতত কোনো কাম সম্পৰ্কীয় আঘাত বা দুৰ্ঘটনাৰ সন্মুখীন হৈছিল নেকি?',
                'আপুনি বৰ্তমান উৎপাদন বা ঔদ্যোগিক ছেটিংছত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো শাৰীৰিক অসুবিধা বা স্বাস্থ্যজনিত সমস্যাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি উৎপাদন বা ঔদ্যোগিক খণ্ডত আপোনাৰ নিৰ্দিষ্ট চাকৰিৰ ভূমিকাৰ সৈতে সম্পৰ্কিত সাম্ভাব্য স্বাস্থ্য বিপদাশংকাৰ বিষয়ে অৱগত নে?',
                'সাম্ভাব্য বিপদথকা এলেকাত কাম কৰোঁতে আপোনাৰ ব্যক্তিগত সুৰক্ষা সঁজুলি (পিপিই) যেনে সুৰক্ষা গগলচ, হাতমোজা, কাণসুৰক্ষা, বা মুখা আদিৰ উপলব্ধতা আছে নেকি?',
                'সাধাৰণ ঔদ্যোগিক স্বাস্থ্যৰ বিপদাশংকা প্ৰতিহত কৰাৰ বাবে আপুনি সুৰক্ষা প্ৰটোকল আৰু ব্যৱস্থাৰ বিষয়ে জানে নেকি, যেনে ক্ষতিকাৰক ৰাসায়নিক পদাৰ্থৰ সংস্পৰ্শলৈ অহা, শব্দ-প্ৰেৰিত শ্ৰৱণ শক্তি হ্ৰাস, বা এৰ্গোনোমিক-সম্পৰ্কীয় আঘাত?',
                'ভাগৰ প্ৰতিৰোধ কৰিবলৈ আৰু সামগ্ৰিক সুস্থতা বজাই ৰাখিবলৈ আপোনাৰ কামৰ শ্বিফ্টৰ সময়ত আপোনাক নিয়মীয়া বিৰতি আৰু বিশ্ৰামৰ সময় প্ৰদান কৰা হয় নেকি?',
                'আপুনি আপোনাৰ কামৰ কাম বোৰ সম্পাদন কৰোঁতে পেশীৰ কংকালৰ আঘাতৰ আশংকা হ্ৰাস কৰিবলৈ সঠিক ভঙ্গী বজাই ৰখা আৰু এৰ্গোনোমিক সঁজুলি ব্যৱহাৰ কৰাৰ গুৰুত্বৰ বিষয়ে অৱগত নে?',
                'উৎপাদন বা ঔদ্যোগিক খণ্ডত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত হ\'ব পৰা স্বাস্থ্যৰ স্থিতিৰ বাবে আপোনাক নিয়মীয়াকৈ পৰীক্ষা বা নিৰীক্ষণ কৰা হয় নেকি, যেনে শ্বাস-প্ৰশ্বাসৰ সমস্যা, শ্ৰৱণ শক্তি হ্ৰাস, বা বৃত্তিগত সম্পৰ্কীয় ৰোগ?',
                'আপোনাক জৰুৰীকালীন প্ৰক্ৰিয়াবোৰৰ ওপৰত প্ৰশিক্ষণ বা তথ্য প্ৰদান কৰা হৈছে নেকি, যাৰ ভিতৰত আছে জৰুৰীকালীন যোগাযোগ নম্বৰৰ জ্ঞান আৰু কৰ্মস্থানত দুৰ্ঘটনা বা স্বাস্থ্যজৰুৰী অৱস্থাৰ ক্ষেত্ৰত গ্ৰহণ কৰিব লগা পদক্ষেপ?',
                'আপুনি কোনো স্বাস্থ্য জৰুৰী কালীন অৱস্থা বা উৎপাদন বা ঔদ্যোগিক খণ্ডত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত উদ্বেগৰ ক্ষেত্ৰত উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি?'
            ]
        },
        {
            id          : 5,
            category_id : 5,
            questions   : [
                'আতিথ্য আৰু সেৱা উদ্যোগত কাম কৰোঁতে আপুনি অতীতত কোনো কাম সম্পৰ্কীয় আঘাত বা দুৰ্ঘটনাঅনুভৱ কৰিছে নেকি?',
                'আপুনি বৰ্তমান আতিথ্য আৰু সেৱা উদ্যোগত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো শাৰীৰিক অসুবিধা বা স্বাস্থ্যজনিত সমস্যাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি আতিথ্য আৰু সেৱা উদ্যোগত আপোনাৰ নিৰ্দিষ্ট চাকৰিৰ ভূমিকাৰ সৈতে সম্পৰ্কিত সাম্ভাব্য স্বাস্থ্য বিপদাশংকাৰ বিষয়ে অৱগত নে?',
                'খাদ্য, ৰাসায়নিক পদাৰ্থ, বা সাম্ভাব্য বিপদৰ সৈতে কাম কৰাৰ সময়ত আপোনাৰ ব্যক্তিগত সুৰক্ষা সঁজুলি (পিপিই) যেনে হাতমোজা, এপ্ৰন, বা মুখা আদিৰ উপলব্ধতা আছে নেকি আৰু নিৰন্তৰভাৱে ব্যৱহাৰ কৰিব পাৰে নেকি?',
                'খাদ্যজনিত ৰোগ প্ৰতিৰোধ আৰু খাদ্য সুৰক্ষা নিশ্চিত কৰাৰ বাবে সঠিক খাদ্য সঞ্চালন আৰু পৰিচ্ছন্নতা পদ্ধতিৰ বিষয়ে আপুনি জানে নেকি?',
                'ভাগৰ প্ৰতিৰোধ কৰিবলৈ আৰু সামগ্ৰিক সুস্থতা বজাই ৰাখিবলৈ আপোনাৰ কামৰ শ্বিফ্টৰ সময়ত আপোনাক নিয়মীয়া বিৰতি আৰু বিশ্ৰামৰ সময় প্ৰদান কৰা হয় নেকি?? ',
                'আপুনি আপোনাৰ কামৰ কাম বোৰ সম্পাদন কৰোঁতে পেশীৰ কংকালৰ আঘাতৰ আশংকা হ্ৰাস কৰিবলৈ ভাল ভঙ্গী বজাই ৰখা আৰু এৰ্গোনোমিক সঁজুলি ব্যৱহাৰ কৰাৰ গুৰুত্বৰ বিষয়ে অৱগত নে?',
                'আতিথ্য আৰু সেৱা উদ্যোগত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত হ\'ব পৰা স্বাস্থ্যৰ স্থিতি যেনে পুনৰাবৃত্তিমূলক চাপৰ আঘাত, শ্বাস-প্ৰশ্বাসৰ সমস্যা, বা চাপ-সম্পৰ্কীয় স্থিতিৰ বাবে আপোনাক নিয়মীয়াকৈ পৰীক্ষা বা নিৰীক্ষণ কৰা হয় নেকি,?',
                'আপোনাক জৰুৰীকালীন যোগাযোগ নম্বৰৰ জ্ঞান আৰু কৰ্মস্থানত দুৰ্ঘটনা বা স্বাস্থ্যজৰুৰী অৱস্থাৰ ক্ষেত্ৰত গ্ৰহণ কৰিব লগা পদক্ষেপকে আদি কৰি জৰুৰীকালীন প্ৰক্ৰিয়াবোৰৰ ওপৰত প্ৰশিক্ষণ বা তথ্য প্ৰদান কৰা হৈছে নেকি?',
                'আতিথ্য আৰু সেৱা উদ্যোগত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো স্বাস্থ্য জৰুৰী অৱস্থা বা উদ্বেগৰ ক্ষেত্ৰত আপুনি উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি?'
            ]
        },
        {
            id          : 6,
            category_id : 6,
            questions   : [
                'পৰিবহন আৰু লজিষ্টিকত কাম কৰোঁতে আপুনি অতীতত কোনো কাম সম্পৰ্কীয় আঘাত বা দুৰ্ঘটনাৰ সন্মুখীন হৈছিল নেকি?',
                'আপুনি বৰ্তমান পৰিবহন আৰু লজিষ্টিকৰ কামৰ সৈতে সম্পৰ্কিত কোনো শাৰীৰিক অসুবিধা বা স্বাস্থ্যজনিত সমস্যাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি পৰিবহন আৰু লজিষ্টিক খণ্ডত আপোনাৰ নিৰ্দিষ্ট চাকৰিৰ ভূমিকাৰ সৈতে সম্পৰ্কিত সাম্ভাব্য স্বাস্থ্য বিপদাশংকাৰ বিষয়ে অৱগত নে?',
                'সম্ভাৱ্য বিপদৰ সৈতে কাম বোৰ সম্পাদন কৰোঁতে আপোনাৰ ব্যক্তিগত সুৰক্ষা সঁজুলি (পিপিই) যেনে হেলমেট, সুৰক্ষা ভেষ্ট, হাতমোজা, বা মুখা আদিৰ উপলব্ধতা আছে নেকি?',
                'আপুনি সঠিক চিটবেল্ট ব্যৱহাৰ, যান-বাহনৰ নিয়ম পালন কৰা, আৰু পথ সুৰক্ষা বজাই ৰখাকে আদি কৰি সুৰক্ষিত ড্ৰাইভিং পদ্ধতিৰ বিষয়ে জানে নেকি?',
                'ভাগৰ প্ৰতিৰোধ কৰিবলৈ আৰু সামগ্ৰিক সুস্থতা বজাই ৰাখিবলৈ আপোনাৰ কামৰ শ্বিফ্টৰ সময়ত আপোনাক নিয়মীয়া বিৰতি আৰু বিশ্ৰামৰ সময় প্ৰদান কৰা হয় নেকি?',
                'আপুনি ভাল ভঙ্গী বজাই ৰখা আৰু সামগ্ৰী চম্ভালি থাকোঁতে বা চম্ভালি থাকোঁতে পেশীৰ কংকালৰ আঘাতৰ আশংকা হ্ৰাস কৰিবলৈ এৰ্গোনোমিক সঁজুলি, যেনে কঁকালৰ সমৰ্থন বা এডজাষ্টেবল আসন ব্যৱহাৰ কৰাৰ গুৰুত্বৰ বিষয়ে অৱগত নে?',
                'পৰিবহন আৰু লজিষ্টিক খণ্ডত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত হ\'ব পৰা স্বাস্থ্যৰ স্থিতি যেনে পিঠিৰ বিষ, দৃষ্টিশক্তিৰ সমস্যা, বা টোপনিৰ বিকাৰৰ বাবে আপোনাক নিয়মীয়াকৈ পৰীক্ষা বা নিৰীক্ষণ কৰা হয় নেকি?',
                'আপোনাক জৰুৰীকালীন যোগাযোগ নম্বৰৰ জ্ঞান আৰু পথত বা কৰ্মস্থানত দুৰ্ঘটনা বা স্বাস্থ্যজৰুৰী অৱস্থাৰ ক্ষেত্ৰত গ্ৰহণ কৰিব লগা পদক্ষেপকে আদি কৰি জৰুৰীকালীন প্ৰক্ৰিয়াবোৰৰ ওপৰত প্ৰশিক্ষণ বা তথ্য প্ৰদান কৰা হৈছে নেকি?',
                'পৰিবহন আৰু লজিষ্টিক খণ্ডত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো স্বাস্থ্য জৰুৰী কালীন বা উদ্বেগৰ ক্ষেত্ৰত আপুনি উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি?'
            ]
        },
        {
            id          : 7,
            category_id : 7,
            questions   : [
                'পথৰ বিক্ৰেতা হিচাপে বা সৰু ব্যৱসায়ত কাম কৰোঁতে আপুনি অতীতত কোনো কাম সম্পৰ্কীয় আঘাত বা দুৰ্ঘটনাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি বৰ্তমান পথৰ বিক্ৰেতা হিচাপে বা সৰু ব্যৱসায়ত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত কোনো শাৰীৰিক অসুবিধা বা স্বাস্থ্যজনিত সমস্যাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি পথৰ বিক্ৰেতা হিচাপে বা সৰু ব্যৱসায়ত আপোনাৰ নিৰ্দিষ্ট চাকৰিৰ ভূমিকাৰ সৈতে সম্পৰ্কিত সাম্ভাব্য স্বাস্থ্য বিপদাশংকাৰ বিষয়ে অৱগত নে?',
                'খাদ্য পৰিচালনা কৰোঁতে বা সাম্ভাব্য বিপদৰ সৈতে কাম কৰাৰ সময়ত আপোনাৰ ব্যক্তিগত সুৰক্ষা সঁজুলি (পিপিই) যেনে হাতমোজা, এপ্ৰন, বা মুখা আদিৰ উপলব্ধতা আছে নেকি আৰু নিৰন্তৰভাৱে ব্যৱহাৰ কৰে নেকি?',
                'খাদ্যজনিত ৰোগ প্ৰতিৰোধ আৰু খাদ্য সুৰক্ষা নিশ্চিত কৰাৰ বাবে সঠিক খাদ্য সঞ্চালন আৰু পৰিচ্ছন্নতা পদ্ধতিৰ বিষয়ে আপুনি জানে নেকি?',
                'ভাগৰ প্ৰতিৰোধ কৰিবলৈ আৰু সামগ্ৰিক সুস্থতা বজাই ৰাখিবলৈ আপোনাৰ কামৰ শ্বিফ্টৰ সময়ত আপোনাক নিয়মীয়া বিৰতি আৰু বিশ্ৰামৰ সময় প্ৰদান কৰা হয় নেকি?',
                'আপুনি পথৰ বিক্ৰেতা হিচাপে বা সৰু ব্যৱসায়ত কাম কৰোঁতে পেশীৰ কংকালৰ আঘাতৰ আশংকা হ্ৰাস কৰিবলৈ ভাল ভঙ্গী বজাই ৰখা আৰু এৰ্গোনোমিক সঁজুলি ব্যৱহাৰ কৰাৰ গুৰুত্বৰ বিষয়ে অৱগত নে?',
                'আপোনাৰ কামৰ সৈতে সম্পৰ্কিত হ\'ব পৰা স্বাস্থ্যৰ স্থিতি, যেনে শ্বাস-প্ৰশ্বাসৰ সমস্যা, ছালৰ বিকাৰ, বা চাপ-সম্পৰ্কীয় স্থিতিৰ বাবে আপোনাক নিয়মীয়াকৈ পৰীক্ষা বা নিৰীক্ষণ কৰা হয় নেকি?',
                'আপোনাৰ কৰ্মস্থানত দুৰ্ঘটনা বা স্বাস্থ্যজৰুৰী অৱস্থাৰ ক্ষেত্ৰত জৰুৰীকালীন যোগাযোগ নম্বৰৰ জ্ঞান আৰু গ্ৰহণ কৰিব লগা পদক্ষেপসহ আপোনাক জৰুৰীকালীন প্ৰক্ৰিয়াবোৰৰ ওপৰত প্ৰশিক্ষণ বা তথ্য প্ৰদান কৰা হৈছে নেকি?',
                'আপুনি কোনো স্বাস্থ্যজৰুৰী অৱস্থা বা পথৰ বিক্ৰেতা হিচাপে বা সৰু ব্যৱসায়ত আপোনাৰ কামৰ সৈতে সম্পৰ্কিত উদ্বেগৰ ক্ষেত্ৰত উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি?'
            ]
        },
        {
            id          : 8,
            category_id : 8,
            questions   : [
                'আপুনি কেতিয়াবা নিজৰ বা আপোনাৰ নিৰ্ভৰশীলসকলৰ বাবে স্বাস্থ্যসেৱা প্ৰাপ্ত কৰাৰ ক্ষেত্ৰত প্ৰত্যাহ্বানৰ সন্মুখীন হৈছে নেকি?',
                'স্বাস্থ্যসেৱা সহায়ক কৰ্মচাৰী হিচাপে আপোনাৰ বাবে উপলব্ধ স্বাস্থ্যসেৱাৰ লাভালাভ আৰু সম্পদৰ বিষয়ে আপুনি অৱগত নে?',
                'প্ৰয়োজন হ\'লে সময়মতে চিকিৎসা বা পৰামৰ্শ প্ৰাপ্ত কৰাত আপুনি কোনো অসুবিধাৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি আপোনাৰ নিয়োগকৰ্তা বা প্ৰতিষ্ঠানৰ দ্বাৰা প্ৰদান কৰা স্বাস্থ্যসেৱা সুবিধাবোৰত সন্তুষ্ট নে?',
                'স্বাস্থ্যসেৱা সহায়ক কৰ্মচাৰী হিচাপে স্বাস্থ্যসেৱাৰ বাবে আপোনাৰ অধিকাৰ আৰু অধিকাৰ সম্পৰ্কে আপুনি প্ৰশিক্ষণ বা তথ্য প্ৰাপ্ত কৰিছে নেকি?',
                'আপুনি আপোনাৰ কৰ্মস্থানত স্বাস্থ্যৰ উদ্বেগ বা বৃত্তিগত বিপদৰ প্ৰতিবেদন দিয়াৰ প্ৰক্ৰিয়াটোৰ বিষয়ে অৱগত নেকি?',
                'স্বাস্থ্যসেৱা উদ্যোগত আপোনাৰ ভূমিকাৰ বাবে প্ৰয়োজনীয় টিকাকৰণ বা টিকাকৰণ প্ৰাপ্ত কৰাৰ ক্ষেত্ৰত আপুনি কোনো বাধাৰ সন্মুখীন হৈছে নেকি?',
                'আপোনাৰ সুস্থতা নিৰীক্ষণ কৰিবলৈ আৰু যিকোনো সাম্ভাব্য স্বাস্থ্য সমস্যা চিনাক্ত কৰিবলৈ আপোনাক নিয়মীয়া স্বাস্থ্য পৰীক্ষা বা স্ক্ৰীণিং প্ৰদান কৰা হৈছে নেকি?',
                'কাম সম্পৰ্কীয় চাপ বা আৱেগিক সুস্থতাৰ মোকাবিলা কৰিবলৈ আপোনাৰ মানসিক স্বাস্থ্য সমৰ্থন বা পৰামৰ্শ সেৱাৰ উপলব্ধতা আছে নেকি?',
                'আপুনি কৰ্তব্যৰত অৱস্থাত জৰুৰীকালীন চিকিৎসা সহায় বিচৰাপ্ৰক্ৰিয়া আৰু সম্পদৰ সৈতে পৰিচিত নেকি?'
            ]
        },
        {
            id          : 9,
            category_id : 9,
            questions   : [
                'মীন পালন আৰু সামুদ্ৰিক উদ্যোগত কাম কৰোঁতে আপুনি কেতিয়াবা নিজৰ বা আপোনাৰ নিৰ্ভৰশীলসকলৰ বাবে স্বাস্থ্যসেৱা প্ৰাপ্ত কৰাৰ ক্ষেত্ৰত প্ৰত্যাহ্বানৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি এজন মীন পালন আৰু সামুদ্ৰিক উদ্যোগৰ শ্ৰমিক হিচাপে আপোনাৰ বাবে উপলব্ধ স্বাস্থ্যসেৱাৰ লাভালাভ আৰু সম্পদৰ বিষয়ে অৱগত নেকি?',
                'প্ৰয়োজন হ\'লে সময়মতে চিকিৎসা বা পৰামৰ্শ প্ৰাপ্ত কৰাত আপুনি কোনো অসুবিধাৰ সন্মুখীন হৈছে নেকি? যদি হয়, অনুগ্ৰহ কৰি বিৱৰণ প্ৰদান কৰক।',
                'মীন পালন আৰু সামুদ্ৰিক উদ্যোগত আপোনাৰ কামৰ সন্দৰ্ভত আপোনাৰ নিয়োগকৰ্তা বা প্ৰতিষ্ঠানৰ দ্বাৰা প্ৰদান কৰা স্বাস্থ্যসেৱা সুবিধাবোৰত আপুনি সন্তুষ্ট নে? যদি নহয়, আপুনি কি উন্নতিৰ পৰামৰ্শ দিব?',
                'আপুনি মীন পালন আৰু সামুদ্ৰিক উদ্যোগৰ সৈতে সম্পৰ্কিত বৃত্তিগত বিপদ বা আঘাত যেনে পানীবাহিত ৰোগ বা মাছ ধৰা সঁজুলি চম্ভালি থকাৰ পৰা আঘাতৰ বাবে উপলব্ধ নিৰ্দিষ্ট স্বাস্থ্যসেৱা বা সমৰ্থনৰ বিষয়ে অৱগত নে?',
                'আপুনি মীন পালন আৰু সামুদ্ৰিক উদ্যোগৰ শ্ৰমিক হিচাপে স্বাস্থ্যসেৱাৰ বাবে আপোনাৰ অধিকাৰ আৰু অধিকাৰ সম্পৰ্কে প্ৰশিক্ষণ বা তথ্য প্ৰাপ্ত কৰিছে নেকি?',
                'আপুনি জাহাজত বা সাগৰত থাকোঁতে জৰুৰীকালীন চিকিৎসা সহায় বিচৰা প্ৰক্ৰিয়া আৰু সম্পদৰ সৈতে পৰিচিত নেকি?',
                'মীন পালন আৰু সামুদ্ৰিক উদ্যোগত আপোনাৰ ভূমিকাৰ বাবে প্ৰয়োজনীয় টিকাকৰণ বা প্ৰতিৰোধমূলক ব্যৱস্থা প্ৰাপ্ত কৰাৰ ক্ষেত্ৰত আপুনি কোনো বাধাৰ সন্মুখীন হৈছে নেকি?',
                'আপোনাৰ সুস্থতা নিৰীক্ষণ কৰিবলৈ আৰু যিকোনো সাম্ভাব্য স্বাস্থ্য সমস্যা চিনাক্ত কৰিবলৈ আপোনাক নিয়মীয়া স্বাস্থ্য পৰীক্ষা বা স্ক্ৰীণিং প্ৰদান কৰা হৈছে নেকি?',
                'মীন পালন আৰু সামুদ্ৰিক উদ্যোগত কাম কৰোঁতে কাম সম্পৰ্কীয় চাপ বা আৱেগিক সুস্থতাৰ মোকাবিলা কৰিবলৈ আপুনি মানসিক স্বাস্থ্য সমৰ্থন বা পৰামৰ্শ সেৱা প্ৰাপ্ত কৰিছে নেকি?'
            ]
        },
        {
            id          : 10,
            category_id : 10,
            questions   : [
                'আপুনি কেতিয়াবা নিজৰ বা আপোনাৰ নিৰ্ভৰশীলসকলৰ বাবে স্বাস্থ্যসেৱা প্ৰাপ্ত কৰাৰ ক্ষেত্ৰত প্ৰত্যাহ্বানৰ সন্মুখীন হৈছে নেকি?',
                'আপুনি আপোনাৰ নিৰ্দিষ্ট উদ্যোগ বা খণ্ডত শ্ৰমিক হিচাপে আপোনাৰ বাবে উপলব্ধ স্বাস্থ্যসেৱাৰ লাভালাভ আৰু সম্পদৰ বিষয়ে অৱগত নেকি?',
                'প্ৰয়োজন হ\'লে সময়মতে চিকিৎসা বা পৰামৰ্শ প্ৰাপ্ত কৰাত আপোনাৰ কোনো অসুবিধা আছে নেকি?',
                'আপুনি স্বাস্থ্য প্ৰতিষ্ঠানৰ দ্বাৰা প্ৰদান কৰা স্বাস্থ্যসেৱা সুবিধাবোৰত সন্তুষ্ট নে?',
                'আপুনি আপোনাৰ কামৰ শাৰীৰ সৈতে সম্পৰ্কিত নিৰ্দিষ্ট বৃত্তিগত স্বাস্থ্য বিপদাশংকাৰ বিষয়ে অৱগত নেকি?',
                'আপুনি আপোনাৰ খণ্ডত শ্ৰমিক হিচাপে স্বাস্থ্যসেৱাৰ অধিকাৰ আৰু অধিকাৰৰ সৈতে পৰিচিত নেকি?',
                'আপোনাৰ ভূমিকাৰ বাবে প্ৰয়োজনীয় টিকাকৰণ বা প্ৰতিৰোধমূলক ব্যৱস্থা প্ৰাপ্ত কৰাৰ ক্ষেত্ৰত আপুনি কোনো বাধাৰ সন্মুখীন হৈছে নেকি?',
                'আপোনাৰ সুস্থতা নিৰীক্ষণ কৰিবলৈ আৰু যিকোনো সাম্ভাব্য স্বাস্থ্য সমস্যা চিনাক্ত কৰিবলৈ আপোনাক নিয়মীয়া স্বাস্থ্য পৰীক্ষা বা স্ক্ৰীণিং প্ৰদান কৰা হৈছে নেকি?',
                'আপুনি আপোনাৰ এলেকাত উপলব্ধ স্বাস্থ্যসেৱা সুবিধা আৰু সম্পদৰ বিষয়ে অৱগত নেকি? যদি নহয়, আপুনি সেইবোৰ কেনেদৰে প্ৰৱেশ কৰিব লাগে সেই বিষয়ে অধিক তথ্য বিচাৰিব নেকি?',
                'কাম সম্পৰ্কীয় চাপ বা আৱেগিক সুস্থতাৰ মোকাবিলা কৰিবলৈ আপোনাৰ মানসিক স্বাস্থ্য সমৰ্থন বা পৰামৰ্শ সেৱাৰ উপলব্ধতা আছে নেকি?'
            ]
        }
    ]
};

export default assamese;
